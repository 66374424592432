import React from 'react';
import DataStore from '../data/DataStore';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonProps from '../model/CommonProps';
import CommonState from '../model/CommonState';
import Dropdown from 'react-bootstrap/Dropdown';
import ListData from '../model/ListData';
import ClientModel from '../model/ClientModel';
import DisplayUtil from './DisplayUtil';
import ClientOperatorModel from '../model/ClientOperatorModel';

class OperatorsAPI extends React.Component<CommonProps, CommonState> {

    clientModel: ClientModel;
    clientOperators: ClientOperatorModel[] = [];
    selectedClientOperator : ClientOperatorModel;

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: CommonProps) {
        super(params);
        this.clientModel = new ClientModel();
        this.clientModel.channelId = DataStore.getSelectedRetailerId();
        this.selectedClientOperator = new ClientOperatorModel();
        this.loadData();
    }

    updateState() {
        this.setState({
            time: new Date()
        });
    }

    async loadData() {
        let sa = new ServerAdaptor();
        let cr1 = await sa.get_client(this.clientModel.channelId);
        this.clientModel = cr1.data as ClientModel;
        let cr = await sa.get_client_services(this.clientModel.channelId);
        let listData = cr.data as ListData;
        this.clientOperators = listData.clientOperators;
        this.updateState();
    }

    async updateStatus(e: React.MouseEvent<HTMLElement, MouseEvent>, clientOperator : ClientOperatorModel) {
        e.preventDefault();
        this.selectedClientOperator = clientOperator;
        DataStore.setSelectedProfileServiceId(clientOperator.channelOperatorId);
        this.updateStatus1(clientOperator);
    }

    async updateStatus1(profileService : ClientOperatorModel) {

        if(profileService.status == 0) {
            profileService.status = 1;
        }else if(profileService.status == 1) {
            profileService.status = 0;
        }

        let sa = new ServerAdaptor();
        let cr = await sa.update_client_service_config(profileService);
        if (cr.status === 200) {
            console.log("response : " + cr.data);
            DisplayUtil.showInfoModel("Information", "Success", "", "/api-operators");
        } else {
            if (cr.status === 501) {
                DisplayUtil.showInfoModel("Information", "Error", JSON.stringify(cr.error), "");
            } else if (cr.status === 502) {
                DisplayUtil.showInfoModel("Information", "Error", cr.error, "");
            } else {
                console.log("response : FAILED");
            }
        }
    }

    render() {

        return (
            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">Operators</h3>
                        </div>
                    </div>
                </div>

                <div className="row mb-6">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="card h-100">
                            <div className="card-header bg-white py-3">
                                <div className="d-md-flex justify-content-between align-items-center">
                                    <div className="mb-3 mb-lg-0 text-center text-sm-start">
                                        <h4 className="mb-0">Operators</h4>
                                    </div>
                                    <div className="text-center text-md-start">
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table text-nowrap">
                                    <thead className="table-light">
                                        <tr>
                                            <th>Sno</th>
                                            <th>Country</th>
                                            <th>Operator</th>
                                            <th>Status</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.clientOperators.map((po, index) =>
                                            <tr>
                                                <td className="align-middle">
                                                    <h5 className=" mb-1">{(index+1)}.</h5>
                                                </td>
                                                <td className="align-middle"><h5 className=" mb-1">{po.countryName}</h5></td>
                                                <td className="align-middle">{po.operatorName}</td>
                                                <td className="align-middle">{po.status==1?"Enabled":"Disabled"}</td>
                                                <td className="align-middle">
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="link float-end" id="dropdown-basic">
                                                            <i className="fas fa-ellipsis-v"></i>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item as="button" onClick={(e) => this.updateStatus(e, po)}>{po.status==1?"Disabled":"Enabled"}</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default OperatorsAPI;