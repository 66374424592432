import React, { CSSProperties, SyntheticEvent } from 'react';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonProps from '../model/CommonProps';
import CommonState from '../model/CommonState';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RetailerModel from '../model/ClientModel';
import SelectSearchModel from '../component/SelectSearchModel';
import OptionType from '../component/OptionType';
import SelectSearchComponent from '../component/SelectSearchComponent';
import TableDataModel from '../model/TableDataModel';
import PaginationTableComponent from '../component/PaginationTableComponent';
import TableModel from '../component/TableModel';
import DropDownData from '../component/DropDownData';
import TableUtil from '../util/TableUtil';
import TableCell from '../component/TableCell';
import DataStore from '../data/DataStore';
import ListData from '../model/ListData';
import URLUtil from '../data/URLUtil';
import ServiceProviderModel from '../model/ServiceProviderModel';

class ReportViewSP extends React.Component<CommonProps, CommonState> {

    header: string = "Report";
    reportType: string = "";
    clientId: string = "";
    tableDataModel: TableDataModel = new TableDataModel();
    tableModel: TableModel = new TableModel();
    retailers: RetailerModel[] = [];
    retailerSelectModel: SelectSearchModel;
    serviceProviders: ServiceProviderModel[] =[];
    serviceProviderSelectModel: SelectSearchModel;
    selectedChannelId = 0;
    selectedServiceProviderId = 0;
    fromDate: Date = new Date();
    toDate: Date = new Date();
    downloadUrl: string = "";

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: CommonProps) {
        super(params);
        this.reportType = URLUtil.getPathParamText(2);
        this.clientId = URLUtil.getParamText("id");
        this.retailers = [];
        this.retailerSelectModel = new SelectSearchModel();
        this.serviceProviders = [];
        this.serviceProviderSelectModel = new SelectSearchModel();
        this.loadData();
    }

    updateState() {
        this.setState({
            time: new Date()
        });
    }

    async loadData() {

        {
            let model = { "command": "all" };
            let sa = new ServerAdaptor();
            let cr = await sa.get_clients(model);
            let listData = cr.data as ListData;
            this.retailers = listData.clients;
            this.retailerSelectModel.clear();
            this.retailerSelectModel.add("0", "Select Client");
            for(let obj of this.retailers) {
                this.retailerSelectModel.add(obj.channelId+"", obj.clientName);
            }
        }

        if(this.isClientReport()) {
            this.selectedServiceProviderId = parseInt(this.clientId);
        }else {
            {
                let model = { "command": "all" };
                let sa = new ServerAdaptor();
                let cr = await sa.get_service_providers(model);
                let listData = cr.data as ListData;
                this.serviceProviders = listData.serviceProviders;
                this.serviceProviderSelectModel.clear();
                this.serviceProviderSelectModel.add("0", "Select Service Provider");
                for(let obj of this.serviceProviders) {
                    this.serviceProviderSelectModel.add(obj.serviceProviderId+"", obj.serviceProviderName);
                }
            }
        }

        this.loadSearchData();
    }

    onTxnTypeSelectClicked(e: OptionType) {
        this.updateState();
    };

    async searchAction(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        this.loadSearchData();
    }

    onRetailerSelectClicked(e: OptionType) {
        this.selectedChannelId = parseInt(e.value);
        this.retailerSelectModel.setSelected(this.selectedChannelId+"");
        this.updateState();
    };

    onServiceProviderSelectClicked(e: OptionType) {
        this.selectedServiceProviderId = parseInt(e.value);
        this.serviceProviderSelectModel.setSelected(this.selectedServiceProviderId+"");
        this.updateState();
    };

    async loadSearchData() {

        let formattedFromDate = (moment(this.fromDate)).format('YYYY-MM-DD HH:mm:ss');
        let formattedToDate = (moment(this.toDate)).format('YYYY-MM-DD HH:mm:ss');

        //let channelId = DataStore.getRetailerId();
        let channelId = this.selectedChannelId;
        let serviceProviderId = this.selectedServiceProviderId;
        let reportType1 = this.reportType;
        let model = { "channelId": channelId, "serviceProviderId": serviceProviderId, "reportType": reportType1, "fromDate": formattedFromDate, "toDate": formattedToDate };
        let sa = new ServerAdaptor();
        let cr = await sa.get_reports_dynamic(model);

        this.tableDataModel = cr.data as TableDataModel;
        this.header = this.tableDataModel.name;

        let tableUtil = new TableUtil();
        tableUtil.loadTableData(this.tableDataModel, this.tableModel);
        if(this.reportType == 'tx-stock') {
            this.tableModel.updateColumnViewToButton("Invoice", "Download");
        }
        this.tableModel.updateView();

        this.downloadUrl = sa.base_url + "/transection-api/get-transection-report-file?channelId=" + channelId + "&txnType=" + reportType1 + "&fromDate=" + formattedFromDate + "&toDate=" + formattedToDate;
        this.updateState();
    }

    setFromDate(date: Date): void {
        console.log("start date : " + date);
        this.fromDate = date;
        this.updateState();
    }

    setToDate(date: Date): void {
        console.log("start date : " + date);
        this.toDate = date;
        this.updateState();
    }

    async onDropDownClicked(e: React.MouseEvent<HTMLElement, MouseEvent>, dropDownData: DropDownData) {
        e.preventDefault();
    }

    async onButtonClicked(e: React.MouseEvent<HTMLElement, MouseEvent>, cell: TableCell) {
        e.preventDefault();
        console.log("link clicked : "+cell);
        let sa = new ServerAdaptor();
        let url = sa.report_url+"/report-service/wallet-receipt?id="+cell.extra_data;
        window.open(url, "_blank");
    }

    getRetailorProperty() {
        if(!this.isClientReport()) {
            return 'block';
        }
        return 'none';
    }

    isClientReport() {
        if(this.clientId.length>0) {
            return true;
        }
        return false;
    }

    render() {

        return (
            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">{this.header}</h3>
                        </div>
                    </div>
                </div>

                <div className="row mb-6">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="card h-100">

                            <div className="card-body">
                                <form className="row g-3 needs-validation">
                                    <div className="col-md-3" style={{ display: this.getRetailorProperty() }}>
                                        <label data-for="location" className="col-form-label form-label pt-0">Service Provider</label>
                                        <SelectSearchComponent model={this.serviceProviderSelectModel} onSelectClicked={(e) => this.onServiceProviderSelectClicked(e)}></SelectSearchComponent>
                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label">From Date</label>
                                        <DatePicker selected={this.fromDate} onChange={(date: Date) => this.setFromDate(date)} />
                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label">To Date</label>
                                        <DatePicker selected={this.toDate} onChange={(date: Date) => this.setToDate(date)} />
                                    </div>
                                    <div className="col-md-1 pt-5">
                                        <button className="btn btn-primary" type="submit" onClick={(e) => this.searchAction(e)}>Submit</button>
                                    </div>
                                    <div className="col-md-1 pt-5">
                                        <a className="btn btn-primary" href={this.downloadUrl}>CSV</a>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row mb-6">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="card h-100">

                            <div className="card-header bg-white py-3 border-bottom-0">
                                <div className="d-md-flex justify-content-between align-items-center">
                                    <div className="mb-3 mb-lg-0 text-center text-sm-start">
                                        <h4 className="mb-0">Transactions</h4>
                                    </div>
                                    <div className="text-center text-md-start">

                                    </div>
                                </div>
                            </div>

                            <div className="card-body p-0">
                                <PaginationTableComponent model={this.tableModel} onDropDownClicked={this.onDropDownClicked} onButtonClicked={this.onButtonClicked} ></PaginationTableComponent>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default ReportViewSP;
