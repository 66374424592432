import React from 'react';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonState from '../model/CommonState';
import DisplayUtil from './DisplayUtil';
import FormUtil from '../util/FormUtil';
import ErrorResponse from '../util/ErrorResponse';
import CommonProps from '../model/CommonProps';
import RetailerModel from '../model/ClientModel';
import LoginModel from '../model/LoginModel';

class ResetMpinView extends React.Component<CommonProps, CommonState> {

    formId: string = "form1";
    flag_new: boolean = true;
    header: string = "Reset Mpin";
    retailerModel: RetailerModel;
    loginModel: LoginModel;

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: CommonProps) {
        super(params);
        this.retailerModel = new RetailerModel();
        this.loginModel = new LoginModel();
    }

    componentDidMount() {
        this.loadData();
    }

    updateState() {
        this.setState({
            time: new Date()
        });
    }

    async loadData() {
        
        let sa = new ServerAdaptor();
        let cr = await sa.get_client(this.retailerModel.channelId);
        this.retailerModel = cr.data as RetailerModel;

        this.updateState();
    }

    updateInput(e: React.ChangeEvent<HTMLInputElement>, typeHint: string) {
        if (typeHint === "newPassword") {
            this.loginModel.newPassword = e.target.value;
        }
        if (typeHint === "conformPassword") {
            this.loginModel.conformPassword = e.target.value;
        }
        FormUtil.updateFormState(this.formId);
        this.updateState();
    }

    async saveAction(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
    
        let sa = new ServerAdaptor();
            let cr = await sa.reset_mpin_request(this.loginModel);
            if(cr.status === 200) {
                console.log("response : "+cr.data);
                DisplayUtil.showInfoModel("Information", "Success", "", "/operators");
            }else {
                if(cr.status === 501) {
                    FormUtil.showFormErrors(cr.error as ErrorResponse, this.formId);
                }else if(cr.status === 502) {
                    FormUtil.showFormErrors(cr.error as ErrorResponse, this.formId);
                }else {
                    DisplayUtil.showInfoModel("Information", "Error", cr.error, "");
                }
            }
    }

    render() {

        return (
            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">{this.header}</h3>
                        </div>
                    </div>
                </div>

                <div className="row mb-8">
                    <div className="col-xl-9 col-lg-8 col-md-12 col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-6">
                                    <h4 className="mb-1">Operator Details</h4>
                                </div>
                                <div className="mb-6">
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Name </label>
                                        <label data-for="phone" className="col-sm-8 col-form-label form-label">{this.retailerModel.clientName}</label>
                                    </div>
                                </div>
                                <div className="mb-6">
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Mobile </label>
                                        <label data-for="phone" className="col-sm-8 col-form-label form-label">{this.retailerModel.mobile}</label>
                                    </div>
                                </div>
                                <form id={this.formId} className="">
                                    <div className="row align-items-center">
                                        <div className="offset-md-4 col-md-8 mt-4">
                                            <button type="submit" className="btn btn-primary" onClick={(e) => this.saveAction(e)}>Send Otp</button>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Enter Otp </label>
                                        <div className="col-md-8 col-12">
                                            <input type="text" className="form-control" placeholder="" value={this.loginModel.newPassword} onChange={(e) => this.updateInput(e, "newPassword")}/>
                                            <div id="newPassword_feedback" className="invalid-feedback"></div>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">New Pin </label>
                                        <div className="col-md-8 col-12">
                                            <input type="text" className="form-control" placeholder="" value={this.loginModel.password} onChange={(e) => this.updateInput(e, "password")}/>
                                            <div id="conformPassword_feedback" className="invalid-feedback"></div>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Confirm Pin</label>
                                        <div className="col-md-8 col-12">
                                            <input type="text" className="form-control" placeholder="Name" value={this.loginModel.conformPassword} onChange={(e) => this.updateInput(e, "conformPassword")}/>
                                            <div id="operatorName_feedback" className="invalid-feedback"></div>
                                        </div>
                                    </div>

                                    <div className="row align-items-center">
                                        <div className="offset-md-4 col-md-8 mt-4">
                                            <button type="submit" className="btn btn-primary" onClick={(e) => this.saveAction(e)}>Save</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default ResetMpinView;