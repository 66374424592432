class ServiceProviderOperatorModel {

    serviceProviderOperatorId: number = 0;
    serviceProviderId: number = 0;
    serviceProviderName: string = "";
    operatorId: number = 0;
    operatorName: string = "";
    priority: number = 0;
    status: number = 0;
    statusDesc: string = "";
    remarks: string = "";
    serviceCharge: string = "";
}

export default ServiceProviderOperatorModel;