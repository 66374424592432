
import ClientModel from "./ClientModel";
import CountryModel from "./CountryModel";
import CurrencyModel from "./CurrencyModel";
import ProductModel from "./ProductModel";
import ServiceProviderWalletTxnModel from "./ServiceProviderWalletTxnModel";
import ServiceTypeModel from "./ServiceTypeModel";
import VoucherPinModel from "./VoucherPinModel";
import ClientOperatorModel from "./ClientOperatorModel";
import ServiceProviderModel from "./ServiceProviderModel";
import ServiceProviderOperatorModel from "./ServiceProviderOperatorModel";

class ListData {

    countries: CountryModel[] = [];
    currencies: CurrencyModel[] = [];
    serviceTypes: ServiceTypeModel[] = [];
    serviceProviders: ServiceProviderModel[] =[];
    serviceProviderOperators: ServiceProviderOperatorModel[] = [];
    serviceProviderWalletTxns: ServiceProviderWalletTxnModel[] = [];
    countriesInfos: CountryModel[] = [];
    clients: ClientModel[] = [];
    clientOperators: ClientOperatorModel[] = [];
    products: ProductModel[] = [];
    vouchers: VoucherPinModel[] = [];

}

export default ListData;