class VoucherPinModel {

    voucherPinId: number = 0;
    productId: number = 0;
    voucherSerialNo: string = "";
    voucherPin: string = "";
    barCode: string = "";
    expiryDateTxt: string = "";
    status: number = 0;
    remarks: string = "";
    
}

export default VoucherPinModel;