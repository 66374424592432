import React from 'react';
import DataStore from '../data/DataStore';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonProps from '../model/CommonProps';
import CommonState from '../model/CommonState';
import ServiceProviderOperatorModel from '../model/ServiceProviderOperatorModel';
import DisplayUtil from './DisplayUtil';
import URLUtil from '../data/URLUtil';

class ServiceProviderOperatorFormView extends React.Component<CommonProps, CommonState> {

    header: string = "Operator Mapping";
    providerOperatorModel: ServiceProviderOperatorModel;

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: CommonProps) {
        super(params);
        this.providerOperatorModel = new ServiceProviderOperatorModel();
    }

    componentDidMount() {

        this.providerOperatorModel.serviceProviderOperatorId = URLUtil.getPathParamNumber(2);
        this.providerOperatorModel.serviceProviderId = DataStore.getSelectedServiceProviderId();
        this.providerOperatorModel.serviceProviderName = DataStore.getSelectedServiceProviderName();
        this.header = this.providerOperatorModel.serviceProviderName+" - Operator Mapping";

        this.loadData();
    }

    updateState() {
        this.setState({
            time: new Date()
        });
    }

    async loadData() {

        {
            let sa = new ServerAdaptor();
            let cr = await sa.get_service_provider_operator_mapping(this.providerOperatorModel.serviceProviderOperatorId);
            this.providerOperatorModel = cr.data as ServiceProviderOperatorModel;
        }

        this.updateState();
    }

    updateInput(e: React.ChangeEvent<HTMLInputElement>, typeHint: string) {
        if (typeHint === "status") {
            var el = e.target as HTMLInputElement;
            var isChecked = el.checked;
            if (isChecked) {
                this.providerOperatorModel.status = 1;
            } else {
                this.providerOperatorModel.status = 0;
            }
            this.updateStatus();
        }
        this.updateState();
    }

    async updateStatus() {

        let sa = new ServerAdaptor();
        let cr = await sa.update_service_provider_operator_mapping_status(this.providerOperatorModel);
        if (cr.status === 200) {
            console.log("response : " + cr.data);
            DisplayUtil.showInfoModel("Information", "Success", "", "/provider-operators");
        } else {
            if (cr.status === 501) {
                DisplayUtil.showInfoModel("Information", "Error", JSON.stringify(cr.error), "");
            } else if (cr.status === 502) {
                DisplayUtil.showInfoModel("Information", "Error", cr.error, "");
            } else {
                console.log("response : FAILED");
            }
        }
    }

    render() {

        var blockOption = false;
        if (this.providerOperatorModel.status == 1) {
            blockOption = true;
        }

        return (
            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">{this.header} Mapping</h3>
                        </div>
                    </div>
                </div>

                <div className="row mb-6">
                    <div className="col-xl-9 col-lg-8 col-md-12 col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-6">
                                    <h4 className="mb-1">Operator Mapping</h4>
                                </div>
                                <div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">Service Provider</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.providerOperatorModel.serviceProviderName}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">Operator</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.providerOperatorModel.operatorName}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">Service Charge (In Percentage)</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.providerOperatorModel.serviceCharge}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">Remarks</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.providerOperatorModel.remarks}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mb-8">
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-header bg-white py-3">
                                <div className="d-md-flex justify-content-between align-items-center">
                                    <div className="mb-3 mb-lg-0 text-sm-start">
                                        <h4 className="mb-0">Operator Mapping Blocking</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div>
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Operator Mapping Enable/Disable</label>
                                        <div className="col-md-8 col-12 pt-2">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" checked={blockOption} onChange={(e) => this.updateInput(e, "status")} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default ServiceProviderOperatorFormView;