import React from 'react';
import DataStore from '../data/DataStore';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonProps from '../model/CommonProps';
import CommonState from '../model/CommonState';
import ServiceProviderModel from '../model/ServiceProviderModel';
import DisplayUtil from './DisplayUtil';
import URLUtil from '../data/URLUtil';

class ServiceProviderView extends React.Component<CommonProps, CommonState> {

    header: string = "Service Provider";
    serviceProviderModel: ServiceProviderModel;

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: CommonProps) {
        super(params);
        this.serviceProviderModel = new ServiceProviderModel();
        this.header = "Service Provider";
        this.serviceProviderModel.serviceProviderId = URLUtil.getPathParamNumber(2);
        this.loadData();
    }

    updateState() {
        this.setState({
            time: new Date()
        });
    }

    async loadData() {

        let sa = new ServerAdaptor();
        let cr = await sa.get_service_provider(this.serviceProviderModel.serviceProviderId);
        this.serviceProviderModel = cr.data as ServiceProviderModel;

        this.updateState();
    }

    updateInput(e: React.ChangeEvent<HTMLInputElement>, typeHint: string) {
        if (typeHint === "status") {
            var el = e.target as HTMLInputElement;
            var isChecked = el.checked;
            if (isChecked) {
                this.serviceProviderModel.status = 1;
            } else {
                this.serviceProviderModel.status = 0;
            }
            this.updateStatus();
        }
        this.updateState();
    }

    async editAction(e: React.MouseEvent<HTMLElement, MouseEvent>) {
        e.preventDefault();
        DataStore.setSelectedServiceProviderId(this.serviceProviderModel.serviceProviderId);
        console.log("edit");
        window.location.href = "/edit-service-provider/"+this.serviceProviderModel.serviceProviderId;
    }

    async updateStatus() {

        let sa = new ServerAdaptor();
        let cr = await sa.update_service_provider_status(this.serviceProviderModel);
        if (cr.status === 200) {
            console.log("response : " + cr.data);
            DisplayUtil.showInfoModel("Information", "Success", "", "/service-providers");
        } else {
            if (cr.status === 501) {
                DisplayUtil.showInfoModel("Information", "Error", JSON.stringify(cr.error), "");
            } else if (cr.status === 502) {
                DisplayUtil.showInfoModel("Information", "Error", cr.error, "");
            } else {
                console.log("response : FAILED");
            }
        }
    }

    render() {

        var blockOption = false;
        if (this.serviceProviderModel.status == 1) {
            blockOption = true;
        }

        return (
            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">{this.header}</h3>
                        </div>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-header bg-white py-3">
                                <div className="d-md-flex justify-content-between align-items-center">
                                    <div className="mb-3 mb-lg-0 text-center text-sm-start">
                                        <h4 className="mb-0">Service Provider Details</h4>
                                    </div>
                                    <div className="text-center text-md-start">
                                    <button type="button" className="btn btn-sm btn-primary" onClick={(e) => this.editAction(e)}>Edit</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">Service Provider ID</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.serviceProviderModel.serviceProviderId}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">Service Provider Code</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.serviceProviderModel.serviceProviderCode}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">Service Provider Name</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.serviceProviderModel.serviceProviderName}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">Country</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.serviceProviderModel.countryName}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">Currency</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.serviceProviderModel.currencyCode}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">Contact Person</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.serviceProviderModel.contactPerson}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">Mobile</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.serviceProviderModel.mobile}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">Email</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.serviceProviderModel.email}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">Remarks</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.serviceProviderModel.remarks}</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mb-8">
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-header bg-white py-3">
                                <div className="d-md-flex justify-content-between align-items-center">
                                    <div className="mb-3 mb-lg-0 text-sm-start">
                                        <h4 className="mb-0">Service Provider Blocking</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div>
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Service Provider Enable/Disable</label>
                                        <div className="col-md-8 col-12 pt-2">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" checked={blockOption} onChange={(e) => this.updateInput(e, "status")} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default ServiceProviderView;