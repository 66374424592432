import React from 'react';
import DataStore from '../data/DataStore';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonState from '../model/CommonState';
import DisplayUtil from './DisplayUtil';
import ProductModel from '../model/ProductModel';
import CommonProps from '../model/CommonProps';

class ProductView extends React.Component<CommonProps, CommonState> {

    productModel: ProductModel;

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: CommonProps) {
        super(params);
        this.productModel = new ProductModel();
        this.productModel.productId = DataStore.getSelectedProductId();
    }

    componentDidMount() {
        this.loadData();
    }

    updateState() {
        this.setState({
            time: new Date()
        });
    }

    async loadData() {
        
        let model = { "productId": this.productModel.productId };
        let sa = new ServerAdaptor();
        let cr = await sa.get_product_internal(model);
        this.productModel = cr.data as ProductModel;

        this.updateState();
    }

    updateInput(e: React.ChangeEvent<HTMLInputElement>, typeHint: string) {
        if (typeHint === "status") {
            var el = e.target as HTMLInputElement;
            var isChecked = el.checked;
            if (isChecked) {
                this.productModel.status = 1;
            } else {
                this.productModel.status = 0;
            }
            this.updateStatus();
        }
        this.updateState();
    }

    async updateStatus() {

        let sa = new ServerAdaptor();
        let cr = await sa.update_product_status(this.productModel);
        if (cr.status === 200) {
            console.log("response : " + cr.data);
            DisplayUtil.showInfoModel("Information", "Success", "", "/operator-products");
        } else {
            if (cr.status === 501) {
                DisplayUtil.showInfoModel("Information", "Error", JSON.stringify(cr.error), "");
            } else if (cr.status === 502) {
                DisplayUtil.showInfoModel("Information", "Error", cr.error, "");
            } else {
                console.log("response : FAILED");
            }
        }
    }

    render() {

        var blockOption = false;
        if (this.productModel.status == 1) {
            blockOption = true;
        }

        return (
            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">Product View</h3>
                        </div>
                    </div>
                </div>

                <div className="row mb-8">
                    <div className="col-xl-9 col-lg-8 col-md-12 col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-6">
                                    <h4 className="mb-1">Product Details</h4>
                                </div>
                                <form>
                                    
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Name</label>
                                        <div className="col-md-8 col-12">
                                            <p className="mb-0">{this.productModel.productName}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Price</label>
                                        <div className="col-md-8 col-12">
                                            <p className="mb-0">{this.productModel.price}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Validity</label>
                                        <div className="col-md-8 col-12">
                                            <p className="mb-0">{this.productModel.validity}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Short Description</label>
                                        <div className="col-md-8 col-12">
                                            <p className="mb-0">{this.productModel.shortDescription}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Description</label>
                                        <div className="col-md-8 col-12">
                                            <p className="mb-0">{this.productModel.description}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Product Image</label>
                                        <div className="col-md-8 col-12">
                                            <p className="mb-0">{this.productModel.productImage}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="location" className="col-sm-4 col-form-label form-label">Service Type</label>
                                        <div className="col-md-8 col-12">
                                            <p className="mb-0">{this.productModel.serviceTypeName}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Voucher Template</label>
                                        <div className="col-md-8 col-12">
                                            <p className="mb-0">{this.productModel.voucherTemplate}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="addressLine" className="col-sm-4 col-form-label form-label">Remarks</label>
                                        <div className="col-md-8 col-12">
                                            <p className="mb-0">{this.productModel.remarks}</p>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mb-8">
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-header bg-white py-3">
                                <div className="d-md-flex justify-content-between align-items-center">
                                    <div className="mb-3 mb-lg-0 text-sm-start">
                                        <h4 className="mb-0">Product Blocking</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div>
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Product Enable/Disable</label>
                                        <div className="col-md-8 col-12 pt-2">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" checked={blockOption} onChange={(e) => this.updateInput(e, "status")} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default ProductView;