import DropDownData from "./DropDownData";

class TableCell {

    value: string = "";
    data_type: number = 0; // 0-text, 1-highlight, 2-dropdown, 3-Button, 4-Link
    extra_data: any;
    dropdown_data: DropDownData[] | undefined;
}

export default TableCell;