import React from 'react';
import DataStore from '../data/DataStore';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonProps from '../model/CommonProps';
import CommonState from '../model/CommonState';
import RetailerWalletModel from '../model/RetailerWalletModel';
import DisplayUtil from './DisplayUtil';
import FormUtil from '../util/FormUtil';
import ErrorResponse from '../util/ErrorResponse';

class ClientWalletUpdateView extends React.Component<CommonProps, CommonState> {

    formId: string = "form1";
    retailerWalletModel: RetailerWalletModel;
    amountAddition: number = 0;

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: CommonProps) {
        super(params);
        this.retailerWalletModel = new RetailerWalletModel();
        this.retailerWalletModel.channelId = DataStore.getSelectedRetailerId();
        this.loadData();
    }

    async loadData() {

        let sa = new ServerAdaptor();
        let cr = await sa.get_client_wallet_info(this.retailerWalletModel.channelId);
        this.retailerWalletModel = cr.data as RetailerWalletModel;
        this.setState({
            time: new Date()
        });
    }

    updateInput(e: React.ChangeEvent<HTMLInputElement>, typeHint: string) {
        if (typeHint === "amountAddition") {
            this.amountAddition = parseInt(e.target.value);
        }
        FormUtil.updateFormState(this.formId);
        this.setState({
            time: new Date(),
        });
    }

    async updateWalletAction(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
    
        let model = { "channelId": this.retailerWalletModel.channelId, "amount": this.amountAddition };
        let sa = new ServerAdaptor();
        let cr = await sa.update_client_wallet(model);
            if(cr.status === 200) {
                console.log("response : "+cr.data);
                DisplayUtil.showInfoModel("Information", "Success", "", "/client-wallet/"+this.retailerWalletModel.channelId);
            }else {
                if(cr.status === 501) {
                    FormUtil.showFormErrors(cr.error as ErrorResponse, this.formId);
                }else if(cr.status === 502) {
                    FormUtil.showFormErrors(cr.error as ErrorResponse, this.formId);
                }else {
                    DisplayUtil.showInfoModel("Information", "Error", cr.error, "");
                }
            }
    }

    render() {

        return (
            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">Wallet Details</h3>
                        </div>
                    </div>
                </div>

                <div className="row mb-8">
                    <div className="col-xl-9 col-lg-8 col-md-12 col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-6">
                                    <h4 className="mb-1">Add Credits</h4>
                                </div>
                                <div className="mb-6">
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Available balance </label>
                                        <label data-for="phone" className="col-sm-8 col-form-label form-label">{this.retailerWalletModel.amount +" ("+ this.retailerWalletModel.currencyCode+")"}</label>
                                    </div>
                                </div>
                                <form id={this.formId} className="">
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Add Wallet Amount</label>
                                        <div className="col-md-8 col-12">
                                            <input type="text" className="form-control" placeholder="0" onChange={(e) => this.updateInput(e, "amountAddition")}/>
                                            <div id="amount_feedback" className="invalid-feedback"></div>
                                        </div>
                                    </div>

                                    <div className="row align-items-center">
                                        <div className="offset-md-4 col-md-8 mt-4">
                                            <button type="submit" className="btn btn-primary" onClick={(e) => this.updateWalletAction(e)}>Add</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default ClientWalletUpdateView;