class ProductModel {

    productId: number = 0;
    productCode: string = "";
    operatorId: number = 0;
    productName: string = "";
    price: string = "";
    currencyName: string = "";
    sendPrice: number = 0;
    sendCurrencyName: string = "";
    validity: string = "";
    shortDescription: string = "";
    description: string = "";
    productImage: string = "";
    voucherTemplate: string = "";
    status: number = 0;
    remarks: string = "";
    serviceTypeId: number = 0;
    serviceTypeName: string = "";
    
}

export default ProductModel;