import React from 'react';
import PaginationModel from './PaginationModel';
import PaginationItemModel from './PaginationItemModel';

interface PaginationComponentProps {
    model: PaginationModel;
    onPageClicked: (selectedPage: PaginationItemModel) => void;
};
interface PaginationComponentState {
    time: Date;
};

class PaginationComponent extends React.Component<PaginationComponentProps, PaginationComponentState> {

    model: PaginationModel = new PaginationModel();

    state: PaginationComponentState = {
        time: new Date(),
    };

    constructor(params: PaginationComponentProps) {
        super(params);
        this.model = params.model;
    }

    updateState() {
        this.setState({
            time: new Date()
        });
    }

    async paginationAction(e: React.MouseEvent<HTMLElement, MouseEvent>, selectedPage: PaginationItemModel) {
        e.preventDefault();
        this.model.setSelected(selectedPage);
        this.props.onPageClicked(selectedPage);
        this.updateState();
    }

    render() {

        if(this.model.displayPages.length < 9) {
            for (var i = 0; i < 9; i++) {
                this.model.displayPages[i] = new PaginationItemModel();
            }
        }

        return (
            <div className="pe-3">
                <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-end">
                        <li className={this.model.displayPages[0].getClassNames()}><a className="page-link " role="button" data-tabindex="0" href="#" onClick={(e) => this.paginationAction(e, this.model.displayPages[0])}><span aria-hidden="true">«</span><span className="visually-hidden">First</span></a></li>
                        <li className={this.model.displayPages[1].getClassNames()}><a className="page-link" role="button" data-tabindex="0" href="#" onClick={(e) => this.paginationAction(e, this.model.displayPages[1])}><span aria-hidden="true">‹</span><span className="visually-hidden">Previous</span></a></li>
                        <li className={this.model.displayPages[2].getClassNames()}><a className="page-link" href="#" onClick={(e) => this.paginationAction(e, this.model.displayPages[2])}>{this.model.displayPages[2].getPageLabel()}</a></li>
                        <li className={this.model.displayPages[3].getClassNames()}><a className="page-link" href="#" onClick={(e) => this.paginationAction(e, this.model.displayPages[3])}>{this.model.displayPages[3].getPageLabel()}</a></li>
                        <li className={this.model.displayPages[4].getClassNames()}><a className="page-link" href="#" onClick={(e) => this.paginationAction(e, this.model.displayPages[4])}>{this.model.displayPages[4].getPageLabel()}</a></li>
                        <li className={this.model.displayPages[5].getClassNames()}><a className="page-link" href="#" onClick={(e) => this.paginationAction(e, this.model.displayPages[5])}>{this.model.displayPages[5].getPageLabel()}</a></li>
                        <li className={this.model.displayPages[6].getClassNames()}><a className="page-link" href="#" onClick={(e) => this.paginationAction(e, this.model.displayPages[6])}>{this.model.displayPages[6].getPageLabel()}</a></li>
                        <li className={this.model.displayPages[7].getClassNames()}><a className="page-link" role="button" data-tabindex="0" href="#" onClick={(e) => this.paginationAction(e, this.model.displayPages[7])}><span aria-hidden="true">›</span><span className="visually-hidden">Next</span></a></li>
                        <li className={this.model.displayPages[8].getClassNames()}><a className="page-link" role="button" data-tabindex="0" href="#" onClick={(e) => this.paginationAction(e, this.model.displayPages[8])}><span aria-hidden="true">»</span><span className="visually-hidden">Last</span></a></li>
                    </ul>
                </nav>
            </div>
        );
    }
}

export default PaginationComponent;