import React from 'react';
import './css/bootstrap-icons/font/bootstrap-icons.css';
import './css/dropzone/dist/dropzone.css';
import './css/font-awesome/css/all.min.css';
import './App.css';
import './css/theme.css';
import './css/style.css';
import Signin from './app/view/Signin';
import AdminView from './app/view/AdminView';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ForgotPasswordView from './app/view/ForgotPasswordView';
import ResetPasswordView from './app/view/ResetPasswordView';
import ResetMpinView from './app/view/ResetMpinView';
import Notifications from './app/view/Notifications';
import { HelmetProvider } from "react-helmet-async";
import TestPage from './app/view/TestPage';

interface MyProps {
  message: string;
};
interface MyState {
  count: number;
};

class App extends React.Component<MyProps, MyState> {
  state: MyState = {
    count: 0,
  };
  render() {
    return (
      //<BrowserRouter>
        <Routes>
          <Route path="/test/:id" element={<TestPage />} />
          <Route path="/signin" element={<Signin message="" />} />
          <Route path="/forgot-password" element={<ForgotPasswordView message="" />} />
          <Route path="/reset-password" element={<ResetPasswordView message="" />} />

          <Route path="/dashboard/:clientid" element={<AdminView view="dashboard" />} />
          <Route path="/admin-dashboard" element={<AdminView view="admin-dashboard" />} />
          <Route path="/sp-dashboard/:id" element={<AdminView view="sp-dashboard" />} />
          <Route path="/notifications" element={<AdminView view="notifications" />} />

          <Route path="/clients" element={<AdminView view="clients" />} />
          <Route path="/client-select/:page" element={<AdminView view="client-select" />} />
          <Route path="/client-view/:clientid" element={<AdminView view="client-view" />} />
          <Route path="/new-client" element={<AdminView view="new-client" />} />
          <Route path="/edit-client/:clientid" element={<AdminView view="edit-client" />} />
          <Route path="/reset-mpin" element={<ResetMpinView message="" />} />
          <Route path="/client-wallet/:clientid" element={<AdminView view="client-wallet" />} />
          <Route path="/update-client-wallet/:clientid" element={<AdminView view="update-client-wallet" />} />
          <Route path="/adjust-client-wallet/:clientid" element={<AdminView view="adjust-client-wallet" />} />
          <Route path="/retailer-wallet-Transactions/:clientid" element={<AdminView view="retailer-wallet-Transactions" />} />
          
          <Route path="/client-operators/:clientid" element={<AdminView view="client-operators" />} />
          <Route path="/new-client-operator/:id" element={<AdminView view="new-client-operator" />} />
          <Route path="/edit-client-operator/:id" element={<AdminView view="edit-client-operator" />} />

          <Route path="/api-operators" element={<AdminView view="api-operators" />} />
          <Route path="/operators" element={<AdminView view="operators" />} />
          <Route path="/operator-view/:id" element={<AdminView view="operator-view" />} />
          <Route path="/new-operator" element={<AdminView view="new-operator" />} />
          <Route path="/edit-operator/:id" element={<AdminView view="edit-operator" />} />
          <Route path="/operator-products/:id" element={<AdminView view="operator-products" />} />
          <Route path="/new-product" element={<AdminView view="new-product" />} />
          <Route path="/edit-product" element={<AdminView view="edit-product" />} />
          <Route path="/product-view" element={<AdminView view="product-view" />} />
          <Route path="/product-vouchers" element={<AdminView view="product-vouchers" />} />
          <Route path="/new-voucher" element={<AdminView view="new-voucher" />} />
          <Route path="/edit-voucher" element={<AdminView view="edit-voucher" />} />
          <Route path="/voucher-bulk-upload" element={<AdminView view="voucher-bulk-upload" />} />

          <Route path="/service-providers" element={<AdminView view="service-providers" />} />
          <Route path="/service-provider-view/:id" element={<AdminView view="service-provider-view" />} />
          <Route path="/new-service-provider" element={<AdminView view="new-service-provider" />} />
          <Route path="/edit-service-provider/:id" element={<AdminView view="edit-service-provider" />} />
          <Route path="/service-provider-wallet/:id" element={<AdminView view="service-provider-wallet" />} />
          <Route path="/update-provider-wallet/:id" element={<AdminView view="update-provider-wallet" />} />
          <Route path="/service-provider-wallet-transactions" element={<AdminView view="service-provider-wallet-transactions" />} />
          <Route path="/service-provider-operators/:spid" element={<AdminView view="service-provider-operators" />} />
          <Route path="/service-provider-operator-mapping-view/:id" element={<AdminView view="service-provider-operator-mapping-view" />} />
          <Route path="/new-service-provider-operator-mapping/:spid" element={<AdminView view="new-service-provider-operator-mapping" />} />
          <Route path="/edit-service-provider-operator-mapping/:id" element={<AdminView view="edit-service-provider-operator-mapping" />} />
          <Route path="/service-provider-routing" element={<AdminView view="service-provider-routing" />} />
          
          <Route path="/forex-list" element={<AdminView view="forex-list" />} />
          <Route path="/new-forex" element={<AdminView view="new-forex" />} />
          <Route path="/edit-forex" element={<AdminView view="edit-forex" />} />
         
          <Route path="/products/:clientid" element={<AdminView view="products" />} />
          <Route path="/order-placing" element={<AdminView view="order-placing" />} />

          <Route path="/transaction-enquiry" element={<AdminView view="transaction-enquiry" />} />
          
          <Route path="/report-dashboard" element={<AdminView view="report-dashboard" />} />
          <Route path="/report-dashboard/:id" element={<AdminView view="client-dashboard" />} />
          <Route path="/report/:report_type" element={<AdminView view="report" />} />
          <Route path="/sp-report/:report_type" element={<AdminView view="sp-report" />} />

          <Route path="/api-documentation" element={<AdminView view="api-documentation" />} />
          <Route path="/" element={<Signin message="" />} />
        </Routes>
      //</BrowserRouter>
    );
  }
}

export default App;
