class ClientModel {

    channelId: number = 0;
    channelCode: string = "";
    clientName: string = "";
    contactPerson: string = "";
    email: string = "";
    mobile: string = "";
    currencyId: number = 0;
    currencyCode: string = "";
    countryId: number = 0;
    countryName: string = "";
    address: string = "";
    pincode: string = "";
    status: number = 0;
    statusDesc: string = "";
    remarks: string = "";
    markupValue: string = "";
    password: string = "";
    
}

export default ClientModel;