class PaginationItemModel {

    pageNumber: number = 0;
    elemenetType: number = 0; // 0-UNSELECTED, 1-SELECTED, 2-RANGE, 3-FIRST, 4-LAST, 5-PREV, 6-NEXT
    records: number[] = [];

    getPageLabel() {
        return this.pageNumber+"";
    }

    getClone() {
        var obj = new PaginationItemModel();
        obj.pageNumber = this.pageNumber;
        obj.elemenetType = this.elemenetType;
        obj.records = this.records;
        return obj;
    }

    getClassNames() {
        if(this.pageNumber == 0) {
            return "page-item visually-hidden";
        }else {
            if(this.elemenetType == 1) {
                return "page-item active";
            }else {
                return "page-item";
            }
        }
    }

}

export default PaginationItemModel;