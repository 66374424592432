import React from 'react';
import DataStore from '../data/DataStore';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonProps from '../model/CommonProps';
import ForexListModel from '../model/ForexListModel';
import Dropdown from 'react-bootstrap/Dropdown';

interface ForexListViewState {
	forexListModel: ForexListModel;
}

class ForexListView extends React.Component<CommonProps, ForexListViewState> {

    forexListModel: ForexListModel;

    state: ForexListViewState = {
        forexListModel: new ForexListModel()
    };

    constructor(params: CommonProps) {
        super(params);
        this.forexListModel = new ForexListModel();
        this.loadData();
    }

    updateState() {
        this.setState({
            forexListModel: this.forexListModel
        });
    }

    async loadData() {
        
        let model = { "command": "all" };
        let sa = new ServerAdaptor();
        let cr = await sa.get_forex_list(model);
        let forexListModel = cr.data as ForexListModel;
        this.forexListModel.forexList = forexListModel.forexList;
        this.updateState();
    }

    async editAction(e: React.MouseEvent<HTMLElement, MouseEvent>, forexId: number) {
        e.preventDefault();
        DataStore.setSelectedForexId(forexId);
        console.log("edit");
        window.location.href = "/edit-forex";
    }

    render() {

        return (
            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">Forex</h3>
                        </div>
                    </div>
                </div>

                <div className="row mb-6">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="card h-100">
                            <div className="card-header bg-white py-3">
                                <div className="d-md-flex justify-content-between align-items-center">
                                    <div className="mb-3 mb-lg-0 text-center text-sm-start">
                                        <h4 className="mb-0">Forex List</h4>
                                    </div>
                                    <div className="text-center text-md-start">
                                        <a type="button" className="btn btn-sm btn-primary" href="/new-forex">New</a>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table text-nowrap">
                                    <thead className="table-light">
                                        <tr>
                                            <th>Sno</th>
                                            <th>Src Currency</th>
                                            <th>Dest Currency</th>
                                            <th>Conversion Rate</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.forexListModel.forexList.map((ci, index) =>
                                            <tr>
                                                <td className="align-middle">
                                                    <h5 className=" mb-1">{(index+1)}.</h5>
                                                </td>
                                                <td className="align-middle"><h5 className=" mb-1">{ci.srcCurrencyCode}</h5></td>
                                                <td className="align-middle">{ci.destCurrencyCode}</td>
                                                <td className="align-middle">{ci.conversionRate}</td>
                                                <td className="align-middle">
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="link float-end" id="dropdown-basic">
                                                            <i className="fas fa-ellipsis-v"></i>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item as="button" onClick={(e) => this.editAction(e, ci.forexId)}>Edit</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>

        );
    }
}

export default ForexListView;