import React from 'react';
import DataStore from '../data/DataStore';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonProps from '../model/CommonProps';
import CommonState from '../model/CommonState';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReportTransectionRecord from '../model/ReportTransactionRecord';
import SelectSearchModel from '../component/SelectSearchModel';
import OptionType from '../component/OptionType';
import SelectSearchComponent from '../component/SelectSearchComponent';

class RetailerWalletTransactions extends React.Component<CommonProps, CommonState> {

    reportTransectionRecords: ReportTransectionRecord[] = [];
    txnProcessTypeSelectModel: SelectSearchModel;
    fromDate: Date = new Date();
    toDate: Date = new Date();
    downloadUrl: string = "";

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: CommonProps) {
        super(params);
        this.reportTransectionRecords = [];
        this.txnProcessTypeSelectModel = new SelectSearchModel();
        this.loadData();
    }

    updateState() {
        this.setState({
            time: new Date()
        });
    }

    async loadData() {

        this.txnProcessTypeSelectModel.add("0", "All");
        this.txnProcessTypeSelectModel.add("101", "Stock Txn");
        this.txnProcessTypeSelectModel.add("103", "Order Txn");
        this.txnProcessTypeSelectModel.add("104", "Commission Txn");

        this.loadSearchData();
    }

    onTxnTypeSelectClicked(e: OptionType) {
        this.txnProcessTypeSelectModel.setSelected(e.value);
        this.updateState();
    };

    async searchAction(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        this.loadSearchData();
    }

    async loadSearchData() {
        let formattedFromDate = (moment(this.fromDate)).format('YYYY-MM-DD HH:mm:ss');
        let formattedToDate = (moment(this.toDate)).format('YYYY-MM-DD HH:mm:ss');
        let channelId = DataStore.getSelectedRetailerId();
        let txnType = parseInt(this.txnProcessTypeSelectModel.selectedOption.value);
        let model = { "channelId": channelId, "txnType": txnType, "fromDate": formattedFromDate, "toDate": formattedToDate };
        let sa = new ServerAdaptor();
        let cr = await sa.get_client_wallet_transactions(model);
        this.reportTransectionRecords = cr.data as ReportTransectionRecord[];
        this.downloadUrl = sa.base_url+"/transaction-api/get-transaction-report-file?channelId="+channelId+"&txnType="+txnType+"&fromDate="+formattedFromDate+"&toDate="+formattedToDate;
        this.updateState();
    }

    setFromDate(date: Date): void {
        console.log("start date : " + date);
        this.fromDate = date;
        this.updateState();
    }

    setToDate(date: Date): void {
        console.log("start date : " + date);
        this.toDate = date;
        this.updateState();
    }

    render() {

        const transectionElements: JSX.Element[] = [];
        {
            this.reportTransectionRecords.map((obj, index) => (
                transectionElements.push(
                    <tr>
                        <td className="align-middle">
                            <h5 className=" mb-1">{(index+1)}.</h5>
                        </td>
                        <td className="align-middle"><h5 className=" mb-1">{obj.channelWalletTxnId}</h5></td>
                        <td className="align-middle">{obj.insertedDate}</td>
                        <td className="align-middle">{obj.channelId}</td>
                        <td className="align-middle">{obj.channelName}</td>
                        <td className="align-middle">{obj.msisdn}</td>
                        <td className="align-middle">{obj.channelPrice}</td>
                        <td className="align-middle">{obj.channelAfterAmount}</td>
                        <td className="align-middle">{obj.operation}</td>
                        <td className="align-middle">{obj.txnTypeName}</td>
                        <td className="align-middle">{obj.txnProcessTypeName}</td>
                        <td className="align-middle">{obj.statusDesc}</td>
                        <td className="align-middle">{obj.remarks}</td>
                        <td className="align-middle">{obj.deviceId}</td>
                        <td className="align-middle">{obj.operatorName}</td>
                        
                        
                    </tr>
                )
            ))
        }

        return (
            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">Wallet Transection History</h3>
                        </div>
                    </div>
                </div>

                <div className="row mb-6">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="card h-100">

                            <div className="card-body">
                                <form className="row g-3 needs-validation">
                                    <div className="col-md-3 mt-0">
                                        <label data-for="location" className="col-sm-6 col-form-label form-label">Transection Type</label>
                                        <div className="col-md-8 col-12">
                                            <SelectSearchComponent model={this.txnProcessTypeSelectModel} onSelectClicked={(e) => this.onTxnTypeSelectClicked(e)}></SelectSearchComponent>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label">From Date</label>
                                        <DatePicker selected={this.fromDate} onChange={(date: Date) => this.setFromDate(date)} />
                                    </div>
                                    <div className="col-md-3">
                                        <label className="form-label">To Date</label>
                                        <DatePicker selected={this.toDate} onChange={(date: Date) => this.setToDate(date)} />
                                    </div>
                                    <div className="col-md-2 pt-5">
                                        <button className="btn btn-primary" type="submit" onClick={(e) => this.searchAction(e)}>Submit</button>
                                    </div>
                                    <div className="col-md-2 pt-5">
                                        <a className="btn btn-primary" href={this.downloadUrl}>Dowload</a>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row mb-6">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="card h-100">
                            <div className="card-header bg-white py-3">
                                <div className="d-md-flex justify-content-between align-items-center">
                                    <div className="mb-3 mb-lg-0 text-center text-sm-start">
                                        <h4 className="mb-0">Transactions</h4>
                                    </div>
                                    <div className="text-center text-md-start">
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table text-nowrap">
                                    <thead className="table-light">
                                        <tr>
                                            <th>Sno</th>
                                            <th>Transection ID</th>
                                            <th>Transection Time</th>
                                            <th>Channel Id</th>
                                            <th>Channel Name</th>
                                            <th>Msisdn</th>
                                            <th>Amount</th>
                                            <th>After Balance</th>
                                            <th>Action</th>
                                            <th>Transection Type</th>
                                            <th>Process Type</th>
                                            <th>Status</th>
                                            <th>Description</th>
                                            <th>DeviceId</th>
                                            <th>Operator</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {transectionElements}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>

        );
    }
}

export default RetailerWalletTransactions;