import React from 'react';
import DataStore from '../data/DataStore';
import ServerAdaptor from '../data/ServerAdaptor';
import CurrencyModel from '../model/CurrencyModel';
import ForexModel from '../model/ForexModel';
import ListData from '../model/ListData';
import DisplayUtil from './DisplayUtil';
import SelectSearchModel from '../component/SelectSearchModel';
import OptionType from '../component/OptionType';
import SelectSearchComponent from '../component/SelectSearchComponent';

interface ForexProps {
    flag_new: boolean;
};
interface ForexViewState {
	forexModel: ForexModel;
}

class ForexView extends React.Component<ForexProps, ForexViewState> {

    flag_new: boolean = true;
    header: string = "New Forex Record";
    forexModel: ForexModel;
    currencies: CurrencyModel[] = [];
    srcCurrencySelectModel: SelectSearchModel;
    destCurrencySelectModel: SelectSearchModel;

    state: ForexViewState = {
        forexModel: new ForexModel()
    };

    constructor(params: ForexProps) {
        super(params);
        this.forexModel = new ForexModel();
        this.srcCurrencySelectModel = new SelectSearchModel();
        this.destCurrencySelectModel = new SelectSearchModel();
        this.flag_new = params.flag_new;
        if(this.flag_new) {
            this.header = "New Forex Record";
            this.loadData();
        } else {
            this.header = "Edit Forex Record";
            this.forexModel.forexId = DataStore.getSelectedForexId();
            this.loadData();
        }
    }

    updateState() {
        this.setState({
            forexModel: this.forexModel
        });
    }

    async loadData() {
        {
            let model = { "command": "all" };
            let sa = new ServerAdaptor();
            let cr = await sa.get_currencies(model);
            let listData = cr.data as ListData;
            this.currencies = listData.currencies;

            this.srcCurrencySelectModel.clear();
            this.srcCurrencySelectModel.add("0", "Select Src Currency");
            for(var om of this.currencies) {
                this.srcCurrencySelectModel.add(om.currencyId+"", om.currencyName);
            }

            this.destCurrencySelectModel.clear();
            this.destCurrencySelectModel.add("0", "Select Dest Currency");
            for(var om of this.currencies) {
                this.destCurrencySelectModel.add(om.currencyId+"", om.currencyName);
            }
        }
        if (this.flag_new) {
            
        }else {
            let sa = new ServerAdaptor();
            let cr = await sa.get_forex_data(this.forexModel.forexId);
            this.forexModel = cr.data as ForexModel;

            this.srcCurrencySelectModel.setSelected(this.forexModel.srcCurrencyId+"");
            this.destCurrencySelectModel.setSelected(this.forexModel.destCurrencyId+"");
        }
        this.updateState();
    }

    updateInput(e: React.ChangeEvent<HTMLInputElement>, typeHint: string) {
        if (typeHint === "conversionRate") {
            this.forexModel.conversionRate = e.target.value;
        }
        this.updateState();
    }

    updateTextAreaInput(e: React.ChangeEvent<HTMLTextAreaElement>, typeHint: string) {
        if (typeHint === "remarks") {
            this.forexModel.remarks = e.target.value;
        }
        this.updateState();
    }

    onSrcCurrencySelectClicked(e: OptionType) {
        let currencyCode = parseInt(e.value);
        this.forexModel.srcCurrencyId = currencyCode;
    };

    onDestCurrencySelectClicked(e: OptionType) {
        let currencyCode = parseInt(e.value);
        this.forexModel.destCurrencyId = currencyCode;
    };

    async saveAction(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
    
        if(this.flag_new) {
            let sa = new ServerAdaptor();
            let cr = await sa.new_forex_data(this.forexModel);
            if(cr.status === 200) {
                console.log("response : "+cr.data);
                DisplayUtil.showInfoModel("Info", "Success", "", "/forex-list");
            }else {
                if(cr.status === 501) {
                    DisplayUtil.showInfoModel("Information", "Error", JSON.stringify(cr.error), "");
                }else if(cr.status === 502) {
                    DisplayUtil.showInfoModel("Information", "Error", cr.error, "");
                }else {
                    console.log("response : FAILED");
                }
            }
        }else {
            let sa = new ServerAdaptor();
            let cr = await sa.update_forex_data(this.forexModel);
            if(cr.status === 200) {
                console.log("response : "+cr.data);
                DisplayUtil.showInfoModel("Info", "Success", "", "/forex-list");
            }else {
                if(cr.status === 501) {
                    DisplayUtil.showInfoModel("Information", "Error", JSON.stringify(cr.error), "");
                }else if(cr.status === 502) {
                    DisplayUtil.showInfoModel("Information", "Error", cr.error, "");
                }else {
                    console.log("response : FAILED");
                }
            }
        }
    }

    render() {

        return (
            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">{this.header}</h3>
                        </div>
                    </div>
                </div>

                <div className="row mb-8">
                    <div className="col-xl-9 col-lg-8 col-md-12 col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-6">
                                    <h4 className="mb-1">Forex Details</h4>
                                </div>
                                <form>
                                    <div className="mb-3 row">
                                        <label data-for="email" className="col-sm-4 col-form-label form-label">Forex ID</label>
                                        <div className="col-md-8 col-12">
                                            <input type="text" className="form-control" placeholder="" value={this.forexModel.forexId} disabled/>
                                            <div className="invalid-feedback"></div>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="location" className="col-sm-4 col-form-label form-label">Source Currency</label>
                                        <div className="col-md-8 col-12">
                                            <SelectSearchComponent model={this.srcCurrencySelectModel} onSelectClicked={(e) => this.onSrcCurrencySelectClicked(e)}></SelectSearchComponent>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="location" className="col-sm-4 col-form-label form-label">Destination Currency</label>
                                        <div className="col-md-8 col-12">
                                            <SelectSearchComponent model={this.destCurrencySelectModel} onSelectClicked={(e) => this.onDestCurrencySelectClicked(e)}></SelectSearchComponent>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="service_provider_name" className="col-sm-4 col-form-label form-label">Conversion Rate</label>
                                        <div className="col-md-8 col-12">
                                            <input type="text" className="form-control" placeholder="" value={this.forexModel.conversionRate} onChange={(e) => this.updateInput(e, "conversionRate")}/>
                                            <div className="invalid-feedback"></div>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="addressLine" className="col-sm-4 col-form-label form-label">Remarks</label>
                                        <div className="col-md-8 col-12">
                                            <textarea className="form-control" id="textarea-input" data-rows="5" value={this.forexModel.remarks} onChange={(e) => this.updateTextAreaInput(e, "remarks")}></textarea>
                                        </div>
                                    </div>

                                    <div className="row align-items-center">
                                        <div className="offset-md-4 col-md-8 mt-4">
                                            <button type="submit" className="btn btn-primary" onClick={(e) => this.saveAction(e)}>Save Changes</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default ForexView;