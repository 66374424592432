import React from 'react';
import SelectComponentModel from '../component/SelectComponentModel';
import DataStore from '../data/DataStore';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonProps from '../model/CommonProps';
import CommonState from '../model/CommonState';
import ServiceProviderWalletTxnModel from '../model/ServiceProviderWalletTxnModel';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SelectComponent from '../component/SelectComponent';
import ListData from '../model/ListData';
import SelectSearchModel from '../component/SelectSearchModel';
import OptionType from '../component/OptionType';
import SelectSearchComponent from '../component/SelectSearchComponent';

class ServiceProviderWalletTransactions extends React.Component<CommonProps, CommonState> {

    serviceProviderWalletTxn: ServiceProviderWalletTxnModel[] = [];
    txnProcessTypeSelectModel: SelectSearchModel;
    fromDate: Date = new Date();
    toDate: Date = new Date();

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: CommonProps) {
        super(params);
        this.serviceProviderWalletTxn = [];
        this.txnProcessTypeSelectModel = new SelectSearchModel();
        this.loadData();
    }

    updateState() {
        this.setState({
            time: new Date()
        });
    }

    async loadData() {

        this.txnProcessTypeSelectModel.add("0", "All");
        this.txnProcessTypeSelectModel.add("201", "Stock Txn");
        this.txnProcessTypeSelectModel.add("203", "Order Txn");

        this.loadSearchData();

    }

    onTxnTypeSelectClicked(e: OptionType) {
        this.txnProcessTypeSelectModel.setSelected(e.value);
        this.updateState();
    };

    async searchAction(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        this.loadSearchData();
    }

    async loadSearchData() {
        let formattedFromDate = (moment(this.fromDate)).format('YYYY-MM-DD HH:mm:ss');
        let formattedToDate = (moment(this.toDate)).format('YYYY-MM-DD HH:mm:ss');
        let serviceProviderId = DataStore.getSelectedServiceProviderId();
        let txnType = parseInt(this.txnProcessTypeSelectModel.selectedOption.value);
        let model = { "serviceProviderId": serviceProviderId, "txnType": txnType, "fromDate": formattedFromDate, "toDate": formattedToDate };
        let sa = new ServerAdaptor();
        let cr = await sa.get_provider_wallet_transactions(model);
        let listData = cr.data as ListData;
        this.serviceProviderWalletTxn = listData.serviceProviderWalletTxns;
        this.updateState();
    }

    setFromDate(date: Date): void {
        console.log("start date : " + date);
        this.fromDate = date;
        this.updateState();
    }

    setToDate(date: Date): void {
        console.log("start date : " + date);
        this.toDate = date;
        this.updateState();
    }

    render() {

        const transectionElements: JSX.Element[] = [];
        {
            this.serviceProviderWalletTxn.map((obj, index) => (
                transectionElements.push(
                    <tr>
                        <td className="align-middle">
                            <h5 className=" mb-1">{(index+1)}.</h5>
                        </td>
                        <td className="align-middle"><h5 className=" mb-1">{obj.spWalletTxnId}</h5></td>
                        <td className="align-middle">{obj.transectionTime}</td>
                        <td className="align-middle">{obj.txnAmount}</td>
                        <td className="align-middle">{obj.afterAmount}</td>
                        <td className="align-middle">{obj.txnTypeName}</td>
                        <td className="align-middle">{obj.txnProcessTypeName}</td>
                    </tr>
                )
            ))
        }

        return (
            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">Wallet Transection History</h3>
                        </div>
                    </div>
                </div>
                
                <div className="row mb-6">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="card h-100">
                            <div className="card-body">
                                <form className="row g-3 needs-validation">
                                    <div className="col-md-3 mt-0">
                                        <label data-for="location" className="col-sm-6 col-form-label form-label">Transection Type</label>
                                        <div className="col-md-8 col-12">
                                            <SelectSearchComponent model={this.txnProcessTypeSelectModel} onSelectClicked={(e) => this.onTxnTypeSelectClicked(e)}></SelectSearchComponent>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="form-label">From Date</label>
                                        <DatePicker selected={this.fromDate} onChange={(date: Date) => this.setFromDate(date)} />
                                    </div>
                                    <div className="col-md-3">
                                        <label className="form-label">To Date</label>
                                        <DatePicker selected={this.toDate} onChange={(date: Date) => this.setToDate(date)} />
                                    </div>
                                    <div className="col-md-3 pt-5">
                                        <button className="btn btn-primary" type="submit" onClick={(e) => this.searchAction(e)}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mb-6">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="card h-100">
                            <div className="card-header bg-white py-3">
                                <div className="d-md-flex justify-content-between align-items-center">
                                    <div className="mb-3 mb-lg-0 text-center text-sm-start">
                                        <h4 className="mb-0">Transactions</h4>
                                    </div>
                                    <div className="text-center text-md-start">
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table text-nowrap">
                                    <thead className="table-light">
                                        <tr>
                                            <th>Sno</th>
                                            <th>TransectionID</th>
                                            <th>Transection Time</th>
                                            <th>Amount</th>
                                            <th>After Balance</th>
                                            <th>Transection Type</th>
                                            <th>Process Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {transectionElements}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>

        );
    }
}

export default ServiceProviderWalletTransactions;