import React from 'react';
import DataStore from '../data/DataStore';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonProps from '../model/CommonProps';
import CommonState from '../model/CommonState';
import Dropdown from 'react-bootstrap/Dropdown';
import ServiceProviderModel from '../model/ServiceProviderModel';
import ListData from '../model/ListData';

class ServiceProviders extends React.Component<CommonProps, CommonState> {

    serviceProviders: ServiceProviderModel[] =[];

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: CommonProps) {
        super(params);
        this.serviceProviders = [];
        this.loadData();
    }

    updateState() {
        this.setState({
            time: new Date()
        });
    }

    async loadData() {
        let model = { "command": "all" };
        let sa = new ServerAdaptor();
        let cr = await sa.get_service_providers(model);
        let listData = cr.data as ListData;
        this.serviceProviders = listData.serviceProviders;
        this.updateState();
    }

    async viewAction(e: React.MouseEvent<HTMLElement, MouseEvent>, serviceProviderId: number) {
        e.preventDefault();
        DataStore.setSelectedServiceProviderId(serviceProviderId);
        console.log("edit");
        window.location.href = "/service-provider-view/"+serviceProviderId;
    }

    async editAction(e: React.MouseEvent<HTMLElement, MouseEvent>, serviceProviderId: number) {
        e.preventDefault();
        DataStore.setSelectedServiceProviderId(serviceProviderId);
        console.log("edit");
        window.location.href = "/edit-service-provider/"+serviceProviderId;
    }

    async dashboardAction(e: React.MouseEvent<HTMLElement, MouseEvent>, serviceProviderId: number) {
        e.preventDefault();
        console.log("dashboard");
        window.location.href = "/sp-dashboard/"+serviceProviderId;
    }

    async walletAction(e: React.MouseEvent<HTMLElement, MouseEvent>, serviceProviderId: number) {
        e.preventDefault();
        DataStore.setSelectedServiceProviderId(serviceProviderId);
        console.log("edit");
        window.location.href = "/service-provider-wallet/"+serviceProviderId;
    }

    async walletTransactionsAction(e: React.MouseEvent<HTMLElement, MouseEvent>, serviceProviderId: number) {
        e.preventDefault();
        DataStore.setSelectedServiceProviderId(serviceProviderId);
        console.log("edit");
        window.location.href = "/service-provider-wallet-Transactions";
    }

    async providerOperatorAction(e: React.MouseEvent<HTMLElement, MouseEvent>, serviceProviderId: number, serviceProviderName: string) {
        e.preventDefault();
        DataStore.setSelectedProvider(serviceProviderId, serviceProviderName);
        console.log("edit");
        window.location.href = "/service-provider-operators/"+serviceProviderId;
    }

    render() {

        return (
            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">Service Providers</h3>
                        </div>
                    </div>
                </div>

                <div className="row mb-6">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="card h-100">
                            <div className="card-header bg-white py-3">
                                <div className="d-md-flex justify-content-between align-items-center">
                                    <div className="mb-3 mb-lg-0 text-center text-sm-start">
                                        <h4 className="mb-0">Service Providers</h4>
                                    </div>
                                    <div className="text-center text-md-start">
                                        <a type="button" className="btn btn-sm btn-primary" href="/new-service-provider">New</a>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive pb-15">
                                <table className="table text-nowrap">
                                    <thead className="table-light">
                                        <tr>
                                            <th>Sno</th>
                                            <th>Service Provider Code</th>
                                            <th>Service Provider Name</th>
                                            <th>Country</th>
                                            <th>Status</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.serviceProviders.map((ci, index) =>
                                            <tr>
                                                <td className="align-middle">
                                                    <h5 className=" mb-1">{(index+1)}.</h5>
                                                </td>
                                                <td className="align-middle"><h5 className=" mb-1">{ci.serviceProviderCode}</h5></td>
                                                <td className="align-middle">{ci.serviceProviderName}</td>
                                                <td className="align-middle">{ci.countryName}</td>
                                                <td className="align-middle">{ci.statusDesc}</td>
                                                <td className="align-middle">
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="link float-end" id="dropdown-basic">
                                                            <i className="fas fa-ellipsis-v"></i>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item as="button" onClick={(e) => this.viewAction(e, ci.serviceProviderId)}>View</Dropdown.Item>
                                                            <Dropdown.Item as="button" onClick={(e) => this.editAction(e, ci.serviceProviderId)}>Edit</Dropdown.Item>
                                                            <Dropdown.Item as="button" onClick={(e) => this.dashboardAction(e, ci.serviceProviderId)}>Dashboard</Dropdown.Item>
                                                            <Dropdown.Item as="button" onClick={(e) => this.walletAction(e, ci.serviceProviderId)}>Wallet</Dropdown.Item>
                                                            <Dropdown.Item as="button" onClick={(e) => this.providerOperatorAction(e, ci.serviceProviderId, ci.serviceProviderName)}>Provider-Operators</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default ServiceProviders;