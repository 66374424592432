class ServiceProviderWalletModel {

    serviceProviderId: number = 0;
    serviceProviderName: string = "";
    amount: number = 0;
    currencyCode: number = 0;
    currencyName: string = "";
    
}

export default ServiceProviderWalletModel;