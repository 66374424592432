import string from "../../css/core-js/features/string";
import TableModel from "../component/TableModel";
import TableRowModel from "../component/TableRowModel";
import TableDataModel from "../model/TableDataModel";

class TableUtil {

    loadTableData(tableDataModel: TableDataModel, tableModel: TableModel) {
        tableModel.reset();
        if(tableDataModel != null) {
            for (let value of tableDataModel.header.values) {
                tableModel.headers.addTextCell(value);
            }
            for (let row of tableDataModel.rows) {
                var rowModel = new TableRowModel();
                var firstColumn = true;
                for (let value of row.values) {
                    if(firstColumn) {
                        rowModel.addHeaderCell(value);
                        firstColumn = false;
                    }else {
                        rowModel.addTextCell(value);
                    }
                }
                tableModel.rows.push(rowModel);
            }
        }
    }

}

export default TableUtil;