import React from 'react';
import DataStore from '../data/DataStore';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonProps from '../model/CommonProps';
import CommonState from '../model/CommonState';
import Dropdown from 'react-bootstrap/Dropdown';
import ListData from '../model/ListData';
import ClientModel from '../model/ClientModel';
import ClientOperatorModel from '../model/ClientOperatorModel';
import URLUtil from '../data/URLUtil';

class ClientOperatorList extends React.Component<CommonProps, CommonState> {

    clientModel: ClientModel;
    clientOperators: ClientOperatorModel[] = [];

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: CommonProps) {
        super(params);
        this.clientModel = new ClientModel();
        this.clientModel.channelId = URLUtil.getPathParamNumber(2);
        this.clientModel.clientName = "";
        this.loadData();
    }

    updateState() {
        this.setState({
            time: new Date()
        });
    }

    async loadData() {
        // {
        //     let sa = new ServerAdaptor();
        //     let cr = await sa.get_client(this.clientModel.channelId);
        //     let clientModel = cr.data as ClientModel;
        //     if(clientModel != null) {
        //         this.clientModel.clientName = clientModel.clientName;
        //     }
        // }
        {
            let sa = new ServerAdaptor();
            let cr = await sa.get_client_services(this.clientModel.channelId);
            let listData = cr.data as ListData;
            this.clientOperators = listData.clientOperators;
        }
        this.updateState();
    }

    async newAction(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        window.location.href = "/new-client-operator/"+this.clientModel.channelId;
    }

    async editAction(e: React.MouseEvent<HTMLElement, MouseEvent>, obj: ClientOperatorModel) {
        e.preventDefault();
        //DataStore.setSelectedProfileServiceId(selectedSno);
        window.location.href = "/edit-client-operator/"+obj.channelOperatorId;
    }

    render() {

        return (
            <div className="container-fluid p-6">
                
                <div className="row mb-6">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="card h-100">
                            <div className="card-header bg-white py-3">
                                <div className="d-md-flex justify-content-between align-items-center">
                                    <div className="mb-3 mb-lg-0 text-center text-sm-start">
                                        <h4 className="mb-0">Client Operators & Commissions</h4>
                                    </div>
                                    <div className="text-center text-md-start">
                                        <button type="button" className="btn btn-sm btn-primary" onClick={(e) => this.newAction(e)}>New</button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table text-nowrap">
                                    <thead className="table-light">
                                        <tr>
                                            <th>Sno</th>
                                            <th>Client</th>
                                            <th>Operator</th>
                                            <th>Operator-Country</th>
                                            <th>Denom - Range</th>
                                            <th>Commission</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.clientOperators.map((po, index) =>
                                            <tr>
                                                <td className="align-middle">
                                                    <h5 className=" mb-1">{(index+1)}.</h5>
                                                </td>
                                                <td className="align-middle"><h5 className=" mb-1">{po.channelCode}</h5></td>
                                                <td className="align-middle">{po.operatorName}</td>
                                                <td className="align-middle">{po.operatorCountry}</td>
                                                <td className="align-middle">{po.denominationMin} - {po.denominationMax}</td>
                                                <td className="align-middle">{po.commissionValue}</td>
                                                <td className="align-middle">
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="link float-end" id="dropdown-basic">
                                                            <i className="fas fa-ellipsis-v"></i>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item as="button" onClick={(e) => this.editAction(e, po)}>Edit</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default ClientOperatorList;