import React from 'react';
import CommonProps from '../model/CommonProps';
import CommonState from '../model/CommonState';
import URLUtil from '../data/URLUtil';

class ReportDashoard extends React.Component<CommonProps, CommonState> {

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: CommonProps) {
        super(params);
    }

    updateState() {
        this.setState({
            time: new Date()
        });
    }

    render() {

        return (

            <div className="container-fluid p-6 bg-light h-100">

                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">Reports</h3>
                        </div>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-md-4 col-12 mb-3">
                        <div className="card h-100">
                            <div className="card-header bg-white py-4">
                                <h4 className="mb-0">Client Wallet Transactions</h4>
                            </div>
                            <div className="card-body">
                                <div className="list-group">
                                    <a href="/report/stock" className="list-group-item list-group-item-action">Stock Transactions</a>
                                    <a href="/report/orders" className="list-group-item list-group-item-action">Order Transactions</a>
                                    <a href="/report/commission" className="list-group-item list-group-item-action">Commission Transactions</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-12 mb-3">
                        <div className="card h-100">
                            <div className="card-header bg-white py-4">
                                <h4 className="mb-0">Transactions By Service Provider</h4>
                            </div>
                            <div className="card-body">
                                <div className="list-group">
                                    <a href="/sp-report/sp-stock" className="list-group-item list-group-item-action">Stock Transactions</a>
                                    <a href="/sp-report/sp-transaction-all" className="list-group-item list-group-item-action">Order Transactions</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-12 mb-3">
                        <div className="card h-100">
                            <div className="card-header bg-white py-4">
                                <h4 className="mb-0">Recharges</h4>
                            </div>
                            <div className="card-body">
                                <div className="list-group">
                                    <a href="/report/transaction-all" className="list-group-item list-group-item-action">All Transactions</a>
                                    <a href="/report/transaction-success" className="list-group-item list-group-item-action">Success Transactions</a>
                                    <a href="/report/transaction-failed" className="list-group-item list-group-item-action">Failed Transactions</a>
                                    <a href="/report/transaction-pending" className="list-group-item list-group-item-action">Pending Transactions</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default ReportDashoard;