import React from 'react';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonProps from '../model/CommonProps';
import APIModels from '../model/APIModels';
import Accordion from 'react-bootstrap/Accordion';

interface APIModelsState {
    apiModels: APIModels;
}

class APIDocumentation extends React.Component<CommonProps, APIModelsState> {

    apiModels: APIModels;

    state: APIModelsState = {
        apiModels: new APIModels()
    };

    constructor(params: CommonProps) {
        super(params);
        this.apiModels = new APIModels();
        this.loadData();
    }

    updateState() {
        this.setState({
            apiModels: this.apiModels
        });
    }

    async loadData() {
        let model = { "command": "all" };
        let sa = new ServerAdaptor();
        let cr = await sa.get_apis(model);
        let respData = cr.data as APIModels;
        this.apiModels = respData;
        this.updateData();
        this.updateState();
    }

    async updateData() {
        for (let api of this.apiModels.apis) {
            if (api.request != null) {
                api.requestTxt = JSON.stringify(api.request, undefined, 2);
            } else {
                api.requestTxt = "";
            }
            if (api.response != null) {
                api.responseTxt = JSON.stringify(api.response, undefined, 2);
            } else {
                api.responseTxt = "";
            }
        }
    }

    render() {

        return (
            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">APIs</h3>
                        </div>
                        <p>{this.apiModels.description}</p>
                    </div>
                </div>

                <div className="row mb-6">
                    <div className="col-lg-12 col-md-12 col-12">

                        <Accordion defaultActiveKey="0">

                            {this.apiModels.apis.map((api, index) =>
                                <Accordion.Item eventKey={index + ""}>
                                    <Accordion.Header>{(index + 1)}. {api.name}</Accordion.Header>
                                    <Accordion.Body>
                                        <p><strong>Description </strong><span>{api.description}</span></p>
                                        <>
                                            {api.url != null ? (<p><strong>Url </strong><span>{api.url}</span></p>) : null}
                                        </>
                                        <>
                                            {api.request != null ? (<>
                                                <p className="mb-1"><strong>Request</strong></p>
                                                <pre>
                                                    {api.requestTxt}
                                                </pre></>) : null}
                                        </>
                                        <>
                                            {api.response != null ? (<>
                                                <p className="mb-1"><strong>Response</strong></p>
                                                <pre>
                                                    {api.responseTxt}
                                                </pre></>) : null}
                                        </>
                                        <>
                                            {api.headerParams != null ? (
                                            <>
                                                <p className="mb-1"><strong>Header Parameters</strong></p>
                                                <div className="table-responsive pb-1">
                                                    <table className="table table-bordered">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th style={{ "width": "5%" }}>Sno</th>
                                                                <th style={{ "width": "20%" }}>Parameter</th>
                                                                <th>Description</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {api.headerParams.map((param, index1) =>
                                                                <tr>
                                                                    <td className="align-middle">
                                                                        <h5 className=" mb-1">{(index1 + 1)}</h5>
                                                                    </td>
                                                                    <td className="align-middle"><h5 className=" mb-1">{param.param}</h5></td>
                                                                    <td className="align-middle">{param.description}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>) : null}
                                        </>
                                        <>
                                            {api.requestParams != null ? (
                                            <>
                                                <p className="mb-1"><strong>Request Parameters</strong></p>
                                                <div className="table-responsive pb-1">
                                                    <table className="table table-bordered">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th style={{ "width": "5%" }}>Sno</th>
                                                                <th style={{ "width": "20%" }}>Parameter</th>
                                                                <th>Description</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {api.requestParams.map((param, index1) =>
                                                                <tr>
                                                                    <td className="align-middle">
                                                                        <h5 className=" mb-1">{(index1 + 1)}</h5>
                                                                    </td>
                                                                    <td className="align-middle"><h5 className=" mb-1">{param.param}</h5></td>
                                                                    <td className="align-middle">{param.description}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>) : null}
                                        </>
                                        <>
                                            {api.responseParams != null ? (
                                            <>
                                                <p className="mb-1"><strong>Response Parameters</strong></p>
                                                <div className="table-responsive pb-1">
                                                    <table className="table table-bordered">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th style={{ "width": "5%" }}>Sno</th>
                                                                <th style={{ "width": "20%" }}>Parameter</th>
                                                                <th>Description</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {api.responseParams.map((param, index1) =>
                                                                <tr>
                                                                    <td className="align-middle">
                                                                        <h5 className=" mb-1">{(index1 + 1)}</h5>
                                                                    </td>
                                                                    <td className="align-middle"><h5 className=" mb-1">{param.param}</h5></td>
                                                                    <td className="align-middle">{param.description}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>) : null}
                                        </>
                                    </Accordion.Body>
                                </Accordion.Item>
                            )}
                        </Accordion>


                    </div>
                </div>

            </div>

        );
    }
}

export default APIDocumentation;