class OperatorModel {

    operatorId: number = 0;
    operatorCode: string = "";
    operatorName: string = "";
    serviceTypeId: number = 0;
    serviceTypeName: string = "";
    countryId: number = 0;
    countryName: string = "";
    currencyId: number = 0;
    currencyName: string = "";
    operatorLogo: string = "";
    printTemplateId: number = 0;
    status: number = 0;
    statusDesc: string = "";
    remarks: string = "";
    
}

export default OperatorModel;