class RetailerWalletModel {

    channelId: number = 0;
    retailerName: string = "";
    amount: number = 0;
    currencyCode: number = 0;
    currencyName: string = "";
    
}

export default RetailerWalletModel;