import TableDataRowModel from "./TableDataRowModel";

class TableDataModel {

    name: string = "";
    header: TableDataRowModel = new TableDataRowModel();
    rows: TableDataRowModel[] = [];

}

export default TableDataModel;