import React from 'react';
import CommonProps from '../model/CommonProps';
import CommonState from '../model/CommonState';
import ServerAdaptor from '../data/ServerAdaptor';
import LoginModel from '../model/LoginModel';
import DisplayUtil from './DisplayUtil';
import DataStore from '../data/DataStore';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ErrorResponse from '../util/ErrorResponse';
import FormUtil from '../util/FormUtil';

class ResetPasswordView extends React.Component<CommonProps, CommonState> {

    model: LoginModel;
    formId: string = "form1";

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: CommonProps) {
        super(params);
        this.model = DataStore.getUser();
    }

    handleChange(typeHint: string, e: any) {
        let fieldName = e.target.name;
        let fleldVal = e.target.value;
        if (typeHint === "otp") {
            this.model.otp = e.target.value;
        }
        if (typeHint === "newPassword") {
            this.model.newPassword = e.target.value;
        }
        if (typeHint === "conformPassword") {
            this.model.conformPassword = e.target.value;
        }
        FormUtil.updateFormState(this.formId);
        this.updateState();
    }

    updateState() {
        this.setState({
            time: new Date()
        });
    }

    async resetPasswordAction(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();

        let sa = new ServerAdaptor();
        let cr = await sa.reset_password_request(this.model);
        if (cr.status === 200) {
            console.log("response : " + cr.data);
            DisplayUtil.showInfoModel("Information", "Success", "", "/signin");
        } else {
            if (cr.status === 501) {
                FormUtil.showFormErrors(cr.error as ErrorResponse, this.formId);
            } else {
                console.log("response : FAILED");
                DisplayUtil.showInfoModel("Information", "Error", cr.error, "");
            }
        }
    }

    async closeInfoModelAction(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        console.log("closeInfoModelAction");
        DisplayUtil.hideInfoModel();
    }

    render() {
        return (
            <div className="bg-light">
                <div className="container d-flex flex-column">
                    <div className="row align-items-center justify-content-center g-0
					min-vh-100">
                        <div className="col-12 col-md-8 col-lg-6 col-xxl-4 py-8 py-xl-0">

                            <div className="card smooth-shadow-md">

                                <div className="card-body p-6">
                                    <div className="mb-4 text-center">
                                        <a href="../index.html"><img src="./Logo.png" className="mb-2" alt="" height={"80px"} width={"300px"} /></a>
                                        <p className="mb-5 mt-2 text-center">Please enter your user information.</p>
                                    </div>

                                    <Form id={this.formId} noValidate className="">

                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="12">
                                                <Form.Label>Otp</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Otp"
                                                    defaultValue=""
                                                    value={this.model.otp}
                                                    onChange={this.handleChange.bind(this, "otp")}
                                                />
                                                <Form.Control.Feedback id="otp_feedback" type="invalid"></Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>

                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="12">
                                                <Form.Label>New Password</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="password"
                                                    placeholder="New Password"
                                                    defaultValue=""
                                                    value={this.model.newPassword}
                                                    onChange={this.handleChange.bind(this, "newPassword")}
                                                />
                                                <Form.Control.Feedback id="newPassword_feedback" type="invalid"></Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>

                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="12">
                                                <Form.Label>Conform Password</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="password"
                                                    placeholder="Conform Password"
                                                    defaultValue=""
                                                    value={this.model.conformPassword}
                                                    onChange={this.handleChange.bind(this, "conformPassword")}
                                                />
                                                <Form.Control.Feedback id="conformPassword_feedback" type="invalid"></Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>

                                        <div className="mt-8">

                                            <div className="d-grid">
                                                <button type="submit" className="btn btn-primary" onClick={(e) => this.resetPasswordAction(e)}>Submit</button>
                                            </div>

                                            <div className="d-md-flex justify-content-between mt-4">
                                                <div className="mb-2 mb-md-0">

                                                </div>
                                                <div>
                                                    <a href="/signin" className="text-inherit
													fs-5">Back to Signin</a>
                                                </div>

                                            </div>
                                        </div>

                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="infoModel" data-tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-path="">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="infoModel-header">Modal title</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body pb-1" id="infoModel-body">

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-sm btn-primary px-5" onClick={(e) => this.closeInfoModelAction(e)}>Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show" id="infoModelBackdrop" style={{ "display": "none" }}></div>

                </div>

            </div>
        );
    }
}

export default ResetPasswordView;