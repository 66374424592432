import React from 'react';
import DataStore from '../data/DataStore';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonState from '../model/CommonState';
import DisplayUtil from './DisplayUtil';
import VoucherPinModel from '../model/VoucherPinModel';
import moment from 'moment';
import DatePicker from "react-datepicker";

interface OperatorProps {
    flag_new: boolean;
};

class VoucherPinFormView extends React.Component<OperatorProps, CommonState> {

    flag_new: boolean = true;
    header: string = "New Voucher Pin";
    voucherPinModel: VoucherPinModel;
    expiryDate: Date = new Date();

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: OperatorProps) {
        super(params);
        this.voucherPinModel = new VoucherPinModel();
        this.flag_new = params.flag_new;
        if(this.flag_new) {
            this.header = "New Voucher Pin";
            this.voucherPinModel.productId = DataStore.getSelectedProductId();
        } else {
            this.header = "Edit Voucher Pin";
            this.voucherPinModel.voucherPinId = DataStore.getSelectedVoucherPinId();
        }
    }

    componentDidMount() {
        this.loadData();
    }

    updateState() {
        this.setState({
            time: new Date()
        });
    }

    async loadData() {
        
        if (this.flag_new) {
            
        }else {
            let model = { "voucherPinId": this.voucherPinModel.voucherPinId };
            let sa = new ServerAdaptor();
            let cr = await sa.get_voucher(model);
            this.voucherPinModel = cr.data as VoucherPinModel;

            try {
                let expiryDate = (moment(this.voucherPinModel.expiryDateTxt, 'YYYY-MM-DD')).toDate();
                this.expiryDate = expiryDate;
            }catch(e) {
                console.log(e);
            }
        }

        this.updateState();
    }

    updateInput(e: React.ChangeEvent<HTMLInputElement>, typeHint: string) {
        if (typeHint === "voucherSerialNo") {
            this.voucherPinModel.voucherSerialNo = e.target.value;
        }
        if (typeHint === "voucherPin") {
            this.voucherPinModel.voucherPin = e.target.value;
        }
        if (typeHint === "barCode") {
            this.voucherPinModel.barCode = e.target.value;
        }
        this.updateState();
    }

    setExpiryDate(date: Date): void {
        console.log("start date : " + date);
        this.expiryDate = date;
        let formattedDate = (moment(this.expiryDate)).format('YYYY-MM-DD');
        this.voucherPinModel.expiryDateTxt = formattedDate;
        this.updateState();
    }

    async saveAction(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
    
        if(this.flag_new) {
            let sa = new ServerAdaptor();
            let cr = await sa.new_voucher(this.voucherPinModel);
            if(cr.status === 200) {
                console.log("response : "+cr.data);
                DisplayUtil.showInfoModel("Information", "Success", "", "/operator-products");
            }else {
                if(cr.status === 501) {
                    DisplayUtil.showInfoModel("Information", "Error", JSON.stringify(cr.error), "");
                }else if(cr.status === 502) {
                    DisplayUtil.showInfoModel("Information", "Error", cr.error, "");
                }else {
                    console.log("response : FAILED");
                }
            }
        }else {
            let sa = new ServerAdaptor();
            let cr = await sa.update_voucher(this.voucherPinModel);
            if(cr.status === 200) {
                console.log("response : "+cr.data);
                DisplayUtil.showInfoModel("Information", "Success", "", "/operator-products");
            }else {
                if(cr.status === 501) {
                    DisplayUtil.showInfoModel("Information", "Error", JSON.stringify(cr.error), "");
                }else if(cr.status === 502) {
                    DisplayUtil.showInfoModel("Information", "Error", cr.error, "");
                }else {
                    console.log("response : FAILED");
                }
            }
        }
    }

    render() {

        return (
            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">{this.header}</h3>
                        </div>
                    </div>
                </div>

                <div className="row mb-8">
                    <div className="col-xl-9 col-lg-8 col-md-12 col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-6">
                                    <h4 className="mb-1">Voucher Pin Details</h4>
                                </div>
                                <form>
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Voucher Serial No</label>
                                        <div className="col-md-8 col-12">
                                            <input type="text" className="form-control" placeholder="Serial No" value={this.voucherPinModel.voucherSerialNo} onChange={(e) => this.updateInput(e, "voucherSerialNo")}/>
                                            <div className="invalid-feedback"></div>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Pin</label>
                                        <div className="col-md-8 col-12">
                                            <input type="text" className="form-control" placeholder="Pin" value={this.voucherPinModel.voucherPin} onChange={(e) => this.updateInput(e, "voucherPin")}/>
                                            <div className="invalid-feedback"></div>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Bar Code</label>
                                        <div className="col-md-8 col-12">
                                            <input type="text" className="form-control" placeholder="Bar Code" value={this.voucherPinModel.barCode} onChange={(e) => this.updateInput(e, "barCode")}/>
                                            <div className="invalid-feedback"></div>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Expiry Date</label>
                                        <div className="col-md-8 col-12">
                                            <DatePicker selected={this.expiryDate} onChange={(date: Date) => this.setExpiryDate(date)} />
                                        </div>
                                    </div>

                                    <div className="row align-items-center">
                                        <div className="offset-md-4 col-md-8 mt-4">
                                            <button type="submit" className="btn btn-primary" onClick={(e) => this.saveAction(e)}>Save Changes</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default VoucherPinFormView;