import React from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import SampleView from './SampleView';
import CommonState from '../model/CommonState';
import Operators from './Operators';
import ServiceProviders from './ServiceProviders';
import OperatorFormView from './OperatorFormView';
import ForexListView from './ForexListView';
import ForexView from './ForexView';
import ClientOperatorList from './ClientOperatorList';
import ClientOperatorView from './ClientOperatorView';

import ServiceProviderWalletView from './ServiceProviderWalletView';
import OrderPlaceView from './OrderPlaceView';
import ServiceProviderWalletUpdateView from './ServiceProviderWalletUpdateView';
import OperatorView from './OperatorView';
import ServiceProviderOperators from './ServiceProviderOperators';
import ServiceProviderFormView from './ServiceProviderFormView';
import ServiceProviderView from './ServiceProviderView';
import ServiceProviderOperatorFormView from './ServiceProviderOperatorFormView';
import ServiceProviderOperatorView from './ServiceProviderOperatorView';
import ReportDashoard from './ReportDashboard';
import ServiceProviderRouting from './ServiceProviderRouting';
import OperatorProducts from './OperatorProducts';
import VoucherPinFormView from './VoucherPinFormView';
import ProductFormView from './ProductFormView';
import ProductVouchers from './ProductVouchers';
import VoucherBulkUploadView from './VoucherBulkUploadView';
import DataStore from '../data/DataStore';
import ReportView from './ReportView';
import Notifications from './Notifications';
import ProductView from './ProductView';
import APIDocumentation from './APIDocumentation';
import OperatorsAPI from './OperatorsAPI';
import ServiceProviderWalletTransactions from './ServiceProviderWalletTransactions';
import RetailerWalletTransactions from './RetailerWalletTransactions';
import TransectionEnquiry from './TransactionEnquiry';
import Dashboard from './Dashboard';
import ClientsList from './ClientsList';
import ClientsListSelect from './ClientsListSelect';
import ClientDashboard from './ClientDashboard';
import ServiceProviderDashboard from './ServiceProviderDashboard';
import ClientFormView from './ClientFormView';
import ClientView from './ClientView';
import ClientWalletView from './ClientWalletView';
import ClientWalletUpdateView from './ClientWalletUpdateView';
import ClientWalletAdjustmentView from './ClientWalletAdjustmentView';
import Products from './Products';
import ReportDashoardClient from './ReportDashboardClient';
import ReportViewSP from './ReportViewSP';
import AdminDashboard from './AdminDashboard';

interface AdminViewProps {
  view: string;
};

class AdminView extends React.Component<AdminViewProps, CommonState> {

  state: CommonState = {
    time: new Date(),
  };

  // componentWillMount(): void {
  //   DataStore.checkLoginAndRedirect();
  // }

  componentDidUpdate(prevProps: Readonly<AdminViewProps>, prevState: Readonly<CommonState>, snapshot?: any): void {
    DataStore.checkLoginAndRedirect();
  }

  render() {

    var component;

    if (this.props.view === "dashboard") {
      component = <ClientDashboard message="" />;
    } else if (this.props.view === "admin-dashboard") {
      component = <AdminDashboard message="" />;
    } else if (this.props.view === "sp-dashboard") {
      component = <ServiceProviderDashboard message="" />;
    } else if (this.props.view === "notifications") {
      component = <Notifications message="" />;
    }
    
    else if (this.props.view === "api-operators") {
      component = <OperatorsAPI message="" />;
    } else if (this.props.view === "operators") {
      component = <Operators message="" />;
    } else if (this.props.view === "operator-view") {
      component = <OperatorView message="" />;
    } else if (this.props.view === "new-operator") {
      component = <OperatorFormView flag_new={true} />;
    } else if (this.props.view === "edit-operator") {
      component = <OperatorFormView flag_new={false} />;
    } else if (this.props.view === "operator-products") {
      component = <OperatorProducts message="" />;
    } else if (this.props.view === "new-product") {
      component = <ProductFormView flag_new={true} />;
    } else if (this.props.view === "edit-product") {
      component = <ProductFormView flag_new={false} />;
    } else if (this.props.view === "product-view") {
      component = <ProductView message="" />;
    } else if (this.props.view === "product-vouchers") {
      component = <ProductVouchers message="" />;
    } else if (this.props.view === "new-voucher") {
      component = <VoucherPinFormView flag_new={true} />;
    } else if (this.props.view === "edit-voucher") {
      component = <VoucherPinFormView flag_new={false} />;
    } else if (this.props.view === "voucher-bulk-upload") {
      component = <VoucherBulkUploadView message="" />;
    }
    
    else if (this.props.view === "service-providers") {
      component = <ServiceProviders message="" />;
    } else if (this.props.view === "service-provider-view") {
      component = <ServiceProviderView message="" />;
    } else if (this.props.view === "new-service-provider") {
      component = <ServiceProviderFormView flag_new={true} />;
    } else if (this.props.view === "edit-service-provider") {
      component = <ServiceProviderFormView flag_new={false} />;
    } else if (this.props.view === "service-provider-wallet") {
      component = <ServiceProviderWalletView message="" />;
    } else if (this.props.view === "update-provider-wallet") {
      component = <ServiceProviderWalletUpdateView message="" />;
    } else if (this.props.view === "service-provider-wallet-Transactions") {
      component = <ServiceProviderWalletTransactions message="" />;
    } else if (this.props.view === "service-provider-operators") {
      component = <ServiceProviderOperators message="" />;
    } else if (this.props.view === "service-provider-operator-mapping-view") {
      component = <ServiceProviderOperatorView message="" />;
    } else if (this.props.view === "new-service-provider-operator-mapping") {
      component = <ServiceProviderOperatorFormView flag_new={true} />;
    } else if (this.props.view === "edit-service-provider-operator-mapping") {
      component = <ServiceProviderOperatorFormView flag_new={false} />;
    } else if (this.props.view === "service-provider-routing") {
      component = <ServiceProviderRouting message="" />;
    }
    
    
    else if (this.props.view === "client-select") {
      component = <ClientsListSelect message="" />;
    } else if (this.props.view === "clients") {
      component = <ClientsList message="" />;
    } else if (this.props.view === "client-view") {
      component = <ClientView message="" />;
    } else if (this.props.view === "new-client") {
      component = <ClientFormView flag_new={true} />;
    } else if (this.props.view === "edit-client") {
      component = <ClientFormView flag_new={false} />;
    } else if (this.props.view === "client-wallet") {
      component = <ClientWalletView message="" />;
    } else if (this.props.view === "update-client-wallet") {
      component = <ClientWalletUpdateView message="" />;
    } else if (this.props.view === "adjust-client-wallet") {
      component = <ClientWalletAdjustmentView message="" />;
    } else if (this.props.view === "retailer-wallet-Transactions") {
      component = <RetailerWalletTransactions message="" />;
    }
    
    else if (this.props.view === "forex-list") {
      component = <ForexListView message="" />;
    } else if (this.props.view === "new-forex") {
      component = <ForexView flag_new={true} />;
    } else if (this.props.view === "edit-forex") {
      component = <ForexView flag_new={false} />;
    }
    
    else if (this.props.view === "client-operators") {
      component = <ClientOperatorList message="" />;
    } else if (this.props.view === "new-client-operator") {
      component = <ClientOperatorView flag_new={true} />;
    } else if (this.props.view === "edit-client-operator") {
      component = <ClientOperatorView flag_new={false} />;
    }
    
    else if (this.props.view === "products") {
      component = <Products message="" />;
    } else if (this.props.view === "order-placing") {
      component = <OrderPlaceView message="" />;
    }
    
    else if (this.props.view === "transaction-enquiry") {
      component = <TransectionEnquiry message="" />;
    }
    
    else if (this.props.view === "report-dashboard") {
      component = <ReportDashoard message="" />;
    } else if (this.props.view === "client-dashboard") {
      component = <ReportDashoardClient message="" />;
    } else if (this.props.view === "report") {
      component = <ReportView message="report" />;
    } else if (this.props.view === "sp-report") {
      component = <ReportViewSP message="report" />;
    }
    
    else if (this.props.view === "api-documentation") {
      component = <APIDocumentation message="" />;
    } else {
      component = <SampleView message="" />;
    }
    
    return (
      <div id="db-wrapper">
        <Sidebar message=""/>
        <div id="page-content">
          <Header message=""/>
          {component}
        </div>
      </div>
    );
  }

}

export default AdminView;