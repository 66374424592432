import React from 'react';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonProps from '../model/CommonProps';
import CommonState from '../model/CommonState';
import ServiceProviderWalletModel from '../model/ServiceProviderWalletModel';
import URLUtil from '../data/URLUtil';

class ServiceProviderWalletView extends React.Component<CommonProps, CommonState> {

    serviceProviderWalletModel: ServiceProviderWalletModel;

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: CommonProps) {
        super(params);
        this.serviceProviderWalletModel = new ServiceProviderWalletModel();
        this.serviceProviderWalletModel.serviceProviderId = URLUtil.getPathParamNumber(2);
        this.loadData();
    }

    async loadData() {
        let sa = new ServerAdaptor();
        let cr = await sa.get_provider_wallet_info(this.serviceProviderWalletModel.serviceProviderId);
        this.serviceProviderWalletModel = cr.data as ServiceProviderWalletModel;
        this.setState({
            time: new Date()
        });
    }

    render() {
        let url = "/update-provider-wallet/"+this.serviceProviderWalletModel.serviceProviderId;
        return (
            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <div className="d-md-flex justify-content-between align-items-center">
                                <div className="mb-3 mb-lg-0 text-center text-sm-start">
                                    <h4 className="mb-0">Wallet Details</h4>
                                </div>
                                <div className="text-center text-md-start">
                                    <a type="button" className="btn btn-sm btn-primary" href={url}>Add Credits</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mb-6">
                    <div className="col-md-8 col-12">
                        <div className="card" >
                            <div className="card-body">
                                <div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">Service Provider</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.serviceProviderWalletModel.serviceProviderName}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">Available balance</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.serviceProviderWalletModel.amount +" ("+ this.serviceProviderWalletModel.currencyName+")"}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default ServiceProviderWalletView;