class ServiceProviderModel {

    serviceProviderId: number = 0;
    serviceProviderCode: string = "";
    serviceProviderName: string = "";
    countryId: number = 0;
    countryName: string = "";
    currencyId: number = 0;
    currencyCode: string = "";
    contactPerson: string = "";
    mobile: string = "";
    email: string = "";
    status: number = 0;
    statusDesc: string = "";
    remarks: string = "";
    
}

export default ServiceProviderModel;