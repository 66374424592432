import React from 'react';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonProps from '../model/CommonProps';
import CommonState from '../model/CommonState';
import ServiceProviderModel from '../model/ServiceProviderModel';
import DisplayUtil from './DisplayUtil';
import ServiceProviderOperatorModel from '../model/ServiceProviderOperatorModel';
import ListData from '../model/ListData';

class ServiceProviderRouting extends React.Component<CommonProps, CommonState> {

    serviceProviders: ServiceProviderModel[] =[];
    serviceProviderOperators: ServiceProviderOperatorModel[] = [];

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: CommonProps) {
        super(params);
        this.serviceProviders = [];
        this.serviceProviderOperators = [];
        this.loadData();
    }

    updateState() {
        this.setState({
            time: new Date()
        });
    }

    async loadData() {
        {
            let model = { "command": "all" };
            let sa = new ServerAdaptor();
            let cr = await sa.get_service_providers(model);
            let listData = cr.data as ListData;
            this.serviceProviders = listData.serviceProviders;
        }
        {
            let model = { "command": "all" };
            let sa = new ServerAdaptor();
            let cr = await sa.get_service_provider_operators_all(model);
            let listData = cr.data as ListData;
            this.serviceProviderOperators = listData.serviceProviderOperators;
        }
        this.updateState();
    }

    updateInput(e: React.ChangeEvent<HTMLInputElement>, typeHint: string, serviceProviderModel: ServiceProviderModel) {
        if (typeHint === "status") {
            var el = e.target as HTMLInputElement;
            var isChecked = el.checked;
            if (isChecked) {
                serviceProviderModel.status = 1;
            } else {
                serviceProviderModel.status = 0;
            }
            this.updateStatus(serviceProviderModel);
        }
    }

    updateOperator(e: React.ChangeEvent<HTMLInputElement>, typeHint: string, serviceProviderOperatorModel: ServiceProviderOperatorModel) {
        if (typeHint === "status") {
            var el = e.target as HTMLInputElement;
            var isChecked = el.checked;
            if (isChecked) {
                serviceProviderOperatorModel.status = 1;
            } else {
                serviceProviderOperatorModel.status = 0;
            }
            this.updateOperatorStatus(serviceProviderOperatorModel);
        }
    }

    async updateStatus(serviceProviderModel: ServiceProviderModel) {

        let sa = new ServerAdaptor();
        let cr = await sa.update_service_provider_route(serviceProviderModel);
        if (cr.status === 200) {
            console.log("response : " + cr.data);
            DisplayUtil.showInfoModel("Information", "Success", "", "");
            this.loadData();
        } else {
            if (cr.status === 501) {
                DisplayUtil.showInfoModel("Information", "Error", JSON.stringify(cr.error), "");
            } else if (cr.status === 502) {
                DisplayUtil.showInfoModel("Information", "Error", cr.error, "");
            } else {
                console.log("response : FAILED");
            }
        }
    }

    async updateOperatorStatus(serviceProviderOperatorModel: ServiceProviderOperatorModel) {

        let sa = new ServerAdaptor();
        let cr = await sa.update_service_provider_operator_route(serviceProviderOperatorModel);
        if (cr.status === 200) {
            console.log("response : " + cr.data);
            DisplayUtil.showInfoModel("Information", "Success", "", "");
            this.loadData();
        } else {
            if (cr.status === 501) {
                DisplayUtil.showInfoModel("Information", "Error", JSON.stringify(cr.error), "");
            } else if (cr.status === 502) {
                DisplayUtil.showInfoModel("Information", "Error", cr.error, "");
            } else {
                console.log("response : FAILED");
            }
        }
    }

    render() {

        return (
            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">Service Providers</h3>
                        </div>
                    </div>
                </div>

                <div className="row mb-6">
                    <div className="col-lg-8 col-md-8 col-12">
                        <div className="card h-100">
                            <div className="card-header bg-white py-3">
                                <div className="d-md-flex justify-content-between align-items-center">
                                    <div className="mb-3 mb-lg-0 text-center text-sm-start">
                                        <h4 className="mb-0">Operators</h4>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table text-nowrap">
                                    <thead className="table-light">
                                        <tr>
                                            <th>Sno</th>
                                            <th>Operator Name</th>
                                            <th>Service Provider Name</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.serviceProviderOperators.map((ci, index) =>
                                            <tr>
                                                <td className="align-middle">
                                                    <h5 className="mb-1">{(index + 1)}.</h5>
                                                </td>
                                                <td className="align-middle"><h5 className=" mb-1">{ci.operatorName}</h5></td>
                                                <td className="align-middle">{ci.serviceProviderName}</td>
                                                <td className="align-right">
                                                    <div className="form-check form-switch">
                                                        <input className="form-check-input float-right" type="checkbox" role="switch" checked={ci.status == 1 ? true:false} onChange={(e) => this.updateOperator(e, "status", ci)}/>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default ServiceProviderRouting;