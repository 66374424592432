import React from 'react';
import TableModel from './TableModel';
import PaginationComponent from './PaginationComponent';
import PaginationItemModel from './PaginationItemModel';
import TableRowModel from './TableRowModel';
import { Dropdown } from 'react-bootstrap';
import DropDownData from './DropDownData';
import TableCell from './TableCell';

interface PaginationTableComponentProps {
    model: TableModel;
    onDropDownClicked: (e: React.MouseEvent<HTMLElement, MouseEvent>, dropDownData: DropDownData) => void;
    onButtonClicked: (e: React.MouseEvent<HTMLElement, MouseEvent>, cell: TableCell) => void;
};
interface PaginationTableComponentState {
    time: Date;
};

class PaginationTableComponent extends React.Component<PaginationTableComponentProps, PaginationTableComponentState> {

    tableModel: TableModel = new TableModel();

    state: PaginationTableComponentState = {
        time: new Date(),
    };

    constructor(params: PaginationTableComponentProps) {
        super(params);
        this.tableModel = params.model;
    }

    updateState() {
        this.setState({
            time: new Date()
        });
    }

    async onPageClicked(selectedPage: PaginationItemModel) {
        this.tableModel.rows_display = [];
        for (var i = 0; i < selectedPage.records.length; i++) {
            var record_index = selectedPage.records[i];
            this.tableModel.rows_display.push(this.tableModel.rows[record_index]);
        }
        this.updateState();
    }

    getDropDownContent(cell: TableCell) {
        const contentElements: JSX.Element[] = [];
        {
            if(cell.dropdown_data != null) {
                for (let dropdownData of cell.dropdown_data) {
                    contentElements.push(
                        <Dropdown.Item as="button" onClick={(e) => this.props.onDropDownClicked(e, dropdownData)}>{dropdownData.option}</Dropdown.Item>
                    );
                }
            }
        }
        return contentElements;
    }

    getRowContent(row: TableRowModel) {
        const tdElements: JSX.Element[] = [];
        {
            if(row != null && row.cells != null) {
                for (let cell of row.cells) {
                    if(cell != null) {
                        if(cell.data_type == 2) {
                            tdElements.push(
                                <td className="align-middle">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="link float-end" id="dropdown-basic">
                                            <i className="fas fa-ellipsis-v"></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {this.getDropDownContent(cell)}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            );
                        }else if(cell.data_type == 1) {
                            tdElements.push(
                            <td className="align-middle">
                                <h5 className=" mb-1">{cell.value}</h5>
                            </td>
                            );
                        }else if(cell.data_type == 4) {
                            tdElements.push(
                            <td className="align-middle">
                                <button type="button" className="btn btn-link mb-2" onClick={(e) => this.props.onButtonClicked(e, cell)}>{cell.value}</button>
                            </td>
                            );
                        }else {
                            tdElements.push(<td className="align-middle">{cell.value}</td>);
                        }
                    }
                }
            }
        }
        return tdElements;
    }

    render() {

        return (
            
            <div className="">
                <div className="table-responsive">
                    <table className="table text-nowrap">
                        <thead className="table-light">
                            <tr>
                                {this.tableModel.headers.cells.map((columnHeader, r) =>
                                    <th>{columnHeader.value}</th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {this.tableModel.rows_display.map((row, r) =>
                                <tr>
                                    {this.getRowContent(row)}
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <PaginationComponent model={this.tableModel.paginationModel} onPageClicked={(selectedPage) => this.onPageClicked(selectedPage)}></PaginationComponent>
            </div>
        );
    }
}

export default PaginationTableComponent;