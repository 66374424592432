import React from 'react';
import DataStore from '../data/DataStore';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonProps from '../model/CommonProps';
import CommonState from '../model/CommonState';
import RetailerModel from '../model/ClientModel';
import DisplayUtil from './DisplayUtil';
import URLUtil from '../data/URLUtil';

class ClientView extends React.Component<CommonProps, CommonState> {

    header: string = "Client Profile";
    retailerModel: RetailerModel;
    
    state: CommonState = {
        time: new Date(),
    };

    constructor(params: CommonProps) {
        super(params);
        this.retailerModel = new RetailerModel();

        this.header = "Client Profile";
        this.retailerModel.channelId = URLUtil.getPathParamNumber(2);
        this.loadData();
    }

    updateState() {
        this.setState({
            time: new Date()
        });
    }

    async loadData() {
        {
            let sa = new ServerAdaptor();
            let cr = await sa.get_client(this.retailerModel.channelId);
            this.retailerModel = cr.data as RetailerModel;
        }

        this.updateState();
    }

    updateInput(e: React.ChangeEvent<HTMLInputElement>, typeHint: string) {
        if (typeHint === "status") {
            var el = e.target as HTMLInputElement;
            var isChecked = el.checked;
            if (isChecked) {
                this.retailerModel.status = 1;
            } else {
                this.retailerModel.status = 0;
            }
            this.updateStatus();
        }
        this.updateState();
    }

    async updateStatus() {

        let sa = new ServerAdaptor();
        let cr = await sa.update_client_status(this.retailerModel);
        if (cr.status === 200) {
            console.log("response : " + cr.data);
            DisplayUtil.showInfoModel("Information", "Success", "", "/clients");
        } else {
            if (cr.status === 501) {
                DisplayUtil.showInfoModel("Information", "Error", JSON.stringify(cr.error), "");
            } else if (cr.status === 502) {
                DisplayUtil.showInfoModel("Information", "Error", cr.error, "");
            } else {
                console.log("response : FAILED");
            }
        }
    }

    async editAction(e: React.MouseEvent<HTMLElement, MouseEvent>) {
        e.preventDefault();
        DataStore.setSelectedRetailerId(this.retailerModel.channelId);
        console.log("edit");
        window.location.href = "/edit-client";
    }

    render() {

        var blockOption = false;
        if (this.retailerModel.status == 1) {
            blockOption = true;
        }

        return (
            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">Client Profile</h3>
                        </div>
                    </div>
                </div>

                <div className="row mb-8">
                    <div className="col-xl-9 col-lg-8 col-md-12 col-12">
                        <div className="card">
                            <div className="card-header bg-white py-3">
                                <div className="d-md-flex justify-content-between align-items-center">
                                    <div className="mb-3 mb-lg-0 text-sm-start">
                                        <h4 className="mb-0">Profile Details</h4>
                                    </div>
                                    <div className="text-md-start">
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div>
                                    
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">Name</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.retailerModel.clientName}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">Email</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.retailerModel.email}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">Mobile Number</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.retailerModel.mobile}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">Contact Person</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.retailerModel.contactPerson}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">Country</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.retailerModel.countryName}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">Currency</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.retailerModel.currencyCode}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">Address</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.retailerModel.address}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">Markup</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.retailerModel.markupValue}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                

            </div>

        );
    }
}

export default ClientView;