import React from 'react';
import DataStore from '../data/DataStore';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonState from '../model/CommonState';
import DisplayUtil from './DisplayUtil';
import VoucherPinModel from '../model/VoucherPinModel';
import moment from 'moment';
import DatePicker from "react-datepicker";
import CommonProps from '../model/CommonProps';

class VoucherBulkUploadView extends React.Component<CommonProps, CommonState> {

    header: string = "Bulk Vouchers";
    selectedProductId = 0;
    selectedFile!: File;

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: CommonProps) {
        super(params);
        this.selectedProductId = DataStore.getSelectedProductId();
    }

    componentDidMount() {
        this.loadData();
    }

    updateState() {
        this.setState({
            time: new Date()
        });
    }

    async loadData() {
        this.updateState();
    }

    updateInput(e: React.ChangeEvent<HTMLInputElement>, typeHint: string) {
        if (typeHint === "upload") {
            const fileList = e.target.files;
            if(fileList != null) {
                let file = fileList[0];
                this.selectedFile = file;
            }
        }
        this.updateState();
    }

    async saveAction(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();

        const formData = new FormData();
        formData.append("file", this.selectedFile, this.selectedFile.name);
        formData.append("productId", this.selectedProductId+"");

        let sa = new ServerAdaptor();
        let cr = await sa.voucherBulkUpload(formData);
        if(cr.status === 200) {
            console.log("response : "+cr.data);
            DisplayUtil.showInfoModel("Information", "Success", "", "/product-vouchers");
        }else {
            if(cr.status === 501) {
                DisplayUtil.showInfoModel("Information", "Error", JSON.stringify(cr.error), "");
            }else if(cr.status === 502) {
                DisplayUtil.showInfoModel("Information", "Error", cr.error, "");
            }else {
                console.log("response : FAILED");
            }
        }
    }

    render() {

        return (
            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">{this.header}</h3>
                        </div>
                    </div>
                </div>

                <div className="row mb-8">
                    <div className="col-md-6 col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-6">
                                    <h4 className="mb-1">Vouchers</h4>
                                </div>
                                <form>
                                    <div className="mb-8 row">
                                        <div className="col-12">
                                            <input type="file" className="form-control" onChange={(e) => this.updateInput(e, "upload")}/>
                                            <div className="invalid-feedback"></div>
                                        </div>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-12">
                                            <button type="submit" className="btn btn-primary" onClick={(e) => this.saveAction(e)}>Upload</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default VoucherBulkUploadView;