import React from 'react';
import DataStore from '../data/DataStore';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonProps from '../model/CommonProps';
import CommonState from '../model/CommonState';
import "react-datepicker/dist/react-datepicker.css";
import ReportTransectionRecord from '../model/ReportTransectionRecord';
import FormUtil from '../util/FormUtil';
import ErrorResponse from '../util/ErrorResponse';
import DisplayUtil from './DisplayUtil';

class TransectionEnquiry extends React.Component<CommonProps, CommonState> {

    reportTransectionRecord: ReportTransectionRecord;
    fromDate: Date = new Date();
    toDate: Date = new Date();
    flagShow: boolean = false;

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: CommonProps) {
        super(params);
        let channelId = DataStore.getSelectedRetailerId();
        this.reportTransectionRecord = new ReportTransectionRecord();
        this.reportTransectionRecord.channelId = channelId;
        this.reportTransectionRecord.clientTid = "";
        this.loadData();
    }

    updateState() {
        this.setState({
            time: new Date()
        });
    }

    async loadData() {
        //this.loadSearchData();
    }

    async searchAction(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        this.loadSearchData();
    }

    async loadSearchData() {

        let sa = new ServerAdaptor();
        let cr = await sa.get_transection_status(this.reportTransectionRecord);
        if (cr.status === 200) {
            this.reportTransectionRecord = cr.data as ReportTransectionRecord;
            this.flagShow = true;
        } else {
            console.log("response : FAILED");
            DisplayUtil.showInfoModel("Information", "Error", cr.error, "");
        }
        this.updateState();
    }

    updateInput(e: React.ChangeEvent<HTMLInputElement>, typeHint: string) {
        if (typeHint === "clientTid") {
            this.reportTransectionRecord.clientTid = e.target.value;
        }
        this.updateState();
    }

    render() {

        return (
            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">Transaction Details</h3>
                        </div>
                    </div>
                </div>

                <div className="row mb-6">
                    <div className="col-xl-9 col-lg-8 col-md-12 col-12">
                        <div className="card h-100">

                            <div className="card-body">
                                <form className="row g-3 needs-validation">
                                    <div className="col-md-12">
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" placeholder="Search for..." aria-label="" aria-describedby="" value={this.reportTransectionRecord.clientTid} onChange={(e) => this.updateInput(e, "clientTid")}/>
                                            <div className="input-group-append">
                                                <button className="btn btn-primary" type="button" onClick={(e) => this.searchAction(e)}>Search</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row mb-8" style={{display: this.flagShow ? 'block' : 'none' }}>
                    <div className="col-xl-9 col-lg-8 col-md-12 col-12">
                        <div className="card">
                            <div className="card-header bg-white py-3">
                                <div className="d-md-flex justify-content-between align-items-center">
                                    <div className="mb-3 mb-lg-0 text-sm-start">
                                        <h4 className="mb-0">Transaction Information</h4>
                                    </div>
                                    <div className="text-md-start">

                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">Client TID</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.reportTransectionRecord.clientTid}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">Transaction ID</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.reportTransectionRecord.transactionId}</p>
                                        </div>
                                    </div>
                                    
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">Service</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.reportTransectionRecord.operatorName}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">Customer Mobile No.</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.reportTransectionRecord.msisdn}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">TXN Amount (Dest Currency)</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.reportTransectionRecord.productPrice}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">TXN Amount (Source Currency)</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.reportTransectionRecord.channelPrice}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">Commission</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.reportTransectionRecord.commissionValue}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">Balance Before</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.reportTransectionRecord.channelBeforeAmount}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">Balance After</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.reportTransectionRecord.channelAfterAmount}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">Date & Time</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.reportTransectionRecord.txnDateTime}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">Status</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.reportTransectionRecord.statusDesc}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4 col-12"><h6 className="fs-5 ls-2">Status Description</h6></div>
                                        <div className="col-sm-8">
                                            <p className="mb-0">{this.reportTransectionRecord.remarks}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default TransectionEnquiry;