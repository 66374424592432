class ClientOperatorModel {

    channelOperatorId: number = 0;
    channelId: number = 0;
    channelCode: string = "";
    operatorId: number = 0;
    operatorName: string = "";
    operatorCountry: string = "";
    countryId: number = 0;
    countryName: string = "";
    denominationFlag: number = 0;
    denominationMax: string = "0";
    denominationMin: string = "0";
    commissionType: number = 0;
    commissionValue: string = "0";
    status: number = 0;
    remarks: string = "";
    
}

export default ClientOperatorModel;