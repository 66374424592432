class CommonResponse {

	status: number = 0;
	errorCode: string = "";
	error: any;
	data: any;

	setSuccessResponse(data: any) {
		this.status = 200;
		this.data = data;
	}

	setErrorResponse(status: number, errorCode: string, error: any) {
		this.status = status;
		this.errorCode = errorCode;
		this.error = error;
	}

}
export default CommonResponse;