import React from 'react';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonProps from '../model/CommonProps';
import CommonState from '../model/CommonState';
import Dropdown from 'react-bootstrap/Dropdown';
import NotificationsModel from '../model/NotificationsModel';
import NotificationRecord from '../model/NotificationRecord';
import DisplayUtil from './DisplayUtil';

class Notifications extends React.Component<CommonProps, CommonState> {

    notificationsModel: NotificationsModel;

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: CommonProps) {
        super(params);
        this.notificationsModel = new NotificationsModel();
        this.loadData();
    }

    async loadData() {
        let model = { "command": "all" };
        let sa = new ServerAdaptor();
        let cr = await sa.get_notifications(model);
        let notificationsModel = cr.data as NotificationsModel;
        let notificationRecords = notificationsModel.notificationRecords;
        this.notificationsModel.notificationRecords = notificationRecords;
        this.setState({
            time: new Date()
        });
    }

    async approveAction(e: React.MouseEvent<HTMLElement, MouseEvent>, notificationRecord: NotificationRecord) {
        e.preventDefault();
        let sa = new ServerAdaptor();
        let cr = await sa.approveTask(notificationRecord.approvalTaskId);
        if (cr.status === 200) {
            console.log("response : " + cr.data);
            DisplayUtil.showInfoModel("Information", "Success", "", "/notifications");
        } else {
            console.log("response : FAILED");
            DisplayUtil.showInfoModel("Information", "Error", cr.error, "");
        }
    }

    async rejectAction(e: React.MouseEvent<HTMLElement, MouseEvent>, notificationRecord: NotificationRecord) {
        e.preventDefault();
        let sa = new ServerAdaptor();
        let cr = await sa.rejectTask(notificationRecord.approvalTaskId);
        if (cr.status === 200) {
            console.log("response : " + cr.data);
            DisplayUtil.showInfoModel("Information", "Success", "", "/notifications");
        } else {
            console.log("response : FAILED");
            DisplayUtil.showInfoModel("Information", "Error", cr.error, "");
        }
    }

    render() {

        return (
            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">Notifications</h3>
                        </div>
                    </div>
                </div>

                <div className="row mb-6">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="card h-100">
                            <div className="card-header bg-white py-3">
                                <div className="d-md-flex justify-content-between align-items-center">
                                    <div className="mb-3 mb-lg-0 text-center text-sm-start">
                                        <h4 className="mb-0">Notifications</h4>
                                    </div>
                                    <div className="text-center text-md-start">
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive pb-15">
                                <table className="table text-nowrap">
                                    <thead className="table-light">
                                        <tr>
                                            <th>Sno</th>
                                            <th>Notification</th>
                                            <th>Status</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.notificationsModel.notificationRecords.map((re, index) =>
                                            <tr>
                                                <td className="align-middle">
                                                    <h5 className=" mb-1">{(index+1)}.</h5>
                                                </td>
                                                <td className="align-middle">{re.message}</td>
                                                <td className="align-middle">{re.processedStatus}</td>
                                                <td className="align-middle">
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="link float-end" id="dropdown-basic">
                                                            <i className="fas fa-ellipsis-v"></i>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item as="button" onClick={(e) => this.approveAction(e, re)}>Approve</Dropdown.Item>
                                                            <Dropdown.Item as="button" onClick={(e) => this.rejectAction(e, re)}>Reject</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Notifications;