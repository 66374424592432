import React from 'react';
import DataStore from '../data/DataStore';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonProps from '../model/CommonProps';
import CommonState from '../model/CommonState';
import ClientModel from '../model/ClientModel';
import Dropdown from 'react-bootstrap/Dropdown';
import ListData from '../model/ListData';

class ClientsList extends React.Component<CommonProps, CommonState> {

    listData: ListData;

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: CommonProps) {
        super(params);
        this.listData = new ListData();
        this.loadData();
    }

    async loadData() {
        let model = { "command": "all" };
        let sa = new ServerAdaptor();
        let cr = await sa.get_clients(model);
        this.listData = cr.data as ListData;
        this.setState({
            time: new Date()
        });
    }

    async viewAction(e: React.MouseEvent<HTMLElement, MouseEvent>, retailer: ClientModel) {
        e.preventDefault();
        //DataStore.setSelectedRetailerId(retailer.channelId);
        console.log("edit");
        window.location.href = "/client-view/"+retailer.channelId;
    }

    async editAction(e: React.MouseEvent<HTMLElement, MouseEvent>, retailer: ClientModel) {
        e.preventDefault();
        //DataStore.setSelectedRetailerId(retailer.channelId);
        console.log("edit");
        window.location.href = "/edit-client/"+retailer.channelId;
    }

    // async salesManagerAction(e: React.MouseEvent<HTMLElement, MouseEvent>, retailer: ClientModel) {
    //     e.preventDefault();
    //     DataStore.setRefRetailerId(retailer.channelId);
    //     console.log("edit");
    //     window.location.href = "/new-sales-manager";
    // }

    // async dealerAction(e: React.MouseEvent<HTMLElement, MouseEvent>, retailer: ClientModel) {
    //     e.preventDefault();
    //     DataStore.setRefRetailerId(retailer.channelId);
    //     console.log("edit");
    //     window.location.href = "/new-dealer";
    // }

    async subRetailerAction(e: React.MouseEvent<HTMLElement, MouseEvent>, retailer: ClientModel) {
        e.preventDefault();
        DataStore.setRefRetailerId(retailer.channelId);
        console.log("edit");
        window.location.href = "/new-sub-retailer";
    }

    async dashboardAction(e: React.MouseEvent<HTMLElement, MouseEvent>, retailer: ClientModel) {
        e.preventDefault();
        window.location.href = "/dashboard/"+retailer.channelId;
    }

    async walletAction(e: React.MouseEvent<HTMLElement, MouseEvent>, retailer: ClientModel) {
        e.preventDefault();
        window.location.href = "/client-wallet/"+retailer.channelId;
    }

    async clientServicesAction(e: React.MouseEvent<HTMLElement, MouseEvent>, retailer: ClientModel) {
        e.preventDefault();
        //DataStore.setSelectedRetailerId(retailer.channelId);
        window.location.href = "/client-operators/"+retailer.channelId;
    }

    async walletTransactionsAction(e: React.MouseEvent<HTMLElement, MouseEvent>, retailer: ClientModel) {
        e.preventDefault();
        //DataStore.setSelectedRetailerId(retailer.channelId);
        // if(retailer.chReferenceId > 0) {
        //     DisplayUtil.showInfoModel("Information", "Not Implemented", "", "/clients");
        // }else {
        //     window.location.href = "/retailer-wallet-Transactions";
        // }
        window.location.href = "/retailer-wallet-transactions/"+retailer.channelId;
    }

    render() {

        return (
            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">Clients</h3>
                        </div>
                    </div>
                </div>

                <div className="row mb-6">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="card h-100">
                            <div className="card-header bg-white py-3">
                                <div className="d-md-flex justify-content-between align-items-center">
                                    <div className="mb-3 mb-lg-0 text-center text-sm-start">
                                        <h4 className="mb-0"></h4>
                                    </div>
                                    <div className="text-center text-md-start">
                                        <a type="button" className="btn btn-sm btn-primary" href="/new-client">New</a>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive pb-15">
                                <table className="table text-nowrap">
                                    <thead className="table-light">
                                        <tr>
                                            <th>Sno</th>
                                            <th>Client Code</th>
                                            <th>Client Name</th>
                                            <th>Country</th>
                                            <th>Status</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.listData.clients.map((re, index) =>
                                            <tr>
                                                <td className="align-middle">
                                                    <h5 className=" mb-1">{(index+1)}.</h5>
                                                </td>
                                                <td className="align-middle"><h5 className=" mb-1">{re.channelCode}</h5></td>
                                                <td className="align-middle">{re.clientName}</td>
                                                <td className="align-middle">{re.countryName}</td>
                                                <td className="align-middle">{re.statusDesc}</td>
                                                <td className="align-middle">
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="link float-end" id="dropdown-basic">
                                                            <i className="fas fa-ellipsis-v"></i>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item as="button" onClick={(e) => this.viewAction(e, re)}>View Profile</Dropdown.Item>
                                                            <Dropdown.Item as="button" onClick={(e) => this.editAction(e, re)}>Edit Profile</Dropdown.Item>
                                                            <Dropdown.Item as="button" onClick={(e) => this.dashboardAction(e, re)}>Dashboard</Dropdown.Item>
                                                            <Dropdown.Item as="button" onClick={(e) => this.walletAction(e, re)}>Wallet</Dropdown.Item>
                                                            <Dropdown.Item as="button" onClick={(e) => this.clientServicesAction(e, re)}>Client-Services</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ClientsList;