import React, { useState } from 'react';
import Select from 'react-select';
import SelectSearchModel from './SelectSearchModel';

interface SelectSearchComponentProps {
  model: SelectSearchModel;
  onSelectClicked: (e: any) => void;
};
interface SelectComponentState {
  time: Date;
};

class SelectSearchComponent extends React.Component<SelectSearchComponentProps, SelectComponentState> {

  model: SelectSearchModel = new SelectSearchModel();

  state: SelectComponentState = {
    time: new Date(),
  };

  constructor(params: SelectSearchComponentProps) {
    super(params);
    this.model = params.model;
  }

  render() {
    return (
      <Select
        value={this.model.selectedOption}
        onChange={this.props.onSelectClicked}
        options={this.model.options}
        classNamePrefix="react-select"
      />
    );
  }
};

export default SelectSearchComponent;