import React from 'react';
import DataStore from '../data/DataStore';
import CommonProps from '../model/CommonProps';
import CommonState from '../model/CommonState';
import DisplayUtil from './DisplayUtil';

class Header extends React.Component<CommonProps, CommonState> {

  async logoutAction(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.preventDefault();
    console.log("logoutAction");
    DataStore.setToken_id("");
    window.location.href = "/";
  }

  async closeInfoModelAction(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    console.log("closeInfoModelAction");
    DisplayUtil.hideInfoModel();
  }

  render() {

    return (
      <div className="header @@classList">
        <nav className="navbar-classic navbar navbar-expand-lg">
          <a id="nav-toggle" href="#"><i className="fas fa-bars"></i></a>
          <div className="ms-lg-3 d-none d-md-none d-lg-block">

            
          </div>

          <ul className="navbar-nav navbar-right-wrap ms-auto d-flex nav-top-wrap">


            <li className="dropdown ms-2">

              <a className="btn btn-light btn-icon rounded-circle indicator
            indicator-primary text-muted" role="button"
                id="dropdownUser" data-bs-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                <i className="fas fa-user-circle" style={{fontSize:'1.5rem'}}></i>
              </a>
              <div className="dropdown-menu dropdown-menu-end"
                aria-labelledby="dropdownUser">
                <div className="px-4 pb-0 pt-2">
                  <div className="lh-1 ">
                    <h5 className="mb-1"> {DataStore.getUserName()}</h5>
                    <a href="#" className="text-inherit fs-6">View my profile</a>
                  </div>
                  <div className=" dropdown-divider mt-3 mb-2"></div>
                </div>

                <ul className="list-unstyled">
                  <li>
                    <a className="dropdown-item" href="#" onClick={(e) => this.logoutAction(e)}>
                    <i className="fas fa-home me-2"></i>Edit Profile
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#" onClick={(e) => this.logoutAction(e)}>
                    <i className="fas fa-home me-2"></i>Sign Out
                    </a>
                  </li>
                </ul>

              </div>
            </li>
          </ul>
        </nav>
        <div className="modal fade" id="infoModel" data-tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-path="">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="infoModel-header">Modal title</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body pb-1" id="infoModel-body">
                
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-sm btn-primary px-5" onClick={(e) => this.closeInfoModelAction(e)}>Ok</button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show" id="infoModelBackdrop" style={{"display":"none"}}></div>
      </div>
    );
  }
}

export default Header;
