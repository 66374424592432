import React from 'react';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonState from '../model/CommonState';
import CountryModel from '../model/CountryModel';
import CurrencyModel from '../model/CurrencyModel';
import ListData from '../model/ListData';
import DisplayUtil from './DisplayUtil';
import FormUtil from '../util/FormUtil';
import ErrorResponse from '../util/ErrorResponse';
import SelectSearchModel from '../component/SelectSearchModel';
import OptionType from '../component/OptionType';
import SelectSearchComponent from '../component/SelectSearchComponent';
import URLUtil from '../data/URLUtil';
import ClientModel from '../model/ClientModel';

interface ClientFormProps {
    flag_new: boolean;
};

class ClientFormView extends React.Component<ClientFormProps, CommonState> {

    formId: string = "form1";
    flag_new: boolean = true;
    header: string = "New Client";
    clientModel: ClientModel;
    countries: CountryModel[] = [];
    countrySelectModel: SelectSearchModel;
    currencies: CurrencyModel[] = [];
    currencySelectModel: SelectSearchModel;

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: ClientFormProps) {
        super(params);
        this.clientModel = new ClientModel();
        this.countrySelectModel = new SelectSearchModel();
        this.currencySelectModel = new SelectSearchModel();
        this.flag_new = params.flag_new;
        if(this.flag_new) {
            this.header = "New Client";
            this.loadData();
        }else {
            this.header = "Edit Client";
            this.clientModel.channelId = URLUtil.getPathParamNumber(2);
            this.loadData();
        }
    }

    updateState() {
        this.setState({
            time: new Date()
        });
    }
    
    async loadData() {

        {
            let model = { "command": "all" };
            let sa = new ServerAdaptor();
            let cr = await sa.get_countries(model);
            let listData = cr.data as ListData;
            this.countries = listData.countries;
            this.countrySelectModel.clear();
            this.countrySelectModel.add("0", "Select Country");
            for(var obj of this.countries) {
                this.countrySelectModel.add(obj.countryId+"", obj.countryName);
            }
        }

        {
            let model = { "command": "all" };
            let sa = new ServerAdaptor();
            let cr = await sa.get_currencies(model);
            let listData = cr.data as ListData;
            this.currencies = listData.currencies;
            this.currencySelectModel.clear();
            this.currencySelectModel.add("0", "Select Currency");
            for(var obj1 of this.currencies) {
                this.currencySelectModel.add(obj1.currencyId+"", obj1.currencyName);
            }
        }
        if (this.flag_new) {
            
        }else {
            let sa = new ServerAdaptor();
            let cr = await sa.get_client(this.clientModel.channelId);
            this.clientModel = cr.data as ClientModel;
            
            this.countrySelectModel.setSelected(this.clientModel.countryId+"");
            this.currencySelectModel.setSelected(this.clientModel.currencyId+"");
        }

        this.updateState();
    }

    updateInput(e: React.ChangeEvent<HTMLInputElement>, typeHint: string) {
        if (typeHint === "channelCode") {
            this.clientModel.channelCode = e.target.value;
        }
        if (typeHint === "clientName") {
            this.clientModel.clientName = e.target.value;
        }
        if (typeHint === "contactPerson") {
            this.clientModel.contactPerson = e.target.value;
        }
        if (typeHint === "mobile") {
            this.clientModel.mobile = e.target.value;
        }
        if (typeHint === "email") {
            this.clientModel.email = e.target.value;
        }
        if (typeHint === "markupValue") {
            this.clientModel.markupValue = e.target.value;
        }
        FormUtil.updateFormState(this.formId);
        this.updateState();
    }

    updateTextAreaInput(e: React.ChangeEvent<HTMLTextAreaElement>, typeHint: string) {
        if (typeHint === "address") {
            this.clientModel.address = e.target.value;
        }
        this.updateState();
    }

    onCountrySelectClicked(e: OptionType) {
        let countryId = parseInt(e.value);
        this.clientModel.countryId = countryId;
    };

    onCurrencySelectClicked(e: OptionType) {
        let currencyId = parseInt(e.value);
        this.clientModel.currencyId = currencyId;
    };

    async saveAction(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
    
        if(this.flag_new) {
            let sa = new ServerAdaptor();
            let cr = await sa.new_client(this.clientModel);
            if(cr.status === 200) {
                console.log("response : "+cr.data);
                DisplayUtil.showInfoModel("Info", "Success", "", "/clients");
            }else {
                if(cr.status === 501) {
                    FormUtil.showFormErrors(cr.error as ErrorResponse, this.formId);
                }else if(cr.status === 502) {
                    FormUtil.showFormErrors(cr.error as ErrorResponse, this.formId);
                }else {
                    DisplayUtil.showInfoModel("Information", "Error", cr.error, "");
                }
            }
        }else {
            let sa = new ServerAdaptor();
            let cr = await sa.update_client(this.clientModel);
            if(cr.status === 200) {
                console.log("response : "+cr.data);
                DisplayUtil.showInfoModel("Info", "Success", "", "/clients");
            }else {
                if(cr.status === 501) {
                    FormUtil.showFormErrors(cr.error as ErrorResponse, this.formId);
                }else if(cr.status === 502) {
                    FormUtil.showFormErrors(cr.error as ErrorResponse, this.formId);
                }else {
                    DisplayUtil.showInfoModel("Information", "Error", cr.error, "");
                }
            }
        }
    }

    render() {

        return (
            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">{this.header}</h3>
                        </div>
                    </div>
                </div>
                <div className="row mb-8">
                    <div className="col-xl-9 col-lg-8 col-md-12 col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-6">
                                    <h4 className="mb-1">Client Details</h4>
                                </div>
                                <form id={this.formId}>
                                    
                                    
                                    <div className="mb-3 row">
                                        <label data-for="service_provider_name" className="col-sm-4 col-form-label form-label">Name</label>
                                        <div className="col-md-8 col-12">
                                            <input required type="text" className="form-control" placeholder="Name" value={this.clientModel.clientName} onChange={(e) => this.updateInput(e, "clientName")}/>
                                            <div id="serviceProviderName_feedback" className="invalid-feedback"></div>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="location"
                                            className="col-sm-4 col-form-label form-label">Country</label>
                                        <div className="col-md-8 col-12">
                                            <SelectSearchComponent model={this.countrySelectModel} onSelectClicked={(e) => this.onCountrySelectClicked(e)}></SelectSearchComponent>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="location"
                                            className="col-sm-4 col-form-label form-label">Currency</label>
                                        <div className="col-md-8 col-12">
                                            <SelectSearchComponent model={this.currencySelectModel} onSelectClicked={(e) => this.onCurrencySelectClicked(e)}></SelectSearchComponent>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="service_provider_name" className="col-sm-4 col-form-label form-label">Contact Person</label>
                                        <div className="col-md-8 col-12">
                                            <input required type="text" className="form-control" placeholder="Contact Person" value={this.clientModel.contactPerson} onChange={(e) => this.updateInput(e, "contactPerson")}/>
                                            <div id="contactPerson_feedback" className="invalid-feedback"></div>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="service_provider_name" className="col-sm-4 col-form-label form-label">Mobile</label>
                                        <div className="col-md-8 col-12">
                                            <input required type="text" className="form-control" placeholder="Mobile" value={this.clientModel.mobile} onChange={(e) => this.updateInput(e, "mobile")}/>
                                            <div id="mobile_feedback" className="invalid-feedback"></div>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="service_provider_name" className="col-sm-4 col-form-label form-label">Email</label>
                                        <div className="col-md-8 col-12">
                                            <input required type="text" className="form-control" placeholder="Email" value={this.clientModel.email} onChange={(e) => this.updateInput(e, "email")}/>
                                            <div id="email_feedback" className="invalid-feedback"></div>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <label data-for="remarks" className="col-sm-4 col-form-label form-label">Address</label>
                                        <div className="col-md-8 col-12">
                                            <textarea className="form-control" data-rows="5" value={this.clientModel.address}  onChange={(e) => this.updateTextAreaInput(e, "address")}></textarea>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <label data-for="service_provider_name" className="col-sm-4 col-form-label form-label">Markup (In Percentage)</label>
                                        <div className="col-md-8 col-12">
                                            <input required type="text" className="form-control" placeholder="Markup" value={this.clientModel.markupValue} onChange={(e) => this.updateInput(e, "markupValue")}/>
                                            <div id="email_feedback" className="invalid-feedback"></div>
                                        </div>
                                    </div>

                                    <div className="row align-items-center">
                                        <div className="offset-md-4 col-md-8 mt-4">
                                            <button type="submit" className="btn btn-primary" onClick={(e) => this.saveAction(e)}>Save Changes</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        );
    }
}

export default ClientFormView;