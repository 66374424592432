import React from 'react';
import DataStore from '../data/DataStore';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonProps from '../model/CommonProps';
import CommonState from '../model/CommonState';
import Dropdown from 'react-bootstrap/Dropdown';
import ServiceProviderOperatorModel from '../model/ServiceProviderOperatorModel';
import URLUtil from '../data/URLUtil';
import ServiceProviderModel from '../model/ServiceProviderModel';
import ListData from '../model/ListData';

class ServiceProviderOperators extends React.Component<CommonProps, CommonState> {

    header: string = "Service Provider Operators";
    serviceProviderModel: ServiceProviderModel;
    serviceProviderOperators: ServiceProviderOperatorModel[] = [];

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: CommonProps) {
        super(params);
        this.serviceProviderModel = new ServiceProviderModel();
        this.serviceProviderModel.serviceProviderId = URLUtil.getPathParamNumber(2);
        this.serviceProviderModel.serviceProviderName = DataStore.getSelectedServiceProviderName();
        this.header = this.serviceProviderModel.serviceProviderName+" - Operators";
        this.loadData();
    }

    updateState() {
        this.setState({
            time: new Date()
        });
    }

    async loadData() {
        let sa = new ServerAdaptor();
        let cr = await sa.get_service_provider_operator_mappings(this.serviceProviderModel.serviceProviderId);
        let listData = cr.data as ListData;
        this.serviceProviderOperators = listData.serviceProviderOperators;
        this.updateState();
    }

    async newAction(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        window.location.href = "/new-service-provider-operator-mapping/"+this.serviceProviderModel.serviceProviderId;
    }

    async viewAction(e: React.MouseEvent<HTMLElement, MouseEvent>, obj: ServiceProviderOperatorModel) {
        e.preventDefault();
        //DataStore.setSelectedMappingId(obj);
        window.location.href = "/service-provider-operator-mapping-view/"+obj.serviceProviderOperatorId;
    }

    async editAction(e: React.MouseEvent<HTMLElement, MouseEvent>, obj: ServiceProviderOperatorModel) {
        e.preventDefault();
        //DataStore.setSelectedMappingId(selectedSno);
        window.location.href = "/edit-service-provider-operator-mapping/"+obj.serviceProviderOperatorId;
    }

    render() {

        return (
            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">{this.header}</h3>
                        </div>
                    </div>
                </div>

                <div className="row mb-6">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="card h-100">
                            <div className="card-header bg-white py-3">
                                <div className="d-md-flex justify-content-between align-items-center">
                                    <div className="mb-3 mb-lg-0 text-center text-sm-start">
                                        <h4 className="mb-0">Service Provider Operators</h4>
                                    </div>
                                    <div className="text-center text-md-start">
                                        <button type="button" className="btn btn-sm btn-primary" onClick={(e) => this.newAction(e, )}>New</button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive pb-15">
                                <table className="table text-nowrap">
                                    <thead className="table-light">
                                        <tr>
                                            <th>Sno</th>
                                            <th>Service Provider</th>
                                            <th>Operator</th>
                                            <th>Service Charge (%)</th>
                                            <th>Status</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.serviceProviderOperators.map((po, index) =>
                                            <tr>
                                                <td className="align-middle">
                                                    <h5 className=" mb-1">{(index+1)}.</h5>
                                                </td>
                                                <td className="align-middle"><h5 className=" mb-1">{po.serviceProviderName}</h5></td>
                                                <td className="align-middle">{po.operatorName}</td>
                                                <td className="align-middle">{po.serviceCharge}</td>
                                                <td className="align-middle">{po.statusDesc}</td>
                                                <td className="align-middle">
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="link float-end" id="dropdown-basic">
                                                            <i className="fas fa-ellipsis-v"></i>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item as="button" onClick={(e) => this.viewAction(e, po)}>View</Dropdown.Item>
                                                            <Dropdown.Item as="button" onClick={(e) => this.editAction(e, po)}>Edit</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default ServiceProviderOperators;