class LoginModel {
    loginId: string = "";
    password: string = "";
    newPassword: string = "";
    conformPassword: string = "";
    userName: string = "";
    userId: number = 0;
    clientId: number = 0;
    email: string = "";
    otp: string = "";
    token: string = "";
    roleId: number = 0;
    permissions: string[] = [];
    apiUser: number = 0;
}

export default LoginModel;