class ReportTransectionRecord {

    transactionId: number = 0;
	productId: number = 0;
	productName: string = "";
	operatorId: number = 0;
	operatorName: string = "";
	serviceProviderProductId: string = "";
    serviceProviderId: number = 0;
	serviceProviderName: string = "";
	channelId: number = 0;
	channelName: string = "";
	msisdn: string = "";
	productPrice: number = 0;
	productCurrencyId: number = 0;
	productCurrencyName: string = "";
	providerPrice: number = 0;
	providerCurrencyId: number = 0;
	providerCurrencyName: string = "";
	channelPrice: number = 0;
	channelCurrencyId: number = 0;
	channelCurrencyName: string = "";
	profit: number = 0;
	commissionValue: number = 0;
	validity: number = 0;
	channelWalletTxnId: number = 0;
	channelBeforeAmount: number = 0;
	channelAfterAmount: number = 0;
	spWalletTxnId: number = 0;
	spBeforeAmount: number = 0;
	spAfterAmount: number = 0;
	txnType: number = 0;
	txnTypeName: string = "";
	txnProcessType: number = 0;
	txnProcessTypeName: string = "";
	txnDateTime: string = "";
	insertedDate: string = "";
	insertedUser: number = 0;
	lastModifiedDate: string = "";
	lastModifiedUser: number = 0;
	status: number = 0;
	statusDesc: string = "";
	remarks: string = "";
	clientTid: string = "";
	deviceId: string = "";
	operation: string = "";
	spRespTid: string = "";
    
}

export default ReportTransectionRecord;