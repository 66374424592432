import React from 'react';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonProps from '../model/CommonProps';
import CommonState from '../model/CommonState';
import "react-datepicker/dist/react-datepicker.css";
import ListData from '../model/ListData';
import DataStore from '../data/DataStore';
import { Dropdown } from 'react-bootstrap';
import VoucherPinModel from '../model/VoucherPinModel';

class ProductVouchers extends React.Component<CommonProps, CommonState> {

    waitDisplay: string = "block";
    tableDisplay: string = "none";

    selectedProductId = 0;

    vouchers: VoucherPinModel[] = [];

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: CommonProps) {
        super(params);
        this.selectedProductId = DataStore.getSelectedProductId();
        this.loadData();
    }

    updateState() {
        this.setState({
            time: new Date()
        });
    }

    async showWait(show: boolean) {
        if (show) {
            this.waitDisplay = "block";
            this.tableDisplay = "none";
            this.updateState();
        } else {
            this.waitDisplay = "none";
            this.tableDisplay = "block";
        }
    }

    async loadData() {
        let model = { "productId": this.selectedProductId };
        let sa = new ServerAdaptor();
        this.showWait(true);
        let cr = await sa.get_vouchers(model);
        let listData = cr.data as ListData;
        this.vouchers = listData.vouchers;
        this.showWait(false);
        this.updateState();
    }

    async viewAction(e: React.MouseEvent<HTMLElement, MouseEvent>, voucherId: number) {
        e.preventDefault();
        DataStore.setSelectedVoucherPinId(voucherId);
        console.log("view");
        window.location.href = "/edit-voucher";
    }

    async editAction(e: React.MouseEvent<HTMLElement, MouseEvent>, voucherId: number) {
        e.preventDefault();
        DataStore.setSelectedVoucherPinId(voucherId);
        console.log("view");
        window.location.href = "/edit-voucher";
    }

    render() {

        return (

            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">Vouchers</h3>
                        </div>
                    </div>
                </div>

                <div className="row mb-6" style={{ display: this.waitDisplay }}>
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="card h-100">
                            <div className="card-body">
                                <div className="spinner-border m-5" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mb-6" style={{ display: this.tableDisplay }}>
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="card h-100">
                            <div className="card-header bg-white py-3">
                                <div className="d-md-flex justify-content-between align-items-center">
                                    <div className="mb-3 mb-lg-0 text-center text-sm-start">
                                        <h4 className="mb-0">Vouchers</h4>
                                    </div>
                                    <div className="text-center text-md-start">
                                        <a type="button" className="btn btn-sm btn-primary" href="/new-voucher">New Voucher</a>
                                        <a type="button" className="btn btn-sm btn-primary ms-1" href="/voucher-bulk-upload">Bulk Upload</a>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive pb-15">
                                <table className="table text-nowrap">
                                    <thead className="table-light">
                                        <tr>
                                            <th>Sno</th>
                                            <th>Serial No</th>
                                            <th>Expiry Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.vouchers.map((obj, index) =>
                                            <tr>
                                                <td className="align-middle">
                                                    <h5 className=" mb-1">{(index+1)}.</h5>
                                                </td>
                                                <td className="align-middle"><h5 className=" mb-1">{obj.voucherSerialNo}</h5></td>
                                                <td className="align-middle">{obj.expiryDateTxt}</td>
                                                <td className="align-middle">
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="link float-end" id="dropdown-basic">
                                                            <i className="fas fa-ellipsis-v"></i>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item as="button" onClick={(e) => this.viewAction(e, obj.voucherPinId)}>View</Dropdown.Item>
                                                            <Dropdown.Item as="button" onClick={(e) => this.editAction(e, obj.voucherPinId)}>Edit</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default ProductVouchers;