import OptionType from "./OptionType";

class SelectSearchModel {

    options!: OptionType[];
    selectedOption!: OptionType;

    add(value: string, label: string) {
        if(this.options == null) {
            this.options = [];
        }
        let option = new OptionType();
        option.label = label;
        option.value = value;
        this.options.push(option);
    }

    clear() {
        if(this.options != null) {
            this.options = [];
        }
    }

    setSelected(value: string) {
        if(this.options != null) {
            for(let option of this.options) {
                if(option.value == value) {
                    this.selectedOption = option;
                }
            }
        }
    }
    
}

export default SelectSearchModel;