import ErrorResponse from "./ErrorResponse";

class FormUtil {

	static showFormErrors(error: ErrorResponse, formId: string) {
		for (const [key, value] of Object.entries(error)) {
            console.log(key, value);
            let field = key+"_feedback";
            let value_str = value as string;
            let el = document.getElementById(field);
            if(el!=null) {
                el.innerText = value_str;
            }
        }
        let el = document.getElementById(formId);
		if(el!=null) {
			el.classList.add("form-validated");
		}
	}

	static updateFormState(formId: string) {
		let el = document.getElementById(formId);
		if(el!=null) {
			el.classList.remove("form-validated");
			let arr = el.getElementsByClassName("invalid-feedback");
			Array.prototype.forEach.call(arr, function(el_feedback) {
				el_feedback.innerText = "";
			});
		}
	}

}
export default FormUtil;
