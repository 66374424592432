import React from 'react';
import DataStore from '../data/DataStore';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonState from '../model/CommonState';
import OperatorModel from '../model/OperatorModel';
import OperatorsModel from '../model/OperatorsModel';
import ServiceProviderOperatorModel from '../model/ServiceProviderOperatorModel';
import DisplayUtil from './DisplayUtil';
import SelectSearchModel from '../component/SelectSearchModel';
import OptionType from '../component/OptionType';
import SelectSearchComponent from '../component/SelectSearchComponent';
import URLUtil from '../data/URLUtil';
import ServiceProviderModel from '../model/ServiceProviderModel';
import FormUtil from '../util/FormUtil';

interface ProviderOperatorProps {
    flag_new: boolean;
};

class ServiceProviderOperatorFormView extends React.Component<ProviderOperatorProps, CommonState> {

    formId: string = "form1";
    flag_new: boolean = true;
    header: string = "New Mapping";
    providerOperatorModel: ServiceProviderOperatorModel;
    serviceProviderModel: ServiceProviderModel;
    operators: OperatorModel[] = [];
    operatorSelectModel: SelectSearchModel;

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: ProviderOperatorProps) {
        super(params);
        this.providerOperatorModel = new ServiceProviderOperatorModel();
        this.serviceProviderModel = new ServiceProviderModel();
        this.serviceProviderModel.serviceProviderId = URLUtil.getPathParamNumber(2);
        this.operatorSelectModel = new SelectSearchModel();
        this.flag_new = params.flag_new;
    }

    componentDidMount() {
        if (this.flag_new) {
            this.flag_new = true;
            this.header = "New";
            this.providerOperatorModel.serviceProviderId = this.serviceProviderModel.serviceProviderId;
        } else {
            this.flag_new = false;
            this.header = "Edit";
            this.providerOperatorModel.serviceProviderOperatorId = this.serviceProviderModel.serviceProviderId;
            this.providerOperatorModel.serviceProviderId = DataStore.getSelectedServiceProviderId();
            this.providerOperatorModel.serviceProviderName = DataStore.getSelectedServiceProviderName();
        }
        this.loadData();
    }

    updateState() {
        this.setState({
            time: new Date()
        });
    }

    async loadData() {

        let model = { "command": "all", "orderBy": "name" };
        let sa = new ServerAdaptor();
        let cr = await sa.get_operators(model);
        let operatorsModel = cr.data as OperatorsModel;
        this.operators = operatorsModel.operators;

        this.operatorSelectModel.clear();
        this.operatorSelectModel.add("0", "Select Operator");
        for(var om of this.operators) {
            this.operatorSelectModel.add(om.operatorId+"", om.operatorName);
        }

        if (this.flag_new) {
            
            let sa = new ServerAdaptor();
            let cr = await sa.get_service_provider(this.serviceProviderModel.serviceProviderId);
            this.serviceProviderModel = cr.data as ServiceProviderModel;
            this.providerOperatorModel.serviceProviderName = this.serviceProviderModel.serviceProviderName;

        }else {

            {
                let sa = new ServerAdaptor();
                let cr = await sa.get_service_provider_operator_mapping(this.providerOperatorModel.serviceProviderOperatorId);
                this.providerOperatorModel = cr.data as ServiceProviderOperatorModel;

                this.operatorSelectModel.setSelected(this.providerOperatorModel.operatorId+"");
            }
        }
        this.updateState();
    }

    onOperatorSelectClicked(e: OptionType) {
        let operatorId = parseInt(e.value);
        this.providerOperatorModel.operatorId = operatorId;
    };

    updateTextAreaInput(e: React.ChangeEvent<HTMLTextAreaElement>, typeHint: string) {
        if (typeHint === "remarks") {
            this.providerOperatorModel.remarks = e.target.value;
        }
        this.updateState();
    }

    updateInput(e: React.ChangeEvent<HTMLInputElement>, typeHint: string) {
        if (typeHint === "serviceCharge") {
            this.providerOperatorModel.serviceCharge = e.target.value;
        }
        FormUtil.updateFormState(this.formId);
        this.updateState();
    }

    async saveAction(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        if (this.flag_new) {
            let sa = new ServerAdaptor();
            let cr = await sa.new_service_provider_operator_mapping(this.providerOperatorModel);
            if(cr.status === 200) {
                console.log("response : "+cr.data);
                DisplayUtil.showInfoModel("Information", "Success", "", "/service-provider-operators/"+this.providerOperatorModel.serviceProviderId);
            }else {
                console.log("response : FAILED");
            }
        }else {
            let sa = new ServerAdaptor();
            let cr = await sa.update_service_provider_operator_mapping(this.providerOperatorModel);
            if(cr.status === 200) {
                console.log("response : "+cr.data);
                DisplayUtil.showInfoModel("Information", "Success", "", "/service-provider-operators/"+this.providerOperatorModel.serviceProviderId);
            }else {
                console.log("response : FAILED");
            }
        }
    }

    render() {

        

        return (
            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">{this.header} Mapping</h3>
                        </div>
                    </div>
                </div>

                <div className="row mb-6">
                    <div className="col-xl-9 col-lg-8 col-md-12 col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-6">
                                    <h4 className="mb-1">Operator Mapping</h4>
                                </div>
                                <form>

                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Service Provider</label>
                                        <div className="col-md-8 col-12">
                                            <input type="text" className="form-control" placeholder="" value={this.providerOperatorModel.serviceProviderName} disabled/>
                                            <div className="invalid-feedback"></div>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <label data-for="location" className="col-sm-4 col-form-label form-label">Operator</label>
                                        <div className="col-md-8 col-12">
                                            <SelectSearchComponent model={this.operatorSelectModel} onSelectClicked={(e) => this.onOperatorSelectClicked(e)}></SelectSearchComponent>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <label data-for="service_provider_name" className="col-sm-4 col-form-label form-label">Service Charge (In Percentage)</label>
                                        <div className="col-md-8 col-12">
                                            <input required type="text" className="form-control" placeholder="Service Charge" value={this.providerOperatorModel.serviceCharge} onChange={(e) => this.updateInput(e, "serviceCharge")}/>
                                            <div id="email_feedback" className="invalid-feedback"></div>
                                        </div>
                                    </div>


                                    <div className="mb-3 row">
                                        <label data-for="addressLine" className="col-sm-4 col-form-label form-label">Remarks</label>
                                        <div className="col-md-8 col-12">
                                            <textarea className="form-control" data-rows="5" value={this.providerOperatorModel.remarks} onChange={(e) => this.updateTextAreaInput(e, "remarks")}></textarea>
                                        </div>
                                    </div>

                                    <div className="row align-items-center">
                                        <div className="offset-md-4 col-md-8 mt-4">
                                            <button type="submit" className="btn btn-primary" onClick={(e) => this.saveAction(e)}>Save</button>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default ServiceProviderOperatorFormView;