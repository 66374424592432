
class URLUtil {

    static getPathParamText(index : number) {
        try {
            //let actual_index = index - 1;
            let loc = window.location;
            let pathname = loc.pathname;
            if(pathname != null) {
                let arr = pathname.split('/');
                return arr[index].trim();
            }
        }catch(e) {
        }
        return "";
    }

    static getPathParamNumber(index : number) {
        try {
            let str = URLUtil.getPathParamText(index);
            return parseInt(str);
        }catch(e) {
        }
        return 0;
    }

    static getParamText(key : string) {
        try {
            let loc = window.location;
            let queryString = loc.search;
            if(queryString != null) {
                let urlParams = new URLSearchParams(queryString);
                let value = urlParams.get(key);
                if(value == null) {
                    value = "";
                }
                return value;
            }
        }catch(e) {
        }
        return "";
    }

}
export default URLUtil;