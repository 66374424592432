class DisplayUtil {

    static showInfoModel(header: string, primary_msg: string, secondery_msg: string, next_path: string) {
        let el_model = document.getElementById("infoModel");
        let el_model_header = document.getElementById("infoModel-header");
        let el_model_body = document.getElementById("infoModel-body");
        let el_model_backdrop = document.getElementById("infoModelBackdrop");
        
        if(el_model != null) {
            el_model.classList.remove("show");
            if(el_model_header != null) {
                el_model_header.textContent = header;
            }
            if(el_model_body != null) {
                let html = '<div class="text-center pt-1">';
                if(primary_msg.length > 0) {
                    html += '<p class="h3 pt-1">'+primary_msg+'</p>';
                }
                if(secondery_msg.length > 0) {
                    html += '<p class="pt-1 mb-0">'+secondery_msg+'</p>';
                }
                html += '</div>';
                el_model_body.innerHTML = html;
            }
            el_model.classList.add("show");
            el_model.style.display = "block";
            el_model.setAttribute("data-path", next_path);
        }
        if(el_model_backdrop != null) {
            el_model_backdrop.style.display = "block";
        }
    }

    static hideInfoModel() {
        let el_model = document.getElementById("infoModel");
        let el_model_header = document.getElementById("infoModel-header");
        let el_model_body = document.getElementById("infoModel-body");
        let el_model_backdrop = document.getElementById("infoModelBackdrop");
        
        if(el_model != null) {
            el_model.classList.remove("show");
            el_model.style.display = "none";
            if(el_model_header != null) {
                el_model_header.textContent = "";
            }
            if(el_model_body != null) {
                el_model_body.innerHTML = '';
            }
            let path = el_model.getAttribute("data-path");
            if(path != null && path.length > 0) {
                window.location.href = path;
            }
        }
        if(el_model_backdrop != null) {
            el_model_backdrop.style.display = "none";
        }
    }

}
export default DisplayUtil;