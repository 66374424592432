import React from 'react';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonProps from '../model/CommonProps';
import CommonState from '../model/CommonState';
import ClientModel from '../model/ClientModel';
import ListData from '../model/ListData';
import URLUtil from '../data/URLUtil';

// 1 - client-services
// 2 - product-commissions
// 3 - product-browse-plans
class ClientsListSelect extends React.Component<CommonProps, CommonState> {

    listData: ListData;
    next_page: number = 0;

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: CommonProps) {
        super(params);
        this.listData = new ListData();
        this.next_page = URLUtil.getPathParamNumber(2);
        this.loadData();
    }

    async loadData() {
        let model = { "command": "all" };
        let sa = new ServerAdaptor();
        let cr = await sa.get_clients(model);
        this.listData = cr.data as ListData;
        this.setState({
            time: new Date()
        });
    }

    async clickAction(e: React.MouseEvent<HTMLElement, MouseEvent>, retailer: ClientModel) {
        e.preventDefault();
        //DataStore.setSelectedRetailerId(retailer.channelId);
        let url = "";
        if(this.next_page!=null) {
            if(this.next_page == 1) {
                url = "/client-operators/"+retailer.channelId;
            }else if(this.next_page == 2) {
                url = "/client-operators/"+retailer.channelId;
            }else if(this.next_page == 3) {
                url = "/products/"+retailer.channelId;
            }
        }
        window.location.href = url;
    }

    render() {

        return (
            <div className="container-fluid p-6">
                
                <div className="row mb-6">
                    <div className="col-12 col-lg-7 col-md-7">
                        <div className="card h-100">
                            <div className="card-header bg-white py-3">
                                <div className="d-md-flex justify-content-between align-items-center">
                                    <div className="mb-3 mb-lg-0 text-center text-sm-start">
                                        <h4 className="mb-0">Select Client</h4>
                                    </div>
                                    <div className="text-center text-md-start">
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive pb-15">
                                <table className="table text-nowrap">
                                    <thead className="table-light">
                                        <tr>
                                            <th>Sno</th>
                                            <th>Client Code</th>
                                            <th>Client Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.listData.clients.map((re, index) =>
                                            <tr>
                                                <td className="align-middle">
                                                    <h5 className=" mb-1">{(index+1)}.</h5>
                                                </td>
                                                <td className="align-middle"><h5 className=" mb-1"><a type="button" className="btn btn-sm btn-link" href="#" onClick={(e) => this.clickAction(e, re)}>{re.channelCode}</a></h5></td>
                                                <td className="align-middle"><a type="button" className="btn btn-sm btn-link" href="#" onClick={(e) => this.clickAction(e, re)}>{re.clientName}</a></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ClientsListSelect;