import React from 'react';
import SelectComponent from '../component/SelectComponent';
import SelectComponentModel from '../component/SelectComponentModel';
import DataStore from '../data/DataStore';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonState from '../model/CommonState';
import CountryModel from '../model/CountryModel';
import CurrencyModel from '../model/CurrencyModel';
import ListData from '../model/ListData';
import OperatorModel from '../model/OperatorModel';
import DisplayUtil from './DisplayUtil';
import ServiceTypeModel from '../model/ServiceTypeModel';
import FormUtil from '../util/FormUtil';
import ErrorResponse from '../util/ErrorResponse';
import SelectSearchModel from '../component/SelectSearchModel';
import SelectSearchComponent from '../component/SelectSearchComponent';
import OptionType from '../component/OptionType';
import URLUtil from '../data/URLUtil';

interface OperatorProps {
    flag_new: boolean;
};

class OperatorFormView extends React.Component<OperatorProps, CommonState> {

    formId: string = "form1";
    flag_new: boolean = true;
    header: string = "New Operator";
    operatorModel: OperatorModel;
    countries: CountryModel[] = [];
    countrySelectModel: SelectSearchModel;
    currencies: CurrencyModel[] = [];
    currencySelectModel: SelectSearchModel;
    serviceTypes: ServiceTypeModel[] = [];
    serviceTypeSelectModel: SelectSearchModel;

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: OperatorProps) {
        super(params);
        this.operatorModel = new OperatorModel();
        this.countrySelectModel = new SelectSearchModel();
        this.currencySelectModel = new SelectSearchModel();
        this.serviceTypeSelectModel = new SelectSearchModel();
        this.flag_new = params.flag_new;
        if(this.flag_new) {
            this.header = "New Operator";
        } else {
            this.header = "Edit Operator";
            this.operatorModel.operatorId = URLUtil.getPathParamNumber(2);
        }
    }

    componentDidMount() {
        this.loadData();
    }

    updateState() {
        this.setState({
            time: new Date()
        });
    }

    async loadData() {
        {
            let model = { "command": "all" };
            let sa = new ServerAdaptor();
            let cr = await sa.get_countries(model);
            let listData = cr.data as ListData;
            this.countries = listData.countries;
            this.countrySelectModel.clear();
            this.countrySelectModel.add("0", "Select Country");
            for(var obj of this.countries) {
                this.countrySelectModel.add(obj.countryId+"", obj.countryName);
            }
        }

        {
            let model = { "command": "all" };
            let sa = new ServerAdaptor();
            let cr = await sa.get_currencies(model);
            let listData = cr.data as ListData;
            this.currencies = listData.currencies;

            this.currencySelectModel.clear();
            this.currencySelectModel.add("0", "Select Currency");
            for(var obj1 of this.currencies) {
                this.currencySelectModel.add(obj1.currencyId+"", obj1.currencyName);
            }
        }

        {
            let model = { "command": "all" };
            let sa = new ServerAdaptor();
            let cr = await sa.get_servicetypes(model);
            let listData = cr.data as ListData;
            this.serviceTypes = listData.serviceTypes;

            this.serviceTypeSelectModel.clear();
            this.serviceTypeSelectModel.add("0", "Select Service Type");
            for(var obj2 of this.serviceTypes) {
                this.serviceTypeSelectModel.add(obj2.serviceTypeId+"", obj2.serviceTypeName);
            }
        }

        if (this.flag_new) {
            
        }else {
            let sa = new ServerAdaptor();
            let cr = await sa.get_operator(this.operatorModel.operatorId);
            this.operatorModel = cr.data as OperatorModel;
            this.countrySelectModel.setSelected(this.operatorModel.countryId+"");
            this.currencySelectModel.setSelected(this.operatorModel.currencyId+"");
            this.serviceTypeSelectModel.setSelected(this.operatorModel.serviceTypeId+"");
        }

        this.updateState();
    }

    updateInput(e: React.ChangeEvent<HTMLInputElement>, typeHint: string) {
        if (typeHint === "operatorCode") {
            this.operatorModel.operatorCode = e.target.value;
        }
        if (typeHint === "operatorName") {
            this.operatorModel.operatorName = e.target.value;
        }
        FormUtil.updateFormState(this.formId);
        this.updateState();
    }

    updateTextAreaInput(e: React.ChangeEvent<HTMLTextAreaElement>, typeHint: string) {
        if (typeHint === "remarks") {
            this.operatorModel.remarks = e.target.value;
        }
        this.updateState();
    }

    onCountrySelectClicked(e: OptionType) {
        let countryId = parseInt(e.value);
        this.operatorModel.countryId = countryId;
    };

    onCurrencySelectClicked(e: OptionType) {
        let currencyId = parseInt(e.value);
        this.operatorModel.currencyId = currencyId;
    };

    onServiceTypeSelectClicked(e: OptionType) {
        let serviceTypeId = parseInt(e.value);
        this.operatorModel.serviceTypeId = serviceTypeId;
    };

    async saveAction(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
    
        if(this.flag_new) {
            let sa = new ServerAdaptor();
            let cr = await sa.new_operator(this.operatorModel);
            if(cr.status === 200) {
                console.log("response : "+cr.data);
                DisplayUtil.showInfoModel("Information", "Success", "", "/operators");
            }else {
                if(cr.status === 501) {
                    FormUtil.showFormErrors(cr.error as ErrorResponse, this.formId);
                }else if(cr.status === 502) {
                    FormUtil.showFormErrors(cr.error as ErrorResponse, this.formId);
                }else {
                    DisplayUtil.showInfoModel("Information", "Error", cr.error, "");
                }
            }
        }else {
            let sa = new ServerAdaptor();
            let cr = await sa.update_operator(this.operatorModel);
            if(cr.status === 200) {
                console.log("response : "+cr.data);
                DisplayUtil.showInfoModel("Information", "Success", "", "/operators");
            }else {
                if(cr.status === 501) {
                    FormUtil.showFormErrors(cr.error as ErrorResponse, this.formId);
                }else if(cr.status === 502) {
                    FormUtil.showFormErrors(cr.error as ErrorResponse, this.formId);
                }else {
                    DisplayUtil.showInfoModel("Information", "Error", cr.error, "");
                }
            }
        }
    }

    render() {

        return (
            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">{this.header}</h3>
                        </div>
                    </div>
                </div>

                <div className="row mb-8">
                    <div className="col-xl-9 col-lg-8 col-md-12 col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-6">
                                    <h4 className="mb-1">Operator Details</h4>
                                </div>
                                <form id={this.formId} className="">
                                    
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Operator Code </label>
                                        <div className="col-md-8 col-12">
                                            <input type="text" className="form-control" placeholder="Operator Code" value={this.operatorModel.operatorCode} onChange={(e) => this.updateInput(e, "operatorCode")}/>
                                            <div id="operatorCode_feedback" className="invalid-feedback"></div>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Name</label>
                                        <div className="col-md-8 col-12">
                                            <input type="text" className="form-control" placeholder="Name" value={this.operatorModel.operatorName} onChange={(e) => this.updateInput(e, "operatorName")}/>
                                            <div id="operatorName_feedback" className="invalid-feedback"></div>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="location" className="col-sm-4 col-form-label form-label">Country</label>
                                        <div className="col-md-8 col-12">
                                            <SelectSearchComponent model={this.countrySelectModel} onSelectClicked={(e) => this.onCountrySelectClicked(e)}></SelectSearchComponent>
                                            <div id="countryId_feedback" className="invalid-feedback"></div>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="location" className="col-sm-4 col-form-label form-label">Currency</label>
                                        <div className="col-md-8 col-12">
                                            <SelectSearchComponent model={this.currencySelectModel} onSelectClicked={(e) => this.onCurrencySelectClicked(e)}></SelectSearchComponent>
                                            <div id="currencyId_feedback" className="invalid-feedback"></div>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="location" className="col-sm-4 col-form-label form-label">Service Type</label>
                                        <div className="col-md-8 col-12">
                                            <SelectSearchComponent model={this.serviceTypeSelectModel} onSelectClicked={(e) => this.onServiceTypeSelectClicked(e)}></SelectSearchComponent>
                                            <div id="serviceTypeId_feedback" className="invalid-feedback"></div>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="addressLine" className="col-sm-4 col-form-label form-label">Remarks</label>
                                        <div className="col-md-8 col-12">
                                            <textarea className="form-control" id="textarea-input" data-rows="5" value={this.operatorModel.remarks} onChange={(e) => this.updateTextAreaInput(e, "remarks")}></textarea>
                                        </div>
                                    </div>

                                    <div className="row align-items-center">
                                        <div className="offset-md-4 col-md-8 mt-4">
                                            <button type="submit" className="btn btn-primary" onClick={(e) => this.saveAction(e)}>Save Changes</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default OperatorFormView;