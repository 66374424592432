import React from 'react';
import CommonProps from '../model/CommonProps';
import ServerAdaptor from '../data/ServerAdaptor';
import LoginModel from '../model/LoginModel';
import DisplayUtil from './DisplayUtil';
import DataStore from '../data/DataStore';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ErrorResponse from '../util/ErrorResponse';
import FormUtil from '../util/FormUtil';


//declare function updateFieldValue(obj: any, field: any, value: any): void;

interface SigninState {
	model: LoginModel;
}

class Signin extends React.Component<CommonProps, SigninState> {

    model: LoginModel;
    formId: string = "login-form";

    state: SigninState = {
        model: new LoginModel()
    };

    constructor(params: CommonProps) {

        super(params);
        this.model = new LoginModel();

    }

    

    updateInput(e: React.ChangeEvent<HTMLInputElement>, typeHint: string) {
        if (typeHint === "login") {
            this.model.loginId = e.target.value;
        }
        if (typeHint === "password") {
            this.model.password = e.target.value;
        }
        this.updateState();
    }

    handleChange(typeHint: string, e: any) {
        let fieldName = e.target.name;
        let fleldVal = e.target.value;
        if (typeHint === "login") {
            this.model.loginId = e.target.value;
        }
        if (typeHint === "password") {
            this.model.password = e.target.value;
        }
        FormUtil.updateFormState(this.formId);
        this.updateState();
    }

    updateState() {
        this.setState({
            model: this.model
        });
    }

    async loginAction(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();

        let sa = new ServerAdaptor();
        let cr = await sa.login_request(this.state.model);
        if (cr.status === 200) {
            console.log("response : " + cr.data);

            let user = cr.data as LoginModel;

            DataStore.setUser(user);
            // DataStore.setSelectedRetailerId(DataStore.getUser().userId);
            DataStore.setToken_id("Bearer "+user.token);
            DataStore.setObject("permissions", user.permissions);

            if((DataStore.isClientUserType())) {
                window.location.href = "/dashboard/"+DataStore.getUser().clientId;
            }else {
                window.location.href = "/admin-dashboard";
            }
        } else {
            if (cr.status === 501) {
                FormUtil.showFormErrors(cr.error as ErrorResponse, this.formId);
            } else {
                console.log("response : FAILED");
                DisplayUtil.showInfoModel("Information", "Error", cr.error, "");
            }
        }
    }

    async closeInfoModelAction(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        console.log("closeInfoModelAction");
        DisplayUtil.hideInfoModel();
    }

    render() {
        return (
            <div className="bg-light">
                <div className="container d-flex flex-column">
                    <div className="row align-items-center justify-content-center g-0
					min-vh-100">
                        <div className="col-12 col-md-8 col-lg-6 col-xxl-4 py-8 py-xl-0">

                            <div className="card smooth-shadow-md">

                                <div className="card-body p-6">
                                    <div className="mb-4 text-center logo" >
                                        <a href="../index.html"><img src="./Logo.png" className="mb-2" alt="" height={"80px"} width={"160px"} /></a>
                                        <p className="mb-5 mt-2 text-center">Please enter your user information.</p>
                                    </div>

                                    <Form id={this.formId} noValidate className="">
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="12">
                                                <Form.Label>Mobile</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Mobile"
                                                    defaultValue=""
                                                    value={this.model.loginId}
                                                    onChange={this.handleChange.bind(this, "login")}
                                                />
                                                <Form.Control.Feedback id="loginId_feedback" type="invalid"></Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>

                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="12">
                                                <Form.Label>Password</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="password"
                                                    placeholder="Password"
                                                    defaultValue=""
                                                    value={this.model.password}
                                                    onChange={this.handleChange.bind(this, "password")}
                                                />
                                                <Form.Control.Feedback id="password_feedback" type="invalid"></Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>

                                        <div className="d-lg-flex justify-content-between align-items-center
										mb-4">
                                            <div className="form-check custom-checkbox">
                                                <input type="checkbox" className="form-check-input" id="rememberme" />
                                                <label className="form-check-label" data-for="rememberme">Remember
                                                    me</label>
                                            </div>
                                        </div>

                                        <div>

                                            <div className="d-grid">
                                                <button type="submit" className="btn btn-primary" onClick={(e) => this.loginAction(e)}>Sign in</button>
                                            </div>

                                            <div className="d-md-flex justify-content-between mt-4">
                                                <div className="mb-2 mb-md-0">

                                                </div>
                                                <div>
                                                    <a href="/forgot-password" className="text-inherit
													fs-5">Forgot your password?</a>
                                                </div>

                                            </div>
                                        </div>

                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="infoModel" data-tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-path="">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="infoModel-header">Modal title</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body pb-1" id="infoModel-body">

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-sm btn-primary px-5" onClick={(e) => this.closeInfoModelAction(e)}>Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show" id="infoModelBackdrop" style={{ "display": "none" }}></div>

                </div>

            </div>
        );
    }
}

export default Signin;