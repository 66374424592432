import React from 'react';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonProps from '../model/CommonProps';
import CommonState from '../model/CommonState';
import "react-datepicker/dist/react-datepicker.css";
import ProductModel from '../model/ProductModel';
import ListData from '../model/ListData';
import DataStore from '../data/DataStore';
import { Dropdown } from 'react-bootstrap';
import URLUtil from '../data/URLUtil';

class OperatorProducts extends React.Component<CommonProps, CommonState> {

    waitDisplay: string = "block";
    tableDisplay: string = "none";

    selectedOperatorId = 0;

    products: ProductModel[] = [];

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: CommonProps) {
        super(params);
        this.selectedOperatorId = URLUtil.getPathParamNumber(2);
        this.loadData();
    }

    updateState() {
        this.setState({
            time: new Date()
        });
    }

    async showWait(show: boolean) {
        if (show) {
            this.waitDisplay = "block";
            this.tableDisplay = "none";
            this.updateState();
        } else {
            this.waitDisplay = "none";
            this.tableDisplay = "block";
        }
    }

    async loadData() {
        let model = { "operatorId": this.selectedOperatorId };
        let sa = new ServerAdaptor();
        this.showWait(true);
        let cr = await sa.get_operator_products(this.selectedOperatorId);
        let listData = cr.data as ListData;
        this.products = listData.products;
        this.showWait(false);
        this.updateState();
    }

    async viewAction(e: React.MouseEvent<HTMLElement, MouseEvent>, productId: number) {
        e.preventDefault();
        DataStore.setSelectedProductId(productId);
        console.log("view");
        window.location.href = "/product-view";
    }

    async editAction(e: React.MouseEvent<HTMLElement, MouseEvent>, productId: number) {
        e.preventDefault();
        DataStore.setSelectedProductId(productId);
        console.log("view");
        window.location.href = "/edit-product";
    }

    async vouchersAction(e: React.MouseEvent<HTMLElement, MouseEvent>, productId: number) {
        e.preventDefault();
        DataStore.setSelectedProductId(productId);
        console.log("view");
        window.location.href = "/product-vouchers";
    }

    render() {

        return (

            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">Products</h3>
                        </div>
                    </div>
                </div>

                <div className="row mb-6" style={{ display: this.waitDisplay }}>
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="card h-100">
                            <div className="card-body">
                                <div className="spinner-border m-5" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mb-6" style={{ display: this.tableDisplay }}>
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="card h-100">
                            <div className="card-header bg-white py-3">
                                <div className="d-md-flex justify-content-between align-items-center">
                                    <div className="mb-3 mb-lg-0 text-center text-sm-start">
                                        <h4 className="mb-0">Products</h4>
                                    </div>
                                    <div className="text-center text-md-start">
                                        <a type="button" className="btn btn-sm btn-primary" href="/new-product">New Product</a>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive pb-15">
                                <table className="table text-nowrap">
                                    <thead className="table-light">
                                        <tr>
                                            <th>Sno</th>
                                            <th>Price</th>
                                            <th>productName</th>
                                            <th>validity</th>
                                            <th>Send Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.products.map((obj, index) =>
                                            <tr>
                                                <td className="align-middle">
                                                    <h5 className=" mb-1">{(index+1)}.</h5>
                                                </td>
                                                <td className="align-middle"><h5 className=" mb-1">{obj.price}</h5></td>
                                                <td className="align-middle">{obj.productName}</td>
                                                <td className="align-middle">{obj.validity}</td>
                                                <td className="align-middle">{obj.sendPrice}</td>
                                                <td className="align-middle">
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="link float-end" id="dropdown-basic">
                                                            <i className="fas fa-ellipsis-v"></i>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item as="button" onClick={(e) => this.viewAction(e, obj.productId)}>View</Dropdown.Item>
                                                            <Dropdown.Item as="button" onClick={(e) => this.editAction(e, obj.productId)}>Edit</Dropdown.Item>
                                                            <Dropdown.Item as="button" onClick={(e) => this.vouchersAction(e, obj.productId)} style={{ "display":  obj.serviceTypeId == 14 ? "block":"none"}}>Vouchers</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default OperatorProducts;