import React from 'react';
import DataStore from '../data/DataStore';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonProps from '../model/CommonProps';
import CommonState from '../model/CommonState';
import RetailerWalletModel from '../model/RetailerWalletModel';
import DisplayUtil from './DisplayUtil';

class ClientWalletAdjustmentView extends React.Component<CommonProps, CommonState> {

    retailerWalletModel: RetailerWalletModel;
    amountAddition: number = 0;

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: CommonProps) {
        super(params);
        this.retailerWalletModel = new RetailerWalletModel();
        this.retailerWalletModel.channelId = DataStore.getSelectedRetailerId();
        this.loadData();
    }

    async loadData() {

        let sa = new ServerAdaptor();
        let cr = await sa.get_client_wallet_info(this.retailerWalletModel.channelId);
        this.retailerWalletModel = cr.data as RetailerWalletModel;
        this.setState({
            time: new Date()
        });
    }

    updateInput(e: React.ChangeEvent<HTMLInputElement>, typeHint: string) {
        if (typeHint === "amountAddition") {
            this.amountAddition = parseInt(e.target.value);
        }
        this.setState({
            time: new Date(),
        });
    }

    async updateWalletAction(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
    
        let model = { "channelId": this.retailerWalletModel.channelId, "amount": this.amountAddition };
        let sa = new ServerAdaptor();
        let cr = await sa.adjust_client_wallet(model);
            if(cr.status === 200) {
                console.log("response : "+cr.data);
                DisplayUtil.showInfoModel("Information", "Success", "", "/retailer-wallet");
            }else {
                console.log("response : FAILED");
            }
    }

    render() {

        return (
            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">Wallet Adjustment</h3>
                        </div>
                    </div>
                </div>

                <div className="row mb-8">
                    <div className="col-xl-9 col-lg-8 col-md-12 col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-6">
                                    <h4 className="mb-1">Wallet Adjustment</h4>
                                </div>
                                <div className="mb-6">
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Available balance </label>
                                        <label data-for="phone" className="col-sm-8 col-form-label form-label">{this.retailerWalletModel.amount +" ("+ this.retailerWalletModel.currencyName+")"}</label>
                                    </div>
                                </div>
                                <form>
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Adjustment Amount</label>
                                        <div className="col-md-8 col-12">
                                            <input type="text" className="form-control" placeholder="0" onChange={(e) => this.updateInput(e, "amountAddition")}/>
                                        </div>
                                    </div>

                                    <div className="row align-items-center">
                                        <div className="offset-md-4 col-md-8 mt-4">
                                            <button type="submit" className="btn btn-primary" onClick={(e) => this.updateWalletAction(e)}>Update</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default ClientWalletAdjustmentView;