import React from 'react';
import CommonProps from '../model/CommonProps';
import CommonState from '../model/CommonState';
import DataStore from '../data/DataStore';
import ServerAdaptor from '../data/ServerAdaptor';

class LinkGroup {
  name: string;
  links: LinkModel[] = [];
  constructor(name: string) {
    this.name = name;
  }
}
class LinkModel {
  name: string;
  url: string;
  isGroup: boolean = false;
  isUserButton: boolean = false;
  icon: string = "folder";
  constructor(name: string, url: string, icon: string) {
    this.name = name;
    this.url = url;
    this.icon = icon;
  }
}

class Sidebar extends React.Component<CommonProps, CommonState> {

  permissions: string[] = [];
  linkGroups: LinkGroup[] = [];
  finalLinks: LinkModel[] = [];

  state: CommonState = {
    time: new Date(),
  };

  constructor(params: CommonProps) {
    super(params);
    this.loadData();
  }

  updateState() {
    this.setState({
      time: new Date()
    });
  }

  async menuClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, linkModel: LinkModel) {
    e.preventDefault();
    DataStore.setSelectedRetailerId(DataStore.getUser().clientId);
    window.location.href = linkModel.url;
  }

  async loadData() {
    if((DataStore.isClientUserType())) {
      this.loadDataClient();
    }else {
      this.loadDataAdmin();
    }
  }

  async loadDataAdmin() {

    let roleId = DataStore.getUser().roleId;

    this.permissions = DataStore.getObject("permissions");
    if(this.permissions==null) {
      this.permissions = [];
    }

    this.linkGroups = [];
    this.finalLinks = [];

    // this.links.push(new LinkModel("Dashboard", "/dashboard"));
    // this.links.push(new LinkModel("Notifications", "/notifications"));
    let linkGroup = null;

    if(roleId!=2) {
      linkGroup = new LinkGroup("Services Management");
      this.linkGroups.push(linkGroup);
      linkGroup.links.push(new LinkModel("Operators", "/operators", "folder"));
      linkGroup.links.push(new LinkModel("Service Providers", "/service-providers" , "folder"));
      linkGroup.links.push(new LinkModel("Service Routing", "/service-provider-routing", "folder"));
      linkGroup.links.push(new LinkModel("Forex", "/forex-list", "folder"));
    }

    {
      linkGroup = new LinkGroup("Channel Management");
      this.linkGroups.push(linkGroup);
      linkGroup.links.push(new LinkModel("Clients", "/clients", "folder"));
      linkGroup.links.push(new LinkModel("Client-Services", "/client-select/2", "folder"));
      linkGroup.links.push(new LinkModel("Commission Config", "/client-select/1", "folder"));
    }
    
    {
      linkGroup = new LinkGroup("Product Management");
      this.linkGroups.push(linkGroup);
      linkGroup.links.push(new LinkModel("Products", "/client-select/3", "file-alt"));
    }

    {
      linkGroup = new LinkGroup("Transactions & Reports");
      this.linkGroups.push(linkGroup);
      linkGroup.links.push(new LinkModel("Transaction Enquiry", "/transaction-enquiry", "file-alt"));
      linkGroup.links.push(new LinkModel("Reports", "/report-dashboard", "file-alt"));
    }

    {
      linkGroup = new LinkGroup("Documentation");
      this.linkGroups.push(linkGroup);
      linkGroup.links.push(new LinkModel("API Documentation", "/api-documentation", "file-alt"));
    }

    let linkTemp = new LinkModel("Dashboard", "/admin-dashboard", "home");
    this.finalLinks.push(linkTemp);

    // linkTemp = new LinkModel("Notifications", "/notifications", "envelope-open");
    // this.finalLinks.push(linkTemp);

    for(let linkGroup of this.linkGroups) {
      linkTemp = new LinkModel(linkGroup.name, "", "folder");
      linkTemp.isGroup = true;
      this.finalLinks.push(linkTemp);
      for(let linkModel of linkGroup.links) {
        this.finalLinks.push(linkModel);
      }
    }

    //this.updateState();
  }

  async loadDataClient() {

    this.permissions = DataStore.getObject("permissions");
    if(this.permissions==null) {
      this.permissions = [];
    }

    this.linkGroups = [];
    this.finalLinks = [];

    let linkGroup = null;

    linkGroup = new LinkGroup("Channel Management");
    this.linkGroups.push(linkGroup);
    {
      let url1 = "/client-view/"+DataStore.getUser().clientId;
      let url2 = "/client-wallet/"+DataStore.getUser().clientId;
      let linkModel = new LinkModel("User-Profile", url1, "id-card");
      linkModel.isUserButton = true;
      linkGroup.links.push(linkModel);
      linkModel = new LinkModel("Wallet", url2, "wallet");
      linkModel.isUserButton = true;
      linkGroup.links.push(linkModel);
    }

    {
      let url1 = "/products/"+DataStore.getUser().clientId;
      linkGroup = new LinkGroup("Product Management");
      this.linkGroups.push(linkGroup);
      linkGroup.links.push(new LinkModel("Products", url1, "file-alt"));
      //linkGroup.links.push(new LinkModel("Operators & Services", "/api-operators", "folder"));
    }

    {
      let url1 = "/report-dashboard/"+DataStore.getUser().clientId;
      linkGroup = new LinkGroup("Transactions & Reports");
      this.linkGroups.push(linkGroup);
      linkGroup.links.push(new LinkModel("Transaction Enquiry", "/transaction-enquiry", "file-alt"));
      linkGroup.links.push(new LinkModel("Reports", url1, "file-alt"));
    }

    {
      linkGroup = new LinkGroup("Documentation");
      this.linkGroups.push(linkGroup);
      linkGroup.links.push(new LinkModel("API Documentation", "/api-documentation", "file-alt"));
    }

    let url_d = "/dashboard/"+DataStore.getUser().clientId;
    let linkTemp = new LinkModel("Dashboard", url_d, "home");
    this.finalLinks.push(linkTemp);

    for(let linkGroup of this.linkGroups) {
      linkTemp = new LinkModel(linkGroup.name, "", "folder");
      linkTemp.isGroup = true;
      this.finalLinks.push(linkTemp);
      for(let linkModel of linkGroup.links) {
        this.finalLinks.push(linkModel);
      }
    }

    //this.updateState();
  }

  renderLink(linkModel: LinkModel) {
    if(linkModel.isGroup) {
      return (
        <div className="navbar-heading">{linkModel.name}</div>
      );
    }else if(linkModel.isUserButton) {
      let cs = "fas fa-"+linkModel.icon+" me-2";
      return (
        <a className="nav-link has-arrow @@if (context.page ===  'dashboard') { active }" href="#" onClick={(e) => this.menuClick(e, linkModel)}>
          <i className={cs}></i>  {linkModel.name}
        </a>
      );
    }else {
      let cs = "fas fa-"+linkModel.icon+" me-2";
      return (
        <a className="nav-link has-arrow @@if (context.page ===  'dashboard') { active }" href={linkModel.url}>
          <i className={cs}></i>  {linkModel.name}
        </a>
      );
    }
  }

  render() {
    let img = ServerAdaptor.base_url2+"./Logo.png";
    return (

      <nav className="navbar-vertical navbar ">
        <div className="nav-scroller scrollbar-width-thin">

          <a className="navbar-brand" href="index.html">
            <img src="/Logo.png" alt="" />
          </a>

          <ul className="navbar-nav flex-column" id="sideNavbar">
            {this.finalLinks.map((el, index) =>
              <li className="nav-item">
                {this.renderLink(el)}
              </li>
            )}
          </ul>

        </div>
      </nav>

    );
  }
}

export default Sidebar;