import DropDownData from "./DropDownData";
import TableCell from "./TableCell";

class TableRowModel {

    cells: TableCell[] = [];

    addTextCell(value: string) {
        var cell = new TableCell();
        cell.value = value;
        this.cells.push(cell);
    }

    addHeaderCell(value: string) {
        var cell = new TableCell();
        cell.value = value;
        cell.data_type = 1;
        this.cells.push(cell);
    }

    addDropDownCell(dropdown_data: DropDownData[]) {
        var cell = new TableCell();
        cell.data_type = 2;
        cell.dropdown_data = dropdown_data;
        this.cells.push(cell);
    }

    addButtonCell(value: string, obj: any) {
        var cell = new TableCell();
        cell.value = value;
        cell.extra_data = obj;
        cell.data_type = 3;
        this.cells.push(cell);
    }
    
}

export default TableRowModel;