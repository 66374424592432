class ForexModel {

    forexId: number = 0;
    srcCurrencyId: number = 0;
    srcCurrencyCode: string = "";
    destCurrencyId: number = 0;
    destCurrencyCode: string = "";
    conversionRate: string = "0";
    processingType: number = 0;
    status: number = 0;
    remarks: string = "";
    
}

export default ForexModel;