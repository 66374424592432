import DashboardChartRecord from "./DashboardChartRecord";

class DashboardChartModel {

    total_sale_today: string = "";
    today_sales_with_lastweekday: string = "";
    total_tansactions_today: string = "";
    today_tansactions_with_lastweekday: string = "";
    total_commission_today: string = "";
    today_commission_with_lastweekday: string = "";
    balance: string = "";
    balance_currency: string = "";

    last7days: DashboardChartRecord[] = [];
    last30days: DashboardChartRecord[] = [];
    
}

export default DashboardChartModel;