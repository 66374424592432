import React from 'react';
import OptionType from '../component/OptionType';

interface MyProps {
    message: string;
};
interface MyState {
    count: number;
};

class SampleView extends React.Component<MyProps, MyState> {
    
    state: MyState = {
        count: 0,
    };

    onSelectClicked(e: OptionType) {
        console.log(e.value);
    };

    render() {
        return (
            <main id="content" role="main" className="main">
                <div className="content container-fluid">
                    
                </div>
            </main>
        );
    }
}

export default SampleView;