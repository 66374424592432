import LoginModel from "../model/LoginModel";

class DataStore {

    static USER_ADMIN: number = 1;
    static USER_SUPPORT: number = 2;
    static USER_CLIENT_ADMIN: number =3;
    static USER_CLIENT: number = 4;

    static openPage(page: string) {
        window.location.href = page;
        window.location.reload();
    }

    static checkLoginAndRedirect() {
        const loggedInUser = DataStore.getUserName();
        if (!(loggedInUser.length > 0)) {
            DataStore.openPage("/signin");
        }
        let sid_session = sessionStorage.getItem("sid");
        let sid_local = localStorage.getItem("sid");
        sid_session = sid_session != null ? sid_session : "";
        sid_local = sid_local != null ? sid_local : "";
        if(sid_session != sid_local) {
            console.info("Sessions are different");
            DataStore.openPage("/signin");
        }else {
            console.info("Sessions are same");
        }
    }

    static setObject(key: string, obj: any) {
        if(obj!=null) {
            let json = JSON.stringify(obj);
            localStorage.setItem(key, json);
        }
    }

    static getObject(key: string) {
        let obj = null;
        let json = localStorage.getItem(key);
        if(json != null) {
            obj = JSON.parse(json);
        }
        return obj;
    }

    static setToken_id(token: string) {
        localStorage.setItem("token", token);
    }

    static getToken_id() {
        let token = localStorage.getItem("token");
        if(token == null) {
            token = "0";
        }
        return token;
    }

    static setUser(user: LoginModel) {
        localStorage.clear();
        this.setObject("user", user);
        let sid = new Date().toString();
        sessionStorage.setItem("sid", sid);
        localStorage.setItem("sid", sid);
    }

    static getUser() {
        let obj = new LoginModel();
        let obj2 = this.getObject("user");
        if(obj2 != null) {
            obj = obj2;
        }
        return obj;
    }

    static getUserName() {
        return DataStore.getUser().userName;
    }

    static getUserRole() {
        return DataStore.getUser().roleId;
    }

    static isClientUserType() {
        let roleId = DataStore.getUser().roleId;
        if(roleId == 3 || roleId == 4) {
            return true;
        }
        return false;
    }

    static isAdminUserType() {
        let roleId = DataStore.getUser().roleId;
        if(roleId == 1 || roleId == 2) {
            return true;
        }
        return false;
    }

    static setSelectedProvider(selectedProviderId: number, selectedProviderName: string) {
        localStorage.setItem("selectedServiceProviderId", selectedProviderId+"");
        localStorage.setItem("selectedServiceProviderName", selectedProviderName);
    }

    static setSelectedServiceProviderId(id: number) {
        localStorage.setItem("selectedServiceProviderId", id+"");
    }

    static getSelectedServiceProviderId() {
        return this.getIntegerValue("selectedServiceProviderId");
    }

    static getSelectedServiceProviderName() {
        return this.getValue("selectedServiceProviderName");
    }

    static setSelectedOperatorId(id: number) {
        localStorage.setItem("operatorId", id+"");
    }

    static getSelectedOperatorId() {
        return this.getIntegerValue("operatorId");
    }

    static setSelectedNotificationId(id: number) {
        localStorage.setItem("selectedNotificationId", id+"");
    }

    static getNotificationMappingId() {
        return this.getIntegerValue("selectedNotificationId");
    }

    static setSelectedMappingId(id: number) {
        localStorage.setItem("selectedMappingId", id+"");
    }

    static getSelectedMappingId() {
        return this.getIntegerValue("selectedMappingId");
    }

    static setRefRetailerId(id: number) {
        localStorage.setItem("refRetailerId", id+"");
    }

    static getRefRetailerId() {
        return this.getIntegerValue("refRetailerId");
    }

    static setCategoryGroupId(categoryGroupId: number) {
        localStorage.setItem("categoryGroupId", categoryGroupId+"");
    }

    static getCategoryGroupId() {
        return this.getIntegerValue("categoryGroupId");
    }

    static setSelectedCategoryGroup(selectedCategoryGroupId: number, selectedCategoryGroupName: string) {
        localStorage.setItem("selectedCategoryGroupId", selectedCategoryGroupId+"");
        localStorage.setItem("selectedCategoryGroupName", selectedCategoryGroupName);
    }

    static getSelectedCategoryGroupId() {
        return this.getIntegerValue("selectedCategoryGroupId");
    }

    static getSelectedCategoryGroupName() {
        return this.getValue("selectedCategoryGroupName");
    }

    static setSelectedForexId(id: number) {
        localStorage.setItem("forexId", id+"");
    }

    static getSelectedForexId() {
        return this.getIntegerValue("forexId");
    }

    static setSelectedMarkupId(id: number) {
        localStorage.setItem("markupId", id+"");
    }

    static getSelectedMarkupId() {
        return this.getIntegerValue("markupId");
    }

    static setSelectedProfile(selectedProfileId: number, selectedProfileName: string) {
        localStorage.setItem("selectedProfileId", selectedProfileId+"");
        localStorage.setItem("selectedProfileName", selectedProfileName);
    }

    static setSelectedProfileId(profileId: number) {
        localStorage.setItem("selectedProfileId", profileId+"");
    }

    static getSelectedProfileId() {
        return this.getIntegerValue("selectedProfileId");
    }

    static getSelectedProfileName() {
        return this.getValue("selectedProfileName");
    }

    static setSelectedProfileServiceId(id: number) {
        localStorage.setItem("selectedProfileServiceId", id+"");
    }

    static getSelectedProfileServiceId() {
        return this.getIntegerValue("selectedProfileServiceId");
    }

    static setSelectedRetailerId(id: number) {
        localStorage.setItem("selectedRetailerId", id+"");
    }

    static getSelectedRetailerId() {
        return this.getIntegerValue("selectedRetailerId");
    }

    static setSelectedProductId(id: number) {
        localStorage.setItem("selectedProductId", id+"");
    }

    static getSelectedProductId() {
        return this.getIntegerValue("selectedProductId");
    }

    static setSelectedVoucherPinId(id: number) {
        localStorage.setItem("selectedVoucherPinId", id+"");
    }

    static getSelectedVoucherPinId() {
        return this.getIntegerValue("selectedVoucherPinId");
    }

    static getValue(key: string) {
        let value = localStorage.getItem(key);
        return value != null ? value : "";
    }

    static getIntegerValue(key: string) {
        let num = 0;
        let num_str = localStorage.getItem(key);
        if(num_str!=null) {
            num = parseInt(num_str);
        }
        return num;
    }

}
export default DataStore;