import PaginationItemModel from "./PaginationItemModel";

class PaginationModel {

    perPageCount: number = 20;
    recordsCount: number = 0;
    pagesCount: number = 0;
    pages: PaginationItemModel[] = [];
    selectedPage: PaginationItemModel = new PaginationItemModel();
    displayPages: PaginationItemModel[] = [];

    setPages(recordsCount: number, perPageCount: number) {

        this.perPageCount = perPageCount;
        this.recordsCount = recordsCount;
        this.pagesCount = 0;
        this.pages = [];
        var r = 0;
        while (r < recordsCount) {
            var j = 0;
            this.pagesCount = this.pagesCount + 1;
            var paginationItemModel = new PaginationItemModel();
            paginationItemModel.pageNumber = this.pagesCount;
            this.pages.push(paginationItemModel);
            while ((j < perPageCount) && (r < recordsCount)) {
                paginationItemModel.records.push(r);
                j++;
                r++;
            }
        }

        if (this.pages.length > 0) {
            this.setSelected(this.pages[0]);
        }

        // var pagesCountTemp = recordsCount/perPageCount;
        // this.pagesCount = Math.trunc(pagesCountTemp);
        // var recordsCountTemp = this.pagesCount * perPageCount;
        // if(recordsCount > recordsCountTemp) {
        //     this.pagesCount = this.pagesCount + 1;
        // }

        // this.pages = [this.pagesCount];
        // for(var i=1; i<=this.pagesCount; i++) {
        //     this.pages[i-1] = i;
        // }
    }

    setSelected(selectedPage: PaginationItemModel) {
        var selectedPageNew = selectedPage;
        if(selectedPage.elemenetType!=0 && selectedPage.elemenetType!=1) {
            for (var i = 0; i < this.pages.length; i++) {
                var pi = this.pages[i];
                if(pi.pageNumber == selectedPage.pageNumber) {
                    selectedPageNew = pi;
                }
            }
        }
        this.setSelectedIn(selectedPageNew);
    }

    setSelectedIn(selectedPage: PaginationItemModel) {

        for (var i = 0; i < this.pages.length; i++) {
            var pi = this.pages[i];
            pi.elemenetType = 0;
        }

        this.selectedPage = selectedPage;

        var displayCount = 7;

        this.displayPages = [];

        // if (this.pages.length <= displayCount) {
        //     for (var i = 0; i <= this.pages.length; i++) {
        //         var pi = this.pages[i];
        //         pi.elemenetType = (pi == selectedPage) ? 1 : 0;
        //         this.displayPages.push(pi);
        //     }
        // } else {

            var displayPagesNew: PaginationItemModel[] = [];
            for (var i = 0; i < 9; i++) {
                displayPagesNew[i] = new PaginationItemModel();
            }

            if ((selectedPage.pageNumber - 3) > 0) {
                var pi_first = this.pages[0];
                pi_first = pi_first.getClone();
                pi_first.elemenetType = 3;
                displayPagesNew[0] = pi_first;
            }
            if ((selectedPage.pageNumber - 1) > 0) {
                var pi_prev = (this.pages[(selectedPage.pageNumber - 2)]);
                pi_prev = pi_prev.getClone();
                pi_prev.elemenetType = 5;
                displayPagesNew[1] = pi_prev;
            }
            if ((selectedPage.pageNumber - 2) > 0) {
                displayPagesNew[2] = (this.pages[(selectedPage.pageNumber - 3)]);
            }
            if ((selectedPage.pageNumber - 1) > 0) {
                displayPagesNew[3] = (this.pages[(selectedPage.pageNumber - 2)]);
            }
            displayPagesNew[4] = (this.pages[(selectedPage.pageNumber - 1)]);
            displayPagesNew[4].elemenetType = 1;

            if ((selectedPage.pageNumber + 1) <= this.pagesCount) {
                displayPagesNew[5] = (this.pages[(selectedPage.pageNumber)]);
            }
            if ((selectedPage.pageNumber + 2) <= this.pagesCount) {
                displayPagesNew[6] = (this.pages[(selectedPage.pageNumber + 1)]);
            }
            if ((selectedPage.pageNumber + 1) <= this.pagesCount) {
                var pi_next = (this.pages[(selectedPage.pageNumber)]);
                pi_next = pi_next.getClone();
                pi_next.elemenetType = 6;
                displayPagesNew[7] = pi_next;
            }
            if ((selectedPage.pageNumber + 3) <= this.pagesCount) {
                var pi_last = this.pages[this.pages.length - 1];
                pi_last = pi_last.getClone();
                pi_last.elemenetType = 4;
                displayPagesNew[8] = pi_last;
            }

            for (var i = 0; i < 9; i++) {
                var pi = displayPagesNew[i];
                // if(pi.pageNumber > 0) {
                // }
                this.displayPages.push(pi);
            }
        // }
    }

    getPaginationPages() {
        return this.displayPages;
    }

}

export default PaginationModel;