import React from 'react';
import DataStore from '../data/DataStore';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonState from '../model/CommonState';
import ListData from '../model/ListData';
import DisplayUtil from './DisplayUtil';
import ServiceTypeModel from '../model/ServiceTypeModel';
import ProductModel from '../model/ProductModel';
import SelectSearchModel from '../component/SelectSearchModel';
import OptionType from '../component/OptionType';
import SelectSearchComponent from '../component/SelectSearchComponent';

interface OperatorProps {
    flag_new: boolean;
};

class ProductFormView extends React.Component<OperatorProps, CommonState> {

    flag_new: boolean = true;
    header: string = "New Product";
    productModel: ProductModel;
    serviceTypes: ServiceTypeModel[] = [];
    serviceTypeSelectModel: SelectSearchModel;

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: OperatorProps) {
        super(params);
        this.productModel = new ProductModel();
        this.serviceTypeSelectModel = new SelectSearchModel();
        this.flag_new = params.flag_new;
        if(this.flag_new) {
            this.header = "New Product";
            this.productModel.operatorId = DataStore.getSelectedOperatorId();
        } else {
            this.header = "Edit Product";
            this.productModel.productId = DataStore.getSelectedProductId();
        }
    }

    componentDidMount() {
        this.loadData();
    }

    updateState() {
        this.setState({
            time: new Date()
        });
    }

    async loadData() {
        
        {
            let model = { "command": "all" };
            let sa = new ServerAdaptor();
            let cr = await sa.get_servicetypes(model);
            let listData = cr.data as ListData;
            this.serviceTypes = listData.serviceTypes;

            this.serviceTypeSelectModel.clear();
            this.serviceTypeSelectModel.add("0", "Select Service Type");
            for(var obj2 of this.serviceTypes) {
                this.serviceTypeSelectModel.add(obj2.serviceTypeId+"", obj2.serviceTypeName);
            }
        }

        if (this.flag_new) {
            
        }else {
            let model = { "productId": this.productModel.productId };
            let sa = new ServerAdaptor();
            let cr = await sa.get_product_internal(model);
            this.productModel = cr.data as ProductModel;
            this.serviceTypeSelectModel.setSelected(this.productModel.serviceTypeId+"");
        }

        this.updateState();
    }

    updateInput(e: React.ChangeEvent<HTMLInputElement>, typeHint: string) {
        if (typeHint === "productName") {
            this.productModel.productName = e.target.value;
        }
        if (typeHint === "price") {
            this.productModel.price = e.target.value;
        }
        if (typeHint === "validity") {
            this.productModel.validity = e.target.value;
        }
        if (typeHint === "shortDescription") {
            this.productModel.shortDescription = e.target.value;
        }
        if (typeHint === "description") {
            this.productModel.description = e.target.value;
        }
        if (typeHint === "productImage") {
            this.productModel.productImage = e.target.value;
        }
        if (typeHint === "voucherTemplate") {
            this.productModel.voucherTemplate = e.target.value;
        }
        this.updateState();
    }

    updateTextAreaInput(e: React.ChangeEvent<HTMLTextAreaElement>, typeHint: string) {
        if (typeHint === "remarks") {
            this.productModel.remarks = e.target.value;
        }
        this.updateState();
    }

    onServiceTypeSelectClicked(e: OptionType) {
        let serviceTypeId = parseInt(e.value);
        this.productModel.serviceTypeId = serviceTypeId;
    };

    async saveAction(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
    
        if(this.flag_new) {
            let sa = new ServerAdaptor();
            let cr = await sa.new_product(this.productModel);
            if(cr.status === 200) {
                console.log("response : "+cr.data);
                DisplayUtil.showInfoModel("Information", "Success", "", "/operator-products");
            }else {
                if(cr.status === 501) {
                    DisplayUtil.showInfoModel("Information", "Error", JSON.stringify(cr.error), "");
                }else if(cr.status === 502) {
                    DisplayUtil.showInfoModel("Information", "Error", cr.error, "");
                }else {
                    console.log("response : FAILED");
                }
            }
        }else {
            let sa = new ServerAdaptor();
            let cr = await sa.update_product(this.productModel);
            if(cr.status === 200) {
                console.log("response : "+cr.data);
                DisplayUtil.showInfoModel("Information", "Success", "", "/operator-products");
            }else {
                if(cr.status === 501) {
                    DisplayUtil.showInfoModel("Information", "Error", JSON.stringify(cr.error), "");
                }else if(cr.status === 502) {
                    DisplayUtil.showInfoModel("Information", "Error", cr.error, "");
                }else {
                    console.log("response : FAILED");
                }
            }
        }
    }

    render() {

        return (
            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">{this.header}</h3>
                        </div>
                    </div>
                </div>

                <div className="row mb-8">
                    <div className="col-xl-9 col-lg-8 col-md-12 col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-6">
                                    <h4 className="mb-1">Product Details</h4>
                                </div>
                                <form>
                                    
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Name</label>
                                        <div className="col-md-8 col-12">
                                            <input type="text" className="form-control" placeholder="Name" value={this.productModel.productName} onChange={(e) => this.updateInput(e, "productName")}/>
                                            <div className="invalid-feedback"></div>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Price</label>
                                        <div className="col-md-8 col-12">
                                            <input type="text" className="form-control" placeholder="Price" value={this.productModel.price} onChange={(e) => this.updateInput(e, "price")}/>
                                            <div className="invalid-feedback"></div>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Validity</label>
                                        <div className="col-md-8 col-12">
                                            <input type="text" className="form-control" placeholder="Validity" value={this.productModel.validity} onChange={(e) => this.updateInput(e, "validity")}/>
                                            <div className="invalid-feedback"></div>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Short Description</label>
                                        <div className="col-md-8 col-12">
                                            <input type="text" className="form-control" placeholder="ShortDescription" value={this.productModel.shortDescription} onChange={(e) => this.updateInput(e, "shortDescription")}/>
                                            <div className="invalid-feedback"></div>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Description</label>
                                        <div className="col-md-8 col-12">
                                            <input type="text" className="form-control" placeholder="Description" value={this.productModel.description} onChange={(e) => this.updateInput(e, "description")}/>
                                            <div className="invalid-feedback"></div>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Product Image</label>
                                        <div className="col-md-8 col-12">
                                            <input type="text" className="form-control" placeholder="Image" value={this.productModel.productImage} onChange={(e) => this.updateInput(e, "productImage")}/>
                                            <div className="invalid-feedback"></div>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="location" className="col-sm-4 col-form-label form-label">Service Type</label>
                                        <div className="col-md-8 col-12">
                                            <SelectSearchComponent model={this.serviceTypeSelectModel} onSelectClicked={(e) => this.onServiceTypeSelectClicked(e)}></SelectSearchComponent>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Voucher Template</label>
                                        <div className="col-md-8 col-12">
                                            <input type="text" className="form-control" placeholder="Template" value={this.productModel.voucherTemplate} onChange={(e) => this.updateInput(e, "voucherTemplate")}/>
                                            <div className="invalid-feedback"></div>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="addressLine" className="col-sm-4 col-form-label form-label">Remarks</label>
                                        <div className="col-md-8 col-12">
                                            <textarea className="form-control" id="textarea-input" data-rows="5" value={this.productModel.remarks} onChange={(e) => this.updateTextAreaInput(e, "remarks")}></textarea>
                                        </div>
                                    </div>

                                    <div className="row align-items-center">
                                        <div className="offset-md-4 col-md-8 mt-4">
                                            <button type="submit" className="btn btn-primary" onClick={(e) => this.saveAction(e)}>Save Changes</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default ProductFormView;