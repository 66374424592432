import React from 'react';
import Callback from '../component/Callback';
import DataStore from '../data/DataStore';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonState from '../model/CommonState';
import ClientOperatorModel from '../model/ClientOperatorModel';
import CommonResponse from '../util/CommonResponse';
import DisplayUtil from './DisplayUtil';
import SelectSearchModel from '../component/SelectSearchModel';
import OptionType from '../component/OptionType';
import SelectSearchComponent from '../component/SelectSearchComponent';
import FormUtil from '../util/FormUtil';
import ErrorResponse from '../util/ErrorResponse';
import OperatorsModel from '../model/OperatorsModel';
import OperatorModel from '../model/OperatorModel';
import URLUtil from '../data/URLUtil';
import ClientModel from '../model/ClientModel';

interface ClientOperatorViewProps {
    flag_new: boolean;
};

class ClientOperatorView extends React.Component<ClientOperatorViewProps, CommonState> implements Callback {

    formId: string = "form1";
    flag_new: boolean = true;
    header: string = "New Service";
    clientOperatorModel: ClientOperatorModel;
    operators: OperatorModel[] = [];
    groupOperatorsSelectModel: SelectSearchModel;
    commissionTypeSelectModel: SelectSearchModel;
    denominationMinFlag = true;
    denominationMaxFlag = true;

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: ClientOperatorViewProps) {
        super(params);
        this.clientOperatorModel = new ClientOperatorModel();
        this.groupOperatorsSelectModel = new SelectSearchModel();
        this.commissionTypeSelectModel = new SelectSearchModel();
        this.flag_new = params.flag_new;
        if(this.flag_new) {
            this.header = "New Service";
            this.clientOperatorModel.channelId = URLUtil.getPathParamNumber(2);
            this.clientOperatorModel.channelCode = "";
        } else {
            this.header = "Edit Service";
            this.clientOperatorModel.channelOperatorId = URLUtil.getPathParamNumber(2);
            this.clientOperatorModel.channelCode = "";
        }
        this.loadData();
    }

    updateState() {
        this.setState({
            time: new Date()
        });
    }

    async loadData() {
        
        
        {
            this.commissionTypeSelectModel.add("0", "Select Commission Type");
            this.commissionTypeSelectModel.add("1", "Percentage");
            this.commissionTypeSelectModel.add("2", "Fixed");
        }

        if(this.flag_new) {
            
            {
                let sa = new ServerAdaptor();
                let cr = await sa.get_client(this.clientOperatorModel.channelId);
                let clientModel = cr.data as ClientModel;
                this.clientOperatorModel.channelCode = clientModel.channelCode;
            }

            {
                let sa = new ServerAdaptor();
                let model = { "command": "all" };
                let cr = await sa.get_operators(model);
                let operatorsModel = cr.data as OperatorsModel;

                this.operators = operatorsModel.operators;

                this.groupOperatorsSelectModel.clear();
                this.groupOperatorsSelectModel.add("0", "Select Operator");
                for(var obj2 of this.operators) {
                    this.groupOperatorsSelectModel.add(obj2.operatorId+"", obj2.operatorName);
                }
            }

        }else {
            {
                let sa = new ServerAdaptor();
                let cr = await sa.get_client_service_config(this.clientOperatorModel.channelOperatorId);
                this.clientOperatorModel = cr.data as ClientOperatorModel;
            }

            {
                let sa = new ServerAdaptor();
                let model = { "command": "all" };
                let cr = await sa.get_operators(model);
                let operatorsModel = cr.data as OperatorsModel;

                this.operators = operatorsModel.operators;

                this.groupOperatorsSelectModel.clear();
                this.groupOperatorsSelectModel.add("0", "Select Operator");
                for(var obj2 of this.operators) {
                    this.groupOperatorsSelectModel.add(obj2.operatorId+"", obj2.operatorName);
                }
            }

            this.groupOperatorsSelectModel.setSelected(this.clientOperatorModel.operatorId+"");
            this.commissionTypeSelectModel.setSelected(this.clientOperatorModel.commissionType+"");
        }
        this.updateState();
    }

    updateInput(e: React.ChangeEvent<HTMLInputElement>, typeHint: string) {
        
        if (typeHint === "commissionValue") {
            // let value = parseFloat(e.target.value);
            // this.clientOperatorModel.commissionValue = value;
            this.clientOperatorModel.commissionValue = e.target.value;
        }
        if (typeHint === "denominationFlag") {
            var el = e.target as HTMLInputElement;
            var isChecked = el.checked;
            if (isChecked) {
                this.clientOperatorModel.denominationFlag = 1;
                this.denominationMinFlag = false;
                this.denominationMaxFlag = false;
            } else {
                this.clientOperatorModel.denominationFlag = 0;
                this.denominationMinFlag = true;
                this.denominationMaxFlag = true;
                this.clientOperatorModel.denominationMin = "0";
                this.clientOperatorModel.denominationMax = "0";
            }
        }
        if (typeHint === "denominationMin") {
            this.clientOperatorModel.denominationMin = e.target.value;
        }
        if (typeHint === "denominationMax") {
            this.clientOperatorModel.denominationMax = e.target.value;
        }
        this.updateState();
    }

    updateTextAreaInput(e: React.ChangeEvent<HTMLTextAreaElement>, typeHint: string) {
        if (typeHint === "remarks") {
            this.clientOperatorModel.remarks = e.target.value;
        }
        this.updateState();
    }

    onGroupOperatorSelectClicked(e: OptionType) {
        let id = parseInt(e.value);
        this.clientOperatorModel.operatorId = id;
        this.groupOperatorsSelectModel.setSelected(this.clientOperatorModel.operatorId+"");
        this.updateState();
    };

    onCommissionTypeSelectClicked(e: OptionType) {
        let id = parseInt(e.value);
        this.clientOperatorModel.commissionType = id;
        this.commissionTypeSelectModel.setSelected(this.clientOperatorModel.commissionType+"");
        this.updateState();
    };

    onResponse(cmd: string, commonResponse: CommonResponse): void {
        if(cmd === "categoryOperators") {
            //this.setCategoryOperators(commonResponse);
        }
    }

    // setCategoryOperators(commonResponse: CommonResponse) {
    //     let listData = commonResponse.data as ListData;
    //     this.categoryOperators = listData.categoryOperators;

    //     this.groupOperatorsSelectModel.clear();
    //     this.groupOperatorsSelectModel.add("0", "Select Operator");
    //     for(var obj2 of this.categoryOperators) {
    //         this.groupOperatorsSelectModel.add(obj2.operatorId+"", obj2.operatorName);
    //     }
    //     this.updateState();
    // }

    async saveAction(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
    
        if(this.flag_new) {
            let sa = new ServerAdaptor();
            let cr = await sa.new_client_service_config(this.clientOperatorModel);
            if (cr.status === 200) {
                console.log("response : " + cr.data);
                DisplayUtil.showInfoModel("Information", "Success", "", "/client-operators/"+this.clientOperatorModel.channelId);
            } else {
                if (cr.status === 501) {
                    FormUtil.showFormErrors(cr.error as ErrorResponse, this.formId);
                } else {
                    console.log("response : FAILED");
                    DisplayUtil.showInfoModel("Information", "Error", cr.error, "");
                }
            }
        }else {
            let sa = new ServerAdaptor();
            let cr = await sa.update_client_service_config(this.clientOperatorModel);
            if (cr.status === 200) {
                console.log("response : " + cr.data);
                DisplayUtil.showInfoModel("Information", "Success", "", "/client-operators/"+this.clientOperatorModel.channelId);
            } else {
                if (cr.status === 501) {
                    FormUtil.showFormErrors(cr.error as ErrorResponse, this.formId);
                } else {
                    console.log("response : FAILED");
                    DisplayUtil.showInfoModel("Information", "Error", cr.error, "");
                }
            }
        }
    }

    render() {

        var selectDenominationFlagOption = "checked";
        if (this.clientOperatorModel.denominationFlag == 1) {
            selectDenominationFlagOption = "checked";
        }

        return (
            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">{this.header}</h3>
                        </div>
                    </div>
                </div>

                <div className="row mb-8">
                    <div className="col-xl-9 col-lg-8 col-md-12 col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-6">
                                    <h4 className="mb-1">Service Details</h4>
                                </div>
                                <form>
                                    
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Client</label>
                                        <div className="col-md-8 col-12">
                                            <input type="text" className="form-control" placeholder="Name" value={this.clientOperatorModel.channelCode} disabled={true}/>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <label data-for="location"
                                            className="col-sm-4 col-form-label form-label">Operator</label>
                                        <div className="col-md-8 col-12">
                                            <SelectSearchComponent model={this.groupOperatorsSelectModel} onSelectClicked={(e) => this.onGroupOperatorSelectClicked(e)}></SelectSearchComponent>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <label data-for="location"
                                            className="col-sm-4 col-form-label form-label">Commission Type</label>
                                        <div className="col-md-8 col-12">
                                            <SelectSearchComponent model={this.commissionTypeSelectModel} onSelectClicked={(e) => this.onCommissionTypeSelectClicked(e)}></SelectSearchComponent>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Commission Value</label>
                                        <div className="col-md-8 col-12">
                                            <input type="text" className="form-control" placeholder="" value={this.clientOperatorModel.commissionValue} onChange={(e) => this.updateInput(e, "commissionValue")}/>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Denomination Flag</label>
                                        <div className="col-md-8 col-12 pt-2">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" value={selectDenominationFlagOption} onChange={(e) => this.updateInput(e, "denominationFlag")}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Denomination Min</label>
                                        <div className="col-md-8 col-12">
                                            <input type="text" className="form-control" placeholder="" value={this.clientOperatorModel.denominationMin} onChange={(e) => this.updateInput(e, "denominationMin")} disabled={this.denominationMinFlag}/>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Denomination Max</label>
                                        <div className="col-md-8 col-12">
                                            <input type="text" className="form-control" placeholder="" value={this.clientOperatorModel.denominationMax} onChange={(e) => this.updateInput(e, "denominationMax")} disabled={this.denominationMaxFlag}/>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <label data-for="addressLine" className="col-sm-4 col-form-label form-label">Remarks</label>
                                        <div className="col-md-8 col-12">
                                            <textarea className="form-control" id="textarea-input" data-rows="5" value={this.clientOperatorModel.remarks} onChange={(e) => this.updateTextAreaInput(e, "remarks")}></textarea>
                                        </div>
                                    </div>

                                    <div className="row align-items-center">
                                        <div className="offset-md-4 col-md-8 mt-4">
                                            <button type="submit" className="btn btn-primary" onClick={(e) => this.saveAction(e)}>Save Changes</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default ClientOperatorView;