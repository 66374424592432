import React from 'react';
import CommonProps from '../model/CommonProps';
import CommonState from '../model/CommonState';
import DashboardModel from '../model/DashboardModel';
import { Chart } from "react-google-charts";
import DashboardChartModel from '../model/DashboardChartModel';
import ServerAdaptor from '../data/ServerAdaptor';
import DataStore from '../data/DataStore';
import URLUtil from '../data/URLUtil';

class AdminDashboard extends React.Component<CommonProps, CommonState> {

  dashboardModel: DashboardModel;
  dashboardChartModel: DashboardChartModel;
  data_7days: any[] = [];
  data_30days: any[] = [];

  state: CommonState = {
    time: new Date(),
  };

  constructor(params: CommonProps) {
    super(params);
    this.dashboardModel = new DashboardModel();
    this.dashboardChartModel = new DashboardChartModel();
    this.loadData();
  }

  updateState() {
    this.setState({
      time: new Date()
    });
  }

  async loadData() {
        let txnType = "dashboard";
        let model = { "serviceProviderId": 0, "txnType": txnType, "fromDate": "2024-01-01", "toDate": "2024-01-01" };
        let sa = new ServerAdaptor();
        let cr = await sa.get_admin_dashboard_data(model);

        this.dashboardChartModel = cr.data as DashboardChartModel;

        if(this.dashboardChartModel.last7days!=null) {
          this.data_7days.push(["Date", "Sales"]);
          for (let i = 0; i < this.dashboardChartModel.last7days.length; i++) {
            let dcr = this.dashboardChartModel.last7days[i];
            let arr = [dcr.name, dcr.values[0]];
            this.data_7days.push(arr);
          }
        }

        if(this.dashboardChartModel.last30days!=null) {
          this.data_30days.push(["Date", "This Month", "Last Month"]);
          for (let i = 0; i < this.dashboardChartModel.last30days.length; i++) {
            let dcr = this.dashboardChartModel.last30days[i];
            let arr = [dcr.name, dcr.values[1], dcr.values[0]];
            this.data_30days.push(arr);
          }
        }
        
        this.updateState();
  }

  async loginAction(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();

  }

  data_bkp = [
    ["Year", "Sales", "Expenses", "Profit"],
    ["2014", 1000, 400, 200],
    ["2015", 1170, 460, 250],
    ["2016", 660, 1120, 300],
    ["2017", 1030, 540, 350],
    ["2018", 660, 1120, 300],
    ["2019", 1030, 540, 350],
    ["2020", 1030, 540, 350],
  ];

  options_7days = {
    chart: {
      title: "Last 7 days",
      subtitle: "Sales in (SAR)",
    },
  };

  data1 = [
    [
      "Day",
      "Guardians of the Galaxy",
      "The Avengers",
      "Transformers: Age of Extinction",
    ],
    [1, 37.8, 80.8, 41.8],
    [2, 30.9, 69.5, 32.4],
    [3, 25.4, 57, 25.7],
    [4, 11.7, 18.8, 10.5],
    [5, 11.9, 17.6, 10.4],
    [6, 8.8, 13.6, 7.7],
    [7, 7.6, 12.3, 9.6],
    [8, 12.3, 29.2, 10.6],
    [9, 16.9, 42.9, 14.8],
    [10, 12.8, 30.9, 11.6],
    [11, 5.3, 7.9, 4.7],
    [12, 6.6, 8.4, 5.2],
    [13, 4.8, 6.3, 3.6],
    [14, 4.2, 6.2, 3.4],
  ];
  
  options_30days = {
    chart: {
      title: "This Month",
      subtitle: "Sales in (SAR)",
    },
  };

  render() {

    return (
      <div className="">
        <div className=" pt-10 pb-21"></div>
        <div className="container-fluid mt-n22 px-6">

          <div className="row">
            <div className="col-xl-3 col-lg-6 col-md-12 col-12 mb-5">

              <div className="card h-100 card-lift">

                <div className="card-body">

                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div>
                      <h4 className="mb-0">Sales</h4>
                    </div>
                    <div className="icon-shape icon-md bg-primary-soft text-primary rounded-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-briefcase"><rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path></svg>
                    </div>
                  </div>

                  <div className="lh-1">
                    <h1 className=" mb-1 fw-bold">{this.dashboardChartModel.total_sale_today}</h1>
                    <p className="mb-0"><span className="text-dark me-2">{this.dashboardChartModel.today_sales_with_lastweekday}%</span>Same day last week</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-12 col-12 mb-5">

              <div className="card h-100 card-lift">

                <div className="card-body">

                  <div className="d-flex justify-content-between align-items-center
                    mb-3">
                    <div>
                      <h4 className="mb-0">Transactions</h4>
                    </div>
                    <div className="icon-shape icon-md bg-primary-soft text-primary
                      rounded-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-list"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg>
                    </div>
                  </div>

                  <div className="lh-1">
                    <h1 className="  mb-1 fw-bold">{this.dashboardChartModel.total_tansactions_today}</h1>
                    <p className="mb-0"><span className="text-dark me-2">{this.dashboardChartModel.today_tansactions_with_lastweekday}%</span>Same day last week</p>
                  </div>
                </div>
              </div>
            </div>
            
          </div>

          <div className="row">

            <div className="col-12 mb-3">

              <div className="card">

                <div className="card-body">

                  <Chart
                    chartType="Bar"
                    width="100%"
                    height="400px"
                    data={this.data_7days}
                    options={this.options_7days}
                  />

                </div>
              </div>
            </div>

          </div>

          <div className="row">

            <div className="col-12 mb-3">

              <div className="card">

                <div className="card-body">

                  <Chart
                    chartType="Line"
                    width="100%"
                    height="400px"
                    data={this.data_30days}
                    options={this.options_30days}
                  />

                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    );
  }
}

export default AdminDashboard;
