import React from 'react';
import DataStore from '../data/DataStore';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonProps from '../model/CommonProps';
import CommonState from '../model/CommonState';
import OperatorsModel from '../model/OperatorsModel';
import PaginationTableComponent from '../component/PaginationTableComponent';
import TableModel from '../component/TableModel';
import TableRowModel from '../component/TableRowModel';
import DropDownData from '../component/DropDownData';
import OperatorModel from '../model/OperatorModel';
import TableCell from '../component/TableCell';

class Operators extends React.Component<CommonProps, CommonState> {

    waitDisplay: string = "block";
    tableDisplay: string = "none";

    operatorsModel: OperatorsModel;
    tableModel: TableModel;

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: CommonProps) {
        super(params);
        this.operatorsModel = new OperatorsModel();
        this.tableModel = new TableModel();
        this.loadData();
    }

    updateState() {
        this.setState({
            time: new Date()
        });
    }

    async showWait(show: boolean) {
        if (show) {
            this.waitDisplay = "block";
            this.tableDisplay = "none";
            this.updateState();
        } else {
            this.waitDisplay = "none";
            this.tableDisplay = "block";
        }
    }

    async loadData() {

        this.showWait(true);
        let model = { "command": "all" };
        let sa = new ServerAdaptor();
        let cr = await sa.get_operators(model);
        let operatorsModel = cr.data as OperatorsModel;
        this.operatorsModel.operators = operatorsModel.operators;

        this.tableModel.reset();

        this.tableModel.headers.addTextCell("Sno");
        this.tableModel.headers.addTextCell("Operator Code");
        this.tableModel.headers.addTextCell("Operator Name");
        this.tableModel.headers.addTextCell("Country");
        this.tableModel.headers.addTextCell("");

        for (var i = 0; i < this.operatorsModel.operators.length; i++) {

            let op = this.operatorsModel.operators[i];

            var rowModel = new TableRowModel();
            rowModel.addHeaderCell((i+1)+".");
            rowModel.addTextCell(op.operatorCode);
            rowModel.addTextCell(op.operatorName);
            rowModel.addTextCell(op.countryName);

            var dropdown_data: DropDownData[] = [];

            var dropDownData = new DropDownData();
            dropDownData.option = "View";
            dropDownData.object = op;
            dropdown_data.push(dropDownData);

            dropDownData = new DropDownData();
            dropDownData.option = "Edit";
            dropDownData.object = op;
            dropdown_data.push(dropDownData);

            dropDownData = new DropDownData();
            dropDownData.option = "Products";
            dropDownData.object = op;
            dropdown_data.push(dropDownData);

            rowModel.addDropDownCell(dropdown_data);

            this.tableModel.rows.push(rowModel);
        }

        this.tableModel.updateView();

        this.showWait(false);
        this.updateState();
    }

    async onDropDownClicked(e: React.MouseEvent<HTMLElement, MouseEvent>, dropDownData: DropDownData) {
        e.preventDefault();
        if(dropDownData.option == "View") {
            let op = dropDownData.object as OperatorModel;
            DataStore.setSelectedOperatorId(op.operatorId);
            console.log("view");
            window.location.href = "/operator-view/"+op.operatorId;
        }else if(dropDownData.option == "Edit") {
            let op = dropDownData.object as OperatorModel;
            DataStore.setSelectedOperatorId(op.operatorId);
            console.log("edit");
            window.location.href = "/edit-operator/"+op.operatorId;
        }else if(dropDownData.option == "Products") {
            let op = dropDownData.object as OperatorModel;
            DataStore.setSelectedOperatorId(op.operatorId);
            console.log("edit");
            window.location.href = "/operator-products/"+op.operatorId;
        }
    }

    async onButtonClicked(e: React.MouseEvent<HTMLElement, MouseEvent>, cell: TableCell) {
        e.preventDefault();
    }

    async viewAction(e: React.MouseEvent<HTMLElement, MouseEvent>, operatorId: number) {
        e.preventDefault();
        DataStore.setSelectedOperatorId(operatorId);
        console.log("view");
        window.location.href = "/operator-view";
    }

    async editAction(e: React.MouseEvent<HTMLElement, MouseEvent>, operatorId: number) {
        e.preventDefault();
        DataStore.setSelectedOperatorId(operatorId);
        console.log("edit");
        window.location.href = "/edit-operator";
    }

    async productsAction(e: React.MouseEvent<HTMLElement, MouseEvent>, operatorId: number) {
        e.preventDefault();
        DataStore.setSelectedOperatorId(operatorId);
        console.log("edit");
        window.location.href = "/operator-products";
    }

    render() {

        return (
            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">Operators</h3>
                        </div>
                    </div>
                </div>

                <div className="row mb-6" style={{ display: this.waitDisplay }}>
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="card h-100">
                            <div className="card-body">
                                <div className="spinner-border m-5" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mb-6">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="card h-100">
                            <div className="card-header bg-white py-3">
                                <div className="d-md-flex justify-content-between align-items-center">
                                    <div className="mb-3 mb-lg-0 text-center text-sm-start">
                                        <h4 className="mb-0">Operators</h4>
                                    </div>
                                    <div className="text-center text-md-start">
                                        <a type="button" className="btn btn-sm btn-primary" href="/new-operator">New</a>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body p-0" style={{ display: this.tableDisplay }}>
                                <PaginationTableComponent model={this.tableModel} onDropDownClicked={this.onDropDownClicked} onButtonClicked={this.onButtonClicked} ></PaginationTableComponent>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default Operators;