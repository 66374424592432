import PaginationModel from "./PaginationModel";
import TableRowModel from "./TableRowModel";

class TableModel {

    headers: TableRowModel = new TableRowModel();
    rows: TableRowModel[] = [];
    rows_display: TableRowModel[] = [];
    paginationModel: PaginationModel = new PaginationModel();
    
    reset() {
        this.headers = new TableRowModel();
        this.rows = [];
        this.rows_display = [];
    }

    updateView() {
        this.paginationModel.setPages(this.rows.length, this.paginationModel.perPageCount);
        this.rows_display = [];
        for (var i = 0; i < this.paginationModel.selectedPage.records.length; i++) {
            var record_index = this.paginationModel.selectedPage.records[i];
            this.rows_display.push(this.rows[record_index]);
        }
    }

    updateColumnViewToButton(name: string, label: string) {
        let index = -1;
        for (var i = 0; i < this.headers.cells.length; i++) {
            var headerCell = this.headers.cells[i];
            if(headerCell.value == name) {
                index = i;
            }
        }
        if(index >= 0) {
            for (var row of this.rows) {
                var cell = row.cells[index];
                var value = cell.value;
                cell.value = label;
                cell.extra_data = value;
                cell.data_type = 4;
            }
        }
    }

}

export default TableModel;