import Callback from '../component/Callback';
import CommonResponse from '../util/CommonResponse';
import ErrorResponse from '../util/ErrorResponse';
import DataStore from './DataStore';

class ServerAdaptor {

    //base_url: string = "http://151.51.71.71:9030";
    //base_url: string = "http://172.252.30.81:18000";
    
    // static base_url2: string = "http://localhost:30000";
    // base_url: string = "http://localhost:30000";
    // report_url: string = "http://localhost:8080";

    // static base_url2: string = "http://api.speedpayplus.com:30000";
    // base_url: string = "http://api.speedpayplus.com:30000";
    // report_url: string = "http://portal.speedpayplus.com:20000";

    // stochos live
    static base_url2: string = "https://api.speedpayplus.com";
    base_url: string = "https://api.speedpayplus.com";
    report_url: string = "https://portal.speedpayplus.com";

    // base_url: string = "http://portal.jmpayment.com:18000";
    // report_url: string = "http://portal.jmpayment.com:20000";

    login_request_1(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/user-api/login";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    login_request(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/authentication";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    forgot_password_request(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/user-api/forgot-password";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    reset_password_request(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/user-api/reset-password";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    reset_mpin_request(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/user-api/reset-mpin";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    signup_request(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/api2";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    get_apis(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/speedpay/apis";
        let cp = this.httpGet(url);
        return cp;
    }

    get_countries(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/speedpay/get-countries";
        let cp = this.httpGet(url);
        return cp;
    }

    get_countriesWithAreas(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/speedpay/get-countries-with-areas";
        let cp = this.httpGet(url);
        return cp;
    }

    get_currencies(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/speedpay/get-currencies";
        let cp = this.httpGet(url);
        return cp;
    }

    get_servicetypes(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/speedpay/get-servicetypes";
        let cp = this.httpGet(url);
        return cp;
    }

    get_service_providers(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/service-provider-api/get-service-providers";
        let cp = this.httpGet(url);
        return cp;
    }

    get_service_provider(serviceProviderId: number): Promise<CommonResponse> {
        let url = this.base_url+"/service-provider-api/get-service-provider?serviceProviderId="+serviceProviderId;
        let cp = this.httpGet(url);
        return cp;
    }

    new_service_provider(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/service-provider-api/new-service-provider";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    update_service_provider(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/service-provider-api/update-service-provider";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    update_service_provider_status(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/service-provider-api/update-service-provider-status";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    update_service_provider_route(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/service-provider-api/update-service-provider-route";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    update_service_provider_operator_route(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/service-provider-api/update-service-provider-operator-route";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    get_provider_wallet_info(serviceProviderId: number): Promise<CommonResponse> {
        let url = this.base_url+"/service-provider-transaction-api/get-provider-wallet-info?serviceProviderId="+serviceProviderId;
        let cp = this.httpGet(url);
        return cp;
    }

    update_provider_wallet(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/service-provider-transaction-api/update-provider-wallet";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    get_provider_wallet_transactions(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/service-provider-transaction-api/get-provider-wallet-transactions";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    get_operators(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/operator-api/get-operators";
        let cp = this.httpGet(url);
        return cp;
    }

    get_operator(operatorId: number): Promise<CommonResponse> {
        let url = this.base_url+"/operator-api/get-operator?operatorId="+operatorId;
        let cp = this.httpGet(url);
        return cp;
    }

    new_operator(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/operator-api/new-operator";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    update_operator(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/operator-api/update-operator";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    update_operator_status(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/operator-api/update-operator-status";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }
    
    update_product_status(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/product-api/update-product-status";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    get_service_provider_operator_mappings(serviceProviderId: number): Promise<CommonResponse> {
        let url = this.base_url+"/service-provider-api/get-service-provider-operator-mappings?serviceProviderId="+serviceProviderId;
        let cp = this.httpGet(url);
        return cp;
    }

    get_service_provider_operator_mapping(serviceProviderOperatorId: number): Promise<CommonResponse> {
        let url = this.base_url+"/service-provider-api/get-service-provider-operator-mapping?id="+serviceProviderOperatorId;
        let cp = this.httpGet(url);
        return cp;
    }

    new_service_provider_operator_mapping(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/service-provider-api/new-service-provider-operator-mapping";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    update_service_provider_operator_mapping(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/service-provider-api/update-service-provider-operator-mapping";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    update_service_provider_operator_mapping_status(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/service-provider-api/update-service-provider-operator-mapping-status";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    get_service_provider_operators_all(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/service-provider-api/get-service-provider-operators-all";
        let cp = this.httpGet(url);
        return cp;
    }

    get_clients(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/client-api/get-clients";
        let cp = this.httpGet(url);
        return cp;
    }

    get_client(channelId: number): Promise<CommonResponse> {
        let url = this.base_url+"/client-api/get-client?channelId="+channelId;
        let cp = this.httpGet(url);
        return cp;
    }

    get_notifications(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/user-api/notifications";
        let cp = this.httpGet(url);
        return cp;
    }

    approveTask(approvalTaskId: number): Promise<CommonResponse> {
        let url = this.base_url+"/transaction-api/approve-task?approvalTaskId="+approvalTaskId;
        let cp = this.httpGet(url);
        return cp;
    }

    rejectTask(approvalTaskId: number): Promise<CommonResponse> {
        let url = this.base_url+"/transaction-api/reject-task?approvalTaskId="+approvalTaskId;
        let cp = this.httpGet(url);
        return cp;
    }

    new_client(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/client-api/new-client";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    update_client(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/client-api/update-client";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    update_client_status(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/client-api/update-client-status";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    get_client_wallet_info(channelId: number): Promise<CommonResponse> {
        let url = this.base_url+"/client-transaction-api/get-client-wallet-info?channelId="+channelId;
        let cp = this.httpGet(url);
        return cp;
    }

    update_client_wallet(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/client-transaction-api/update-client-wallet";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    adjust_client_wallet(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/client-transaction-api/adjust-client-wallet";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    get_client_wallet_transactions(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/transaction-api/get-transaction-report2";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    get_reports(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/report-api/get-reports";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    get_reports_dynamic(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/report-api/get-reports-dynamic";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }
    
    get_retailor_wallet_reports(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/report-api/get-retailor-wallet-reports";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    get_dashboard_data(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/report-api/get-dashboard-report";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    get_sp_dashboard_data(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/report-api/get-sp-dashboard-report";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    get_admin_dashboard_data(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/report-api/get-admin-dashboard-report";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    update_transactions_status(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/transaction-api/transaction-status-update";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    get_forex_list(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/client-api/get-forex-list";
        let cp = this.httpGet(url);
        return cp;
    }

    get_forex_data(forexId: number): Promise<CommonResponse> {
        let url = this.base_url+"/client-api/get-forex-data?forexId="+forexId;
        let cp = this.httpGet(url);
        return cp;
    }

    new_forex_data(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/client-api/new-forex-data";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    update_forex_data(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/client-api/update-forex-data";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    get_client_services(clientId: number): Promise<CommonResponse> {
        let url = this.base_url+"/client-api/get-client-services?channelId="+clientId;
        let cp = this.httpGet(url);
        return cp;
    }

    get_client_service_config(clientServiceId: number): Promise<CommonResponse> {
        let url = this.base_url+"/client-api/get-client-service?clientServiceId="+clientServiceId;
        let cp = this.httpGet(url);
        return cp;
    }

    new_client_service_config(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/client-api/new-client-service";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    update_client_service_config(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/client-api/update-client-service";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    get_client_countries(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/product-api/get-countries-for-client";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    get_client_operators(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/product-api/get-country-operators";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    get_products(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/product-api/get-products";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    get_product(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/product-api/get-product";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    get_operator_products(operatorId: number): Promise<CommonResponse> {
        let url = this.base_url+"/product-api/get-operator-products?operatorId="+operatorId;
        let cp = this.httpGet(url);
        return cp;
    }

    get_product_internal(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/product-api/get-product-internal";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    new_product(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/product-api/new-product";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    update_product(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/product-api/update-product";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    get_vouchers(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/product-api/get-vouchers";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    get_voucher(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/product-api/get-voucher";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    new_voucher(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/product-api/new-voucher";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    update_voucher(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/product-api/update-voucher";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    voucherBulkUpload(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/product-api/voucher-bulk-upload";
        let cp = this.httpJsonPostFile(url, model);
        return cp;
    }

    clientBulkUpload(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/client-api/client-bulk-upload";
        let cp = this.httpJsonPostFile(url, model);
        return cp;
    }

    get_transactions(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/transaction-api/get-transaction-report";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    place_order(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/transaction-api/new-order";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

    get_category_operator_mappings1(categoryGroupId: number, cmd: string, callback: Callback): void {
        let url = this.base_url+"/category-api/get-category-operator-mappings?categoryGroupId="+categoryGroupId;
        this.httpGetCallback(url, cmd, callback);
    }

    get_transection_status(model: any): Promise<CommonResponse> {
        let url = this.base_url+"/transaction-api/get-transaction-status";
        let cp = this.httpJsonPost(url, model);
        return cp;
    }

	httpGet(url_part: string): Promise<CommonResponse> {
        return new Promise( (resolve, reject) => {
		    console.log("url_part " + url_part);
            var xhr = new XMLHttpRequest();
            let url = url_part;
            xhr.open('GET', url, true);
		    xhr.setRequestHeader("content-type", "application/json");
            xhr.setRequestHeader("Authorization", DataStore.getToken_id());
            
            xhr.onload = function () {
                if (xhr.readyState !== 4) {
                    return;
                }
                if (xhr.status === 200) {
                    console.log("Server responce : " + xhr.responseText);
                    let response = JSON.parse(xhr.responseText);
                    let commonResponse = new CommonResponse();
                    commonResponse.setSuccessResponse(response);
                    resolve(commonResponse);
                } else {
                    console.log('Request failed.  Returned status of ' + xhr.status);
                    if (xhr.status === 401) {
                        window.location.href = "/login";
                    }
                    let error = JSON.parse(xhr.responseText) as ErrorResponse;
                    let commonResponse = new CommonResponse();
                    commonResponse.setErrorResponse(xhr.status, error.errorCode, error.error);
                    resolve(commonResponse);
                }
            };
            xhr.send();
        });
    }

    httpGetCallback(url_part: string, cmd: string, callback: Callback): void {

		console.log("json_input " + url_part);
        var xhr = new XMLHttpRequest();
        let url = url_part;
        xhr.open('GET', url, true);
		xhr.setRequestHeader("content-type", "application/json");
        xhr.setRequestHeader("Authorization", DataStore.getToken_id());
            
        xhr.onload = () => {
            if (xhr.readyState !== 4) {
                return;
            }
            if (xhr.status === 200) {
                console.log("Server responce : " + xhr.responseText);
                let response = JSON.parse(xhr.responseText) as CommonResponse;
                let commonResponse = new CommonResponse();
                commonResponse.setSuccessResponse(response);
                callback.onResponse(cmd, commonResponse);
            } else {
                console.log('Request failed.  Returned status of ' + xhr.status);
                if (xhr.status === 401) {
                    window.location.href = "/login";
                }
                let error = JSON.parse(xhr.responseText) as ErrorResponse;
                let commonResponse = new CommonResponse();
                commonResponse.setErrorResponse(xhr.status, error.errorCode, error.error);
                callback.onResponse(cmd, commonResponse);
            }
        };
        xhr.send();
    }

    httpJsonPost(url_part: string, json_obj: any): Promise<CommonResponse> {
        return new Promise( (resolve, reject) => {
            let json_input = JSON.stringify(json_obj);
		    console.log("url "+url_part+" json_input " + json_input);
            var xhr = new XMLHttpRequest();
            let url = url_part;
            xhr.open('POST', url, true);
		    xhr.setRequestHeader("content-type", "application/json");
            xhr.setRequestHeader("Authorization", DataStore.getToken_id());
            
            xhr.onload = function () {
                if (xhr.readyState !== 4) {
                    return;
                }
                if (xhr.status === 200) {
                    console.log("Server responce : " + xhr.responseText);
                    let response = JSON.parse(xhr.responseText);
                    let commonResponse = new CommonResponse();
                    commonResponse.setSuccessResponse(response);
                    resolve(commonResponse);
                } else {
                    console.log('Request failed.  Returned status of ' + xhr.status);
                    if (xhr.status === 401) {
                        window.location.href = "/login";
                    }
                    let error = JSON.parse(xhr.responseText) as ErrorResponse;
                    let commonResponse = new CommonResponse();
                    commonResponse.setErrorResponse(xhr.status, error.errorCode, error.error);
                    resolve(commonResponse);
                }
            };
            xhr.send(json_input);
        });
    }

    httpPost(url_part: string, json_obj: any, cmd: string, callback: Callback): void {

        let json_input = JSON.stringify(json_obj);
		console.log("json_input " + json_input);
        var xhr = new XMLHttpRequest();
        let url = url_part;
        xhr.open('POST', url, true);
		xhr.setRequestHeader("content-type", "application/json");
        xhr.setRequestHeader("Authorization", DataStore.getToken_id());
            
        xhr.onload = () => {
            if (xhr.readyState !== 4) {
                return;
            }
            if (xhr.status === 200) {
                console.log("Server responce : " + xhr.responseText);
                let response = JSON.parse(xhr.responseText) as CommonResponse;
                let commonResponse = new CommonResponse();
                commonResponse.setSuccessResponse(response);
                callback.onResponse(cmd, commonResponse);
            } else {
                console.log('Request failed.  Returned status of ' + xhr.status);
                if (xhr.status === 401) {
                    window.location.href = "/login";
                }
                let error = JSON.parse(xhr.responseText) as ErrorResponse;
                let commonResponse = new CommonResponse();
                commonResponse.setErrorResponse(xhr.status, error.errorCode, error.error);
                callback.onResponse(cmd, commonResponse);
            }
        };
        xhr.send(json_input);
    }

    httpJsonPostFile(url_part: string, formData: FormData): Promise<CommonResponse> {
        return new Promise( (resolve, reject) => {
            var xhr = new XMLHttpRequest();
            let url = url_part;
            xhr.open('POST', url, true);
		    //xhr.setRequestHeader("content-type", "multipart/form-data");
            xhr.setRequestHeader("Authorization", DataStore.getToken_id());
            
            xhr.onload = function () {
                if (xhr.readyState !== 4) {
                    return;
                }
                if (xhr.status === 200) {
                    console.log("Server responce : " + xhr.responseText);
                    let response = JSON.parse(xhr.responseText);
                    let commonResponse = new CommonResponse();
                    commonResponse.setSuccessResponse(response);
                    resolve(commonResponse);
                } else {
                    console.log('Request failed.  Returned status of ' + xhr.status);
                    console.log("Server responce : " + xhr.responseText);
                    if (xhr.status === 401) {
                        window.location.href = "/login";
                    }
                    let error = JSON.parse(xhr.responseText) as ErrorResponse;
                    let commonResponse = new CommonResponse();
                    commonResponse.setErrorResponse(xhr.status, error.errorCode, error.error);
                    resolve(commonResponse);
                }
            };
            xhr.send(formData);
        });
    }
}
export default ServerAdaptor;